import { useState } from "react"
import Footer from "../components/Footer"
import Header from "../components/Header"
import InnerHero from "../components/InnerHero"
import Experience from "../components/Experience"
import Side from "../components/Side"
import ServiceBlock from "../components/ServiceBlock"
import BSServiceBlock from "../components/BSServiceBlock"
import InsuranceServices from "../components/InsuranceServices"
import ProtectBusiness from "../components/ProtectBusiness"
import ClaimsExample from "../components/ClaimsExample"
import faq from "../data/faq"
import Accordian from "../components/Accordian"
import Slider from "react-slick"
import settings from "../lib/SliderSettings"

const ProductLiabilityInsurance = () => {
    const [activeW, setActiveW] = useState('what')
    const [isCoverage, setCoverage] = useState(true)
    const coverages = [
        {
            title: "Bodily Injury",
            content: " Covers claims related to physical injuries caused by defective products."
        },
        {
            title: "Property Damage",
            content: "Covers claims for damage to property resulting from product defects."
        },
        {
            title: "Legal Defense Costs:",
            content: "Covers legal fees and expenses incurred in defending against covered claims."
        },
        {
            title: "Medical Expenses:",
            content: "Covers medical costs for injuries caused by a defective product."
        },
        {
            title: "Compensation Costs:",
            content: "Covers settlements and damages awarded to claimants."
        },
        {
            title: "Product Recall Costs:",
            content: "Covers expenses related to recalling defective products from the market."
        },
        {
            title: "Wrongful Advertising:",
            content: "Covers claims related to misleading advertising that causes harm."
        }
    ]
    const exclusion = [
        {
            title: "Intentional Acts:",
            content: "Claims arising from intentional misconduct or fraud."
        },
        {
            title: "Known Defects:",
            content: "Claims related to defects known to the business before the policy inception."
        },
        {
            title: "Product Warranties:",
            content: "Claims arising from breach of product warranties."
        },
        {
            title: "Contractual Liability:",
            content: "Claims assumed under contract, unless they would have existed without the contract"
        },
        {
            title: "Professional Services:",
            content: "Claims related to professional services or advice provided."
        },
        {
            title: "Product Recall:",
            content: "Costs associated with recalling products not covered unless specifically included."
        },
        {
            title: "Asbestos",
            content: "Claims related to asbestos-containing products."
        },
        {
            title: "Pollution",
            content: " Claims arising from environmental pollution or contamination."
        },
        {
            title: "Nuclear Risks:",
            content: "Claims related to nuclear incidents or radiation exposure."
        },
        {
            title: "War and Terrorism",
            content: "Claims arising from acts of war or terrorism."
        }
    ]
    return (
        <>
            <Header />
            <div className="">
                <InnerHero
                    heading="Product Liability Insurance "
                    title="Protect your business from legal claims related to product defects and ensure peace of mind with comprehensive Product Liability Insurance."
                    image="/assets/img/product-liability-hero.png"
                />
                <Experience />
                <section className="w3-section my-5 px-lg-5 px-lg-80">
                    <div className="d-flex flex-row align-items-center justify-content-between px-4">
                        <div onClick={() => setActiveW('what')} className={`pointer ${activeW === 'what' ? 'active-w3' : ''}`}>
                            <p>What</p>
                        </div>
                        <div onClick={() => setActiveW('who')} className={`pointer ${activeW === 'who' ? 'active-w3' : ''}`}>
                            <p>Who</p>
                        </div>
                        <div onClick={() => setActiveW('why')} className={`pointer ${activeW === 'why' ? 'active-w3' : ''}`}>
                            <p>Why</p>
                        </div>
                        <div onClick={() => setActiveW('coverage')} className={`pointer ${activeW === 'coverage' ? 'active-w3' : ''}`}>
                            <p>Coverages & Exclusions</p>
                        </div>
                    </div>
                </section>
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">What is Product Liability Insurance?</h1>
                        <p className="mt-5 content-regular">Product Liability Insurance is a policy designed to protect businesses from financial losses due to claims related to the manufacture, sale, or distribution of products. It covers legal fees, settlements, and damages awarded in lawsuits alleging injury or damage caused by defective or unsafe products.</p>
                    </div>
                </section>
                <section className="coverage-section">
                    <div className="px-lg-5 px-lg-80">
                        <h1 className="title-primary-color mb-4">Key Coverage Areas of Product Liability Insurance</h1>
                        <Slider {...settings}>
                            <Side
                                heading="Side A"
                                content="Manufacturing Defects"
                                image="/assets/img/sideA.png"
                                description="Covers claims arising from defects in the manufacturing process that lead to unsafe products."
                            />
                            <Side
                                heading="Side B"
                                content="Design Defects"
                                image="/assets/img/sideB.png"
                                description="Covers claims related to inherent design flaws that make a product unsafe."
                            />
                            <Side
                                heading="Side C"
                                content="Marketing Defects"
                                image="/assets/img/sideC.png"
                                description="Covers claims due to inadequate instructions, warnings, or labels that fail to inform consumers about potential risks."
                            />
                        </Slider>

                    </div>
                    <ServiceBlock
                        title="Advantages of Product Liability Insurance"
                        subtitle="A narrative about how Buckss was founded, the inspiration behind the company, and its journey so far."
                        points={["Protects against costly legal claims:", "Safeguards business reputation:", "Ensures compliance with regulatory requirements:","Enhances customer trust and confidence:","Provides peace of mind to focus on business growth:"]}
                        ctaLabel=""
                        ctaLink=""
                        image="/assets/img/product-liability-advantage.png"
                        type="primary"
                        imageLocation="right"
                    />
                </section>
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">Who Needs Product Liability Insurance?</h1>
                        <p className="mt-5 content-regular">Product Liability Insurance is essential for any business involved in the manufacturing, distribution, or sale of products. This includes:Manufacturers, Distributors, Wholesalers, Retailers. mporters and Exporters, E-commerce Businesses</p>
                    </div>
                </section>
                <ServiceBlock
                    title="Factors Influencing Product Liability Policy Coverage"
                    subtitle=""
                    points={["Type of products sold:", "Volume of products produced or sold:", "Distribution channels:", "Past claims history:", "Regulatory environment:"]}
                    ctaLabel=""
                    ctaLink=""
                    image="/assets/img/product-liability-factors.png"
                    type="primary"
                    imageLocation="right"
                />
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">Why Get Product Liability Insurance?</h1>
                        <p className="my-5 content-regular">Businesses face numerous risks associated with their products. Product Liability Insurance provides a safeguard against potential financial losses due to legal claims. It covers:                        </p>
                        <BSServiceBlock points={["Injuries caused by defective or unsafe products:", "Property damage resulting from product defects:", "Legal fees and defense costs:", "Settlements and judgments awarded to claimants:", "Regulatory fines and penalties:"]} />
                    </div>
                </section>
                <ServiceBlock
                    title="Who Can File Claims Against Your Business?"
                    subtitle=""
                    points={["Consumers and end-users:", "Retailers and wholesalers:", "Regulatory authorities:", "Competitors:", "Third-party contractors:"]}
                    ctaLabel=""
                    ctaLink=""
                    image="/assets/img/litigation.png"
                    type="primary"
                    imageLocation="right"
                />
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">Coverages & Exclusions in Product Liability Insurance*</h1>
                        <section className="w3-section my-5">
                            <div className="d-flex flex-row">
                                <div onClick={() => setCoverage(true)} className={`mr-4 pointer ${isCoverage ? 'active-w3' : ''}`}>
                                    <p>Coverages</p>
                                </div>
                                <div onClick={() => setCoverage(false)} className={`pointer ${!isCoverage ? 'active-w3' : ''}`}>
                                    <p>Exclusions</p>
                                </div>
                            </div>
                        </section>
                        <div>
                            {isCoverage && (
                                <p>Product Liability Insurance provides extensive coverage against various risks. Key coverages include:</p>
                            )}
                            {!isCoverage && (
                                <p>Certain situations are excluded from Product Liability coverage, including:</p>
                            )}

                        </div>
                        {isCoverage && (
                            <div className="row">
                                {coverages.map((c, i) => (
                                    <div className="col-12 col-lg-6">
                                        <h5 className="title-primary-color my-3">{`${i + 1}. ${c.title}`}</h5>
                                        <p>{`${c.content}`}</p>
                                    </div>
                                ))}
                            </div>
                        )}
                        {!isCoverage && (
                            <div className="row">
                                {exclusion.map((c, i) => (
                                    <div className="col-12 col-lg-6">
                                        <h5 className="title-primary-color my-3">{`${i + 1}. ${c.title}`}</h5>
                                        <p>{`${c.content}`}</p>
                                    </div>
                                ))}
                            </div>
                        )}

                    </div>
                </section>
                <ClaimsExample 
                    title="Directors & Officers Claims Examples"
                    subtitle="Case of Employee Lawsuits for Unpaid Benefits"
                    incident="A group of employees sued a company's subsidiary in India for unpaid benefits and financial compensation."
                    resolution="Legal defense costs were incurred, and the insurer appointed a local law firm to handle the case. The outcome was favorable, with only the defense costs reimbursed under the D&O policy."
                    image="/assets/img/claims-example.png"
                />
                {/* <section className="my-4">
                    <p className={`service-title title-primary-color text-center`}>{'Frequently Asked Questions'}</p>
                    <div className=" p-5">
                    <div id="accordion" className="px-lg-5 px-lg-80 p-5">
                        {faq.do.map(f => (<Accordian faq={f} />))}
                    </div>
                </div>
                </section> */}
                <ServiceBlock
                    title="Why Choose Buckss for Product Liability Insurance?"
                    subtitle=""
                    points={
                        [
                            "Expertise:Leveraging over 50 years of combined experience in the insurance industry.",
                            "Tailored Solutions:Customized Product Liability insurance plans to suit your business needs.",
                            "Dedicated Support:Comprehensive support throughout the claims process.",
                            "Strong Partnerships:Collaborations with leading insurance providers to offer the best coverage options.",
                        ]
                    }
                    ctaLabel=""
                    ctaLink=""
                    image="/assets/img/insurance-agent-vector.png"
                    type="primary"
                    imageLocation="right"
                />
                <div className="mt-4">
                        <InsuranceServices />
                    </div>
               <div className="mt-4">
               <ProtectBusiness />
               </div>
            </div>
            <Footer />
        </>
    )
}

export default ProductLiabilityInsurance