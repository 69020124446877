const insurer = [
    {
        "name": "Bajaj Allianz",
        "img": "/assets/img/insurer/BajajLife.png",
        "type": "Life"
    },
    {
        "name": "Care",
        "img": "/assets/img/insurer/CareHealth.png",
        "type": "Health"
    },
    {
        "name": "Cholamandalam MS General",
        "img": "/assets/img/insurer/Cholamandalam MS General Insurance.png",
        "type": "General"
    },
    {
        "name": "Godigit",
        "img": "/assets/img/insurer/Godigit.png",
        "type": "General"
    },
    {
        "name": "Godigit",
        "img": "/assets/img/insurer/Godigit.png",
        "type": "Health"
    },
    {
        "name": "HDFC Ergo",
        "img": "/assets/img/insurer/HDFCERGOGeneralInsurance.png",
        "type": "General"
    },
    {
        "name": "HDFC Ergo",
        "img": "/assets/img/insurer/HDFCERGOGeneralInsurance.png",
        "type": "Health"
    },
    {
        "name": "HDFC Life",
        "img": "/assets/img/insurer/HDFCLifeInsurance.png",
        "type": "Life"
    },
    {
        "name": "ICICI Lombard",
        "img": "/assets/img/insurer/ICICILombardGeneralInsurance.png",
        "type": "General"
    },
    {
        "name": "ICICI Lombard",
        "img": "/assets/img/insurer/ICICILombardGeneralInsurance.png",
        "type": "Health"
    },
    {
        "name": "ICICI Pru",
        "img": "/assets/img/insurer/ICICIPrudentialLifeInsurance.png",
        "type": "Life"
    },
    {
        "name": "IFFCO Tokio",
        "img": "/assets/img/insurer/IFFCOTokioGeneral.png",
        "type": "General"
    },
    {
        "name": "LIC",
        "img": "/assets/img/insurer/LifeInsuranceCorporation.png",
        "type": "Life"
    },
    {
        "name": "National Insurance",
        "img": "/assets/img/insurer/NationalInsurance.png",
        "type": "General"
    },
    {
        "name": "New India Assurance",
        "img": "/assets/img/insurer/New India Assurance.png",
        "type": "General"
    },
    {
        "name": "Niva Bupa",
        "img": "/assets/img/insurer/Niva Bupa.png",
        "type": "Health"
    },
    {
        "name": "Oriental Insurance",
        "img": "/assets/img/insurer/Oriental Insurance.png",
        "type": "General"
    },
    {
        "name": "PNB Metlife",
        "img": "/assets/img/insurer/PNBMetlifeIndiaInsurance.png",
        "type": "Life"
    },
    {
        "name": "Pramerica Life",
        "img": "/assets/img/insurer/PramericaLifeInsurance.png",
        "type": "Life"
    },
    {
        "name": "Reliance General",
        "img": "/assets/img/insurer/Reliance General Insurance.png",
        "type": "General"
    },
    {
        "name": "Royal Sundaram General",
        "img": "/assets/img/insurer/Royal Sundaram General Insurance.png",
        "type": "General"
    },
    {
        "name": "SBI General",
        "img": "/assets/img/insurer/SBI General Insurance.png",
        "type": "General"
    },
    {
        "name": "SBI Life",
        "img": "/assets/img/insurer/SBI Life Insurance.png",
        "type": "Life"
    },
    {
        "name": "Shriram General",
        "img": "/assets/img/insurer/Shriram General Insurance.png",
        "type": "General"
    },
    {
        "name": "TATA AIA",
        "img": "/assets/img/insurer/TATA AIA Life.png",
        "type": "Life"
    },
    {
        "name": "Tata AIG",
        "img": "/assets/img/insurer/Tata AIG.png",
        "type": "General"
    },
    {
        "name": "United India",
        "img": "/assets/img/insurer/United India Insurance.png",
        "type": "General"
    },
    {
        "name": "United India",
        "img": "/assets/img/insurer/United India Insurance.png",
        "type": "Health"
    }
]

export default insurer;