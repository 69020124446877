import { useState } from "react"
import Footer from "../components/Footer"
import Header from "../components/Header"
import InnerHero from "../components/InnerHero"
import Experience from "../components/Experience"
import Side from "../components/Side"
import ServiceBlock from "../components/ServiceBlock"
import BSServiceBlock from "../components/BSServiceBlock"
import InsuranceServices from "../components/InsuranceServices"
import ProtectBusiness from "../components/ProtectBusiness"
import ClaimsExample from "../components/ClaimsExample"
import faq from "../data/faq"
import Accordian from "../components/Accordian"
import Slider from "react-slick"
import settings from "../lib/SliderSettings"

const DirectorsInsurance = () => {
    const [activeW, setActiveW] = useState('what')
    const [isCoverage, setCoverage] = useState(true)

    const coverages = [
        {
            title: "Sanctions and Legal Violations",
            content: "Covers the cost of bail and bonds if directors or officers face criminal charges."
        },
        {
            title: "Product Liability",
            content: "Pays for legal defense in case of lawsuits, including attorney fees and court costs."
        },
        {
            title: "ERISA Violations",
            content: "Covers the cost of managing public relations and mitigating reputation damage."
        },
        {
            title: "Substance-related Claims",
            content: "Provides for costs related to extradition of directors or officers detained abroad."
        },
        {
            title: "Asbestos-related Claims",
            content: "Covers expenses incurred by directors or officers attending court as part of a defense."
        },
        {
            title: "Insolvency Issues",
            content: "Reimburses costs related to kidnapping incidents involving directors or officers."
        },
        {
            title: "War and Hostilities",
            content: "overs expenses associated with self-reporting potential legal issues to authorities."
        },
        {
            title: "IPO-related Claims",
            content: "Covers costs incurred during investigations related to the directors' or officers' duties."
        },
        {
            title: "Audit-related Issues",
            content: "Funds to manage public communication strategies in times of crisis."
        },
        {
            title: "Bribery and Corruption",
            content: "Provides additional coverage limits for non-executive directors"
        }
    ]
    const exclusion = [
        {
            title: "Bail and Bond Expenses",
            content: "Covers the cost of bail and bonds if directors or officers face criminal charges."
        },
        {
            title: "Defense Costs",
            content: "Pays for legal defense in case of lawsuits, including attorney fees and court costs."
        },
        {
            title: "Crisis Management Expenses",
            content: "Covers the cost of managing public relations and mitigating reputation damage."
        },
        {
            title: "Extradition Coverage",
            content: "Provides for costs related to extradition of directors or officers detained abroad."
        },
        {
            title: "Court Attendance Expenses",
            content: "Covers expenses incurred by directors or officers attending court as part of a defense."
        },
        {
            title: "Kidnap and Ransom",
            content: "Reimburses costs related to kidnapping incidents involving directors or officers."
        },
        {
            title: "Self-reporting Costs",
            content: "overs expenses associated with self-reporting potential legal issues to authorities."
        },
        {
            title: "Investigation Costs",
            content: "Covers costs incurred during investigations related to the directors' or officers' duties."
        },
        {
            title: "Crisis Communication",
            content: "Funds to manage public communication strategies in times of crisis."
        },
        {
            title: "Extra Coverage for Non-executive Directors",
            content: "Provides additional coverage limits for non-executive directors"
        }
    ]
    return (
        <>
            <Header />
            <div className="">
                <InnerHero
                    heading="Directors & Officers Liability Insurance"
                    title="Shield your company’s leadership from legal risks and protect their personal assets against potential lawsuits."
                    image="/assets/img/do.png"
                />
                <Experience />
                <section className="w3-section my-5 px-lg-5 px-lg-80">
                    <div className="d-flex flex-row align-items-center justify-content-between px-4">
                        <div onClick={() => setActiveW('what')} className={`pointer ${activeW === 'what' ? 'active-w3' : ''}`}>
                            <p>What</p>
                        </div>
                        <div onClick={() => setActiveW('who')} className={`pointer ${activeW === 'who' ? 'active-w3' : ''}`}>
                            <p>Who</p>
                        </div>
                        <div onClick={() => setActiveW('why')} className={`pointer ${activeW === 'why' ? 'active-w3' : ''}`}>
                            <p>Why</p>
                        </div>
                        <div onClick={() => setActiveW('coverage')} className={`pointer ${activeW === 'coverage' ? 'active-w3' : ''}`}>
                            <p>Coverages & Exclusions</p>
                        </div>
                    </div>
                </section>
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">What Is Directors & Officers Liability Insurance?</h1>
                        <p className="mt-5 content-regular">Directors and Officers (D&O) Liability Insurance is designed to protect the personal assets of corporate directors and officers, as well as their spouses, from financial losses stemming from lawsuits brought against them for alleged wrongful acts while managing a company. This insurance covers legal fees, settlements, and other related costs.</p>
                    </div>
                </section>
                <section className="coverage-section">
                    <div className="px-lg-5 px-lg-80">
                        <h1 className="title-primary-color mb-4">Three Key Coverage Areas of D&O Insurance</h1>
                        <Slider {...settings}>
                            
                                
                                    <Side
                                        heading="Side A"
                                        content="Individual Coverage"
                                        image="/assets/img/sideA.png"
                                        description="Protects directors and officers when the company cannot indemnify them, ensuring their personal assets are shielded from lawsuits."
                                    />
                                
                                
                                    <Side
                                        heading="Side B"
                                        content="Company Reimbursement"
                                        image="/assets/img/sideB.png"
                                        description="Reimburses the company for legal expenses incurred when indemnifying its directors and officers."
                                    />
                                
                                    <Side
                                        heading="Side C"
                                        content="Entity Coverage"
                                        image="/assets/img/sideC.png"
                                        description="Covers the organization itself for claims made against it, typically related to securities litigation."
                                    />
                                
                            
                    
                </Slider>

            </div>
            <ServiceBlock
                title="Advantages of D&O Liability Insurance"
                subtitle="A narrative about how Buckss was founded, the inspiration behind the company, and its journey so far."
                points={["Shields personal assets of directors & officers:", "Crucial for both large and small enterprises:", "Enhances the ability to attract and retain top executive talent:"]}
                ctaLabel=""
                ctaLink=""
                image="/assets/img/do-advantage.png"
                type="primary"
                imageLocation="right"
            />
        </section >
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">Who Needs Directors & Officers Liability Insurance?</h1>
                        <p className="mt-5 content-regular">Any organization with a board of directors or advisory committee should have a D&O policy. Companies today face increased litigation risks from various sources, including regulators, shareholders, vendors, customers, competitors, and employees. Protecting the leadership with D&O insurance is essential to safeguard the organization's strategic decision-makers.</p>
                    </div>
                </section>
                <ServiceBlock
                    title="Factors Influencing D&O Policy Coverage"
                    subtitle=""
                    points={["Company’s risk tolerance:", "Financial stability:", "Size and structure of the company:", "History of litigation:", "Age and maturity of the business:"]}
                    ctaLabel=""
                    ctaLink=""
                    image="/assets/img/do-coverage-factor.png"
                    type="primary"
                    imageLocation="right"
                />
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">Why Get Directors & Officers Liability Insurance?</h1>
                        <p className="my-5 content-regular">Directors and officers are vulnerable to numerous financial risks in their roles. D&O insurance provides a safety net, protecting them from a wide range of potential legal challenges, including:</p>
                        <BSServiceBlock points={["Stakeholder lawsuits:", "Allegations of wrongful acts:", "Regulatory investigations and fines:", "Financial mismanagement and reporting errors:", "Employment-related issues such as harassment or discrimination:"]} />
                    </div>
                </section>
                <ServiceBlock
                    title="Potential Litigants Against Directors or Officers"
                    subtitle=""
                    points={["Suppliers and vendors:", "Regulatory authorities:", "Stakeholders and shareholders:", "Employees:", "Clients and customers:", "Competitors:"]}
                    ctaLabel=""
                    ctaLink=""
                    image="/assets/img/litigation.png"
                    type="primary"
                    imageLocation="right"
                />
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">Coverages & Exclusions in D&O Insurance*</h1>
                        <section className="w3-section my-5">
                            <div className="d-flex flex-row">
                                <div onClick={() => setCoverage(true)} className={`mr-4 pointer ${isCoverage ? 'active-w3' : ''}`}>
                                    <p>Coverages</p>
                                </div>
                                <div onClick={() => setCoverage(false)} className={`pointer ${!isCoverage ? 'active-w3' : ''}`}>
                                    <p>Exclusions</p>
                                </div>
                            </div>
                        </section>
                        <div>
                            {isCoverage && (
                                <p>D&O insurance offers comprehensive coverage to protect against various risks. Key coverages include</p>
                            )}
                            {!isCoverage && (
                                <p>Certain situations are excluded from D&O coverage, including:</p>
                            )}

                        </div>
                        {isCoverage && (
                            <div className="row">
                                {coverages.map((c, i) => (
                                    <div className="col-12 col-lg-6">
                                        <h5 className="title-primary-color my-3">{`${i + 1}. ${c.title}`}</h5>
                                        <p>{`${c.content}`}</p>
                                    </div>
                                ))}
                            </div>
                        )}
                        {!isCoverage && (
                            <div className="row">
                                {exclusion.map((c, i) => (
                                    <div className="col-12 col-lg-6">
                                        <h5 className="title-primary-color my-3">{`${i + 1}. ${c.title}`}</h5>
                                        <p>{`${c.content}`}</p>
                                    </div>
                                ))}
                            </div>
                        )}

                    </div>
                </section>
                <ClaimsExample
                    title="Directors & Officers Claims Examples"
                    subtitle="Case of Employee Lawsuits for Unpaid Benefits"
                    incident="A group of employees sued a company's subsidiary in India for unpaid benefits and financial compensation."
                    resolution="Legal defense costs were incurred, and the insurer appointed a local law firm to handle the case. The outcome was favorable, with only the defense costs reimbursed under the D&O policy."
                    image="/assets/img/claims-example.png"
                />
                {/* <section className="my-4">
                    <p className={`service-title title-primary-color text-center`}>{'Frequently Asked Questions'}</p>
                    <div className=" p-5">
                        <div id="accordion" className="px-lg-5 px-lg-80 p-5">
                            {faq.do.map(f => (<Accordian faq={f} />))}
                        </div>
                    </div>
                </section> */}
                <ServiceBlock
                    title="Why Choose Buckss for D&O Insurance?"
                    subtitle=""
                    points={
                        [
                            "Expertise:Leveraging over 50 years of combined experience in the insurance industry.",
                            "Customized Solutions:Tailored insurance plans to meet your company’s specific needs.",
                            "Dedicated Support:Comprehensive support throughout the claims process.",
                            "Top Partnerships:Strong relationships with leading insurance providers for optimal coverage.",
                        ]
                    }
                    ctaLabel=""
                    ctaLink=""
                    image="/assets/img/insurance-agent-vector.png"
                    type="primary"
                    imageLocation="right"
                />
                <div className="mt-4">
                    <InsuranceServices />
                </div>
                <div className="mt-4">
                    <ProtectBusiness />
                </div>
            </div >
    <Footer />
        </>
    )
}

export default DirectorsInsurance