import Banner from "./components/Banner";
import Footer from "./components/Footer";
import Header from "./components/Header"
import ServiceBlock from "./components/ServiceBlock";

const Claims = () => {
    const banner = {
        title: "Guiding You Through Every Step of the Claims Process",
        subtitle: "Our team will assist you make your claim process faster. Our commitment is to ensure that the claim process is smooth and hassle-free.",
        ctaLabel: "Connect with us",
        ctaLink: '/contactus',
        image: "/assets/img/claim-banner.png",
        smallTitle: "CLAIM ASSISTANCE"
    }
    return (
        <>
            <Header />
            <Banner banner={banner} />
            <ServiceBlock
                title="Our Claim Support"
                subtitle="Our dedicated claim support team is here to assist you every step of the way, ensuring a smooth and efficient claim process."
                points={[]}
                ctaLabel=""
                ctaLink=""
                image="/assets/img/support.png"
                type="primary"
                imageLocation="left"
            />
            <ServiceBlock
                title="How to File a Claim"
                subtitle=""
                points={[
                    "Contact Us: Call or email our claims support team.",
                    "Provide Claim Information: Tell us about your claim.",
                    "Documentation: Submit documents as advised by our claims expert.",
                    "Resolution: We will assist you end to end to receive your claim settlement."
                ]}
                ctaLabel=""
                ctaLink=""
                image="/assets/img/fileclaim.png"
                type="light"
                imageLocation="right"
                />

<ServiceBlock
                title="Dedicated Assistance for Claims"
            subtitle=""
            points={[
                "Our team assists you at every step of the claim process to ensure a swift and fair settlement.",
                "Contact Us for Claims Support : Relationship Manager – Krishna MV – 9902317933. Call us for claims support.",
            ]}
            ctaLabel="Call Now"
            ctaLink="tel:9902317933"
            image="/assets/img/assistance.png"
            type="primary"
            imageLocation="left"
                />

<ServiceBlock
                title="Why Buckss for claims settlement"
            subtitle=""
            points={[
                "Get your claim in short time:  We will help you get your claim settlement faster.",
                "Avoid claim rejections: Our experts will guide you through all the necessary documents and procedures to get your claim settled.",
                "Get maximum assured claim amount: Our claims checklist service will ensure you miss nothing, and payout is maximised."
            ]}
            ctaLabel="Call Now"
            ctaLink="tel:9902317933"
            image="/assets/img/claim-settlement.png"
            type="light"
            imageLocation="right"
                />
                      <div className="row banner mx-lg-5 mx-4 my-4">
        <div className="col-12 col-lg-7 px-lg-5 carousel-slide">
          <p className="banner-smallTitle ">PROTECT YOUR BUSINESS</p>
          <p className="service-title">Get a Quick Insurance Quote</p>
          <p className="service-subtitle">Comprehensive suite of insurance benefits and insurance products to protect your team and your business.</p>
          <div>
            <div className="nav-item primary-button my-3 mr-3">
              <a className="nav-link" href="mailto:po@buckss.in">Get a Quote</a>
            </div>
            <div className="nav-item outline-button my-3 ml-3">
              <a className="nav-link" href="mailto:po@buckss.in">Contact Us</a>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-5">
          <img className="slider-img" src="/assets/img/get-quote.png" />
        </div>
      </div>
            <Footer />
        </>
    )
}

export default Claims;