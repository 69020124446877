import { useState } from "react"
import Footer from "../components/Footer"
import Header from "../components/Header"
import InnerHero from "../components/InnerHero"
import Experience from "../components/Experience"
import Side from "../components/Side"
import ServiceBlock from "../components/ServiceBlock"
import BSServiceBlock from "../components/BSServiceBlock"
import InsuranceServices from "../components/InsuranceServices"
import ProtectBusiness from "../components/ProtectBusiness"
import ClaimsExample from "../components/ClaimsExample"
import faq from "../data/faq"
import Accordian from "../components/Accordian"
import Slider from "react-slick"
import settings from "../lib/SliderSettings"

const SingleTransistInsurance = () => {
    const [activeW, setActiveW] = useState('what')
    const [isCoverage, setCoverage] = useState(true)
    const coverages = [
        {
            title: "Cargo Damage",
            content: "Covers physical loss or damage to goods due to accidents, mishandling, or natural calamities."
        },
        {
            title: "Theft and Pilferage",
            content: "Protects against theft or pilferage of goods during transit."
        },
        {
            title: "Fire",
            content: "Covers loss or damage to goods due to fire during transit"
        },
        {
            title: "Collision",
            content: "Protects against damage caused by collisions or overturning of the transport vehicle."
        },
        {
            title: "Loading and Unloading",
            content: "Covers damages that occur during the loading and unloading of goods."
        },
        {
            title: "Strikes and Riots",
            content: "Provides coverage for loss or damage due to strikes, riots, and civil commotions."
        },
        {
            title: "General Average",
            content: "Covers the insured's contribution to general average losses in case of maritime incidents."
        }
    ]
    const exclusion = [
        {
            title: "Willful Misconduct",
            content: "Damages due to intentional acts or negligence by the insured."
        },
        {
            title: "Inherent Vice",
            content: "Losses arising from the inherent nature of the goods (e.g., perishable goods spoiling)."
        },
        {
            title: "Improper Packing",
            content: "Damages resulting from inadequate or improper packing."
        },
        {
            title: "War and Terrorism",
            content: "Losses caused by war, invasion, or acts of terrorism"
        },
        {
            title: "Nuclear Risks",
            content: "Damages due to nuclear reactions or radiation."
        },
        {
            title: "Delay",
            content: "Financial losses due to delays in transit"
        },
        {
            title: "Customs Rejection",
            content: "Losses due to the rejection of goods by customs authorities."
        },
        {
            title: "Government Action",
            content: "Losses resulting from government actions or regulations."
        }
    ]
    return (
        <>
            <Header />
            <div className="">
                <InnerHero
                    heading="Single Transit Policy in Marine Insuranc"
                    title="Safeguard your goods during one-time shipments with our comprehensive Single Transit Policy."
                    image="/assets/img/marine-open-hero.png"
                />
                <Experience />
                <section className="w3-section my-5 px-lg-5 px-lg-80">
                    <div className="d-flex flex-row align-items-center justify-content-between px-4">
                        <div onClick={() => setActiveW('what')} className={`pointer ${activeW === 'what' ? 'active-w3' : ''}`}>
                            <p>What</p>
                        </div>
                        <div onClick={() => setActiveW('who')} className={`pointer ${activeW === 'who' ? 'active-w3' : ''}`}>
                            <p>Who</p>
                        </div>
                        <div onClick={() => setActiveW('why')} className={`pointer ${activeW === 'why' ? 'active-w3' : ''}`}>
                            <p>Why</p>
                        </div>
                        <div onClick={() => setActiveW('coverage')} className={`pointer ${activeW === 'coverage' ? 'active-w3' : ''}`}>
                            <p>Coverages & Exclusions</p>
                        </div>
                    </div>
                </section>
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">What Is Single Transit Policy in Marine Insurance? </h1>
                        <p className="mt-5 content-regular">A Single Transit Policy in Marine Insurance provides coverage for a specific, one-time shipment of goods from one location to another. This type of policy is ideal for businesses that do not require continuous coverage but need protection for occasional shipments. The policy covers the cargo against various risks, including damage or loss during transit by sea, air, or land.</p>
                    </div>
                </section>
                <section className="coverage-section">
                    <div className="px-lg-5 px-lg-80">
                        <h1 className="title-primary-color mb-4">Key Benefits of Single Transit Policy:</h1>
                        <Slider {...settings}>
                            <Side
                                heading="Side A"
                                content="Comprehensive Coverage"
                                image="/assets/img/sideA.png"
                                description="Protects goods against a wide range of risks during transit."
                            />
                            <Side
                                heading="Side B"
                                content="Flexibility"
                                image="/assets/img/sideB.png"
                                description="Ideal for one-time or occasional shipments."
                            />
                            <Side
                                heading="Side C"
                                content="Cost-Effective"
                                image="/assets/img/sideC.png"
                                description="Pay only for the specific transit, not for continuous coverage."
                            />
                            <Side
                                heading="Side D"
                                content="Customizable"
                                image="/assets/img/sideC.png"
                                description="Tailored to meet the specific needs of each shipment."
                            />
                            <Side
                                heading="Side E"
                                content="Global Reach"
                                image="/assets/img/sideC.png"
                                description="Coverage available for both domestic and international shipments."
                            />
                        </Slider>

                    </div>
                    <ServiceBlock
                        title="Advantages of Single Transit Policy"
                        subtitle="A narrative about how Buckss was founded, the inspiration behind the company, and its journey so far."
                        points={["Protection against loss or damage to goods:","Coverage for various modes of transport (sea, air, land):","Financial security for one-time shipments:","Flexibility to insure only when needed:","Peace of mind during critical shipments:"]}
                        ctaLabel=""
                        ctaLink=""
                        image="/assets/img/do-advantage.png"
                        type="primary"
                        imageLocation="right"
                    />
                    
                </section>
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">Who Needs Single Transit Policy?</h1>
                        <p className="mt-5 content-regular">A Single Transit Policy is essential for any business or individual involved in occasional or one-time shipments of goods. It is particularly beneficial for: Small and medium-sized enterprises (SMEs)
,Importers and exporters, Manufacturers and distributors, E-commerce businesses, Individuals shipping valuable items</p>
                    </div>
                </section>
                <ServiceBlock
                    title="Factors Influencing Single Transit Policy Coverage"
                    subtitle=""
                    points={["Type and value of goods being shipped:","Mode of transportation (sea, air, land):", "Distance and route of shipment:", "Shipping frequency and volume:","Risk appetite and business requirements:"]}
                    ctaLabel=""
                    ctaLink=""
                    image="/assets/img/do-coverage-factor.png"
                    type="primary"
                    imageLocation="right"
                />
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">Why Get Single Transit Policy?</h1>
                        <p className="my-5 content-regular">A Single Transit Policy in Marine Insurance is crucial for protecting your goods during one-time shipments. The policy provides:</p>
                        <BSServiceBlock points={["Coverage for loss or damage to goods during transit:", "Financial protection against unforeseen events:", "Assurance of safe and secure delivery:","Peace of mind for both sender and receiver:","Flexibility to insure only when necessary:"]} />
                    </div>
                </section>
                <ServiceBlock
                    title="Who Can Benefit from Single Transit Policy?"
                    subtitle=""
                    points={["Businesses with occasional shipping needs:", "Importers and exporters requiring coverage for specific shipments:", "Manufacturers and distributors transporting goods infrequently:", "Individuals shipping high-value items:"]}
                    ctaLabel=""
                    ctaLink=""
                    image="/assets/img/litigation.png"
                    type="primary"
                    imageLocation="right"
                />
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">Coverages & Exclusions in D&O Insurance*</h1>
                        <section className="w3-section my-5">
                            <div className="d-flex flex-row">
                                <div onClick={() => setCoverage(true)} className={`mr-4 pointer ${isCoverage ? 'active-w3' : ''}`}>
                                    <p>Coverages</p>
                                </div>
                                <div onClick={() => setCoverage(false)} className={`pointer ${!isCoverage ? 'active-w3' : ''}`}>
                                    <p>Exclusions</p>
                                </div>
                            </div>
                        </section>
                        <div>
                            {isCoverage && (
                                <p>D&O insurance offers comprehensive coverage to protect against various risks. Key coverages include</p>
                            )}
                            {!isCoverage && (
                                <p>Certain situations are excluded from D&O coverage, including:</p>
                            )}

                        </div>
                        {isCoverage && (
                            <div className="row">
                                {coverages.map((c, i) => (
                                    <div className="col-12 col-lg-6">
                                        <h5 className="title-primary-color my-3">{`${i + 1}. ${c.title}`}</h5>
                                        <p>{`${c.content}`}</p>
                                    </div>
                                ))}
                            </div>
                        )}
                        {!isCoverage && (
                            <div className="row">
                                {exclusion.map((c, i) => (
                                    <div className="col-12 col-lg-6">
                                        <h5 className="title-primary-color my-3">{`${i + 1}. ${c.title}`}</h5>
                                        <p>{`${c.content}`}</p>
                                    </div>
                                ))}
                            </div>
                        )}

                    </div>
                </section>
                <ClaimsExample 
                    title="Single Transit Policy Claims Examples"
                    subtitle="Case of Damaged Electronics During Shipment"
                    incident="A manufacturer shipped a batch of high-value electronics to a retailer. During transit, the truck carrying the goods was involved in an accident, causing significant damage to the electronics."
                    resolution="The Single Transit Policy covered the cost of the damaged goods, ensuring that the manufacturer did not suffer a financial loss. The claim process was swift, allowing the manufacturer to quickly replace the damaged goods and fulfill the retailer's order."
                    image="/assets/img/claims-example.png"
                />
                {/* <section className="my-4">
                    <p className={`service-title title-primary-color text-center`}>{'Frequently Asked Questions'}</p>
                    <div className=" p-5">
                    <div id="accordion" className="px-lg-5 px-lg-80 p-5">
                        {faq.singleTransist.map(f => (<Accordian faq={f} />))}
                    </div>
                </div>
                </section> */}
                <ServiceBlock
                    title="Why Choose Buckss for Single Transit Policy in Marine Insurance?"
                    subtitle=""
                    points={
                        [
                            "Expertise:Leveraging over 50 years of combined experience in the insurance industry.",
                            "Tailored Solutions: Customized Single Transit Policy plans to suit your shipping needs.",
                            "Dedicated Support:Comprehensive support throughout the claims process.",
                            "Top Partnerships:Strong relationships with leading insurance providers for optimal coverage.",
                        ]
                    }
                    ctaLabel=""
                    ctaLink=""
                    image="/assets/img/insurance-agent-vector.png"
                    type="primary"
                    imageLocation="right"
                />
                <div className="mt-4">
                        <InsuranceServices />
                    </div>
               <div className="mt-4">
               <ProtectBusiness />
               </div>
            </div>
            <Footer />
        </>
    )
}

export default SingleTransistInsurance