import React from 'react';
import ReactDOM from 'react-dom';
import {
  Router,
  Switch,
  Route
} from 'react-router-dom'
import './index.css';
import './style.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import createBrowserHistory from 'history/createBrowserHistory'
import AboutUs from './AboutUs';
import ContactUs from './ContactUs';
import Services from './Services';
import Claims from './Claims';
import Terms from './Terms';
import PrivacyPolicy from './PrivacyPolicy';
import DirectorsInsurance from './pages/DirectorsInsurance';
import IndemnityInsurance from './pages/IndemnityInsurance';
import ProductLiabilityInsurance from './pages/ProductLiabilityInsurance';
import FireInsurance from './pages/FireInsurance';
import FireLossInsurance from './pages/FireLossInsurance';
import MachineryBreakdownInsurance from './pages/MachineryBreakdownInsurance';
import MachineryLossInsurance from './pages/MachineryLossInsurance';
import OfficePackageInsurance from './pages/OfficePackageInsurance';
import SingleTransistInsurance from './pages/SingleTransistInsurance';
import MarineOpenInsurance from './pages/MarineOpenInsurance';
import GroupHealthInsurance from './pages/GroupHealthInsurance';
import GroupPersonalAccident from './pages/GroupPersonalAccident';
import GroupTermInsurance from './pages/GroupTermInsurance';
import SalesTurnoverInsurance from './pages/SalesTurnoverInsurance';
import ErrorsOmmisionInsurance from './pages/ErrorsOmissionInsurance';
import DoctorsInsurance from './pages/Doctorsinsurance';
import GeneralLiability from './pages/GeneralLiability';
import CyberInsurance from './pages/CyberInsurance';
import CrimeInsurance from './pages/CrimeInsurance';
import ContractorInsurance from './pages/ContractorInsurance';
import WorkmanCompensation from './pages/WorkmanCompensation';
import ErectionInsurance from './pages/ErectionInsurance';
import CommercialVehicleInsurance from './pages/CommercialVehicleInsurance';
import GroupTravelInsurance from './pages/GroupTravelInsurance';
import ContractorPlantMachiner from './pages/ContractorPlantMachiner';
export const history = createBrowserHistory()

ReactDOM.render(
  <React.StrictMode>
    <Router history={history}>
      <Switch>
        <Route path="/" render={() => <App />} exact />
        <Route path="/services" render={() => <Services />} exact />
        <Route path="/claims" render={() => <Claims />} exact />
        <Route path="/aboutus" render={() => <AboutUs />} exact />
        <Route path="/contactus" render={() => <ContactUs />} />
        <Route path="/terms" render={() => <Terms />} />
        <Route path="/privacy" render={() => <PrivacyPolicy />} />
        <Route path="/director-officer-insurance" render={() => <DirectorsInsurance />} />
        <Route path="/indemnity-insurance" render={() => <IndemnityInsurance />} />
        <Route path="/product-liability-insurance" render={() => <ProductLiabilityInsurance />} />
        <Route path="/fire-insurance" render={() => <FireInsurance />} />
        <Route path="/fire-loss-insurance" render={() => <FireLossInsurance />} />
        <Route path="/machinery-breakdown-insurance" render={() => <MachineryBreakdownInsurance />} />
        <Route path="/machinery-loss-insurance" render={() => <MachineryLossInsurance />} />
        <Route path="/office-package-insurance" render={() => <OfficePackageInsurance />} />
        <Route path="/single-transist-insurance" render={() => <SingleTransistInsurance />} />
        <Route path="/marine-open-insurance" render={() => <MarineOpenInsurance />} />
        <Route path="/sales-turnover-insurance" render={() => <SalesTurnoverInsurance />} />
        <Route path="/group-health-insurance" render={() => <GroupHealthInsurance />} />
        <Route path="/group-personal-accident-insurance" render={() => <GroupPersonalAccident />} />
        <Route path="/group-term-insurance" render={() => <GroupTermInsurance />} />
        <Route path="/errors-omissions-insurance" render={() => <ErrorsOmmisionInsurance />} />
        <Route path="/doctors-insurnace" render={() => <DoctorsInsurance/>} />
        <Route path="/general-liability-insurance" render={() => <GeneralLiability/>} />
        <Route path="/cyber-insurance" render={() => <CyberInsurance/>} />
        <Route path="/crime-insurance" render={() => <CrimeInsurance/>} />
        <Route path="/contractor-insurance" render={() => <ContractorInsurance/>}/>
        <Route path="/workman-compensation" render={()=><WorkmanCompensation/>}/>
        <Route path="/erection-insurance" render={()=><ErectionInsurance/>}/>
        <Route path="/commercial-vehicle-insurance" render={() => <CommercialVehicleInsurance/>} />
        <Route path="/group-travel-insurance" render={() => <GroupTravelInsurance/>} />
        <Route path="/contractors-plant-machinery-insurance" render={() => <ContractorPlantMachiner/>} />
        
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
