import { useState } from "react"
import Footer from "../components/Footer"
import Header from "../components/Header"
import InnerHero from "../components/InnerHero"
import Experience from "../components/Experience"
import Side from "../components/Side"
import ServiceBlock from "../components/ServiceBlock"
import InsuranceServices from "../components/InsuranceServices"
import ProtectBusiness from "../components/ProtectBusiness"
import ClaimsExample from "../components/ClaimsExample"
import faq from "../data/faq"
import Accordian from "../components/Accordian"
import Slider from "react-slick"
import settings from "../lib/SliderSettings"

const MarineOpenInsurance = () => {
    const [activeW, setActiveW] = useState('what')
    const [isCoverage, setCoverage] = useState(true)
    const coverages = [
        {
            title: "Damage to Goods",
            content: "Covers physical damage to goods during transit due to accidents, handling errors, or natural disasters."
        },
        {
            title: "Loss of Goods",
            content: "Protection against loss of cargo due to theft, piracy, or misplacement during transportation."
        },
        {
            title: "General Average",
            content: "Covers your contribution to shared losses in maritime transport situations."
        },
        {
            title: "War and Strikes Coverage",
            content: "Optional coverage for risks associated with war, strikes, and civil commotions."
        },
        {
            title: "Delay in Transit",
            content: "Protection against financial losses due to delays in the delivery of goods."
        },
        {
            title: "Warehouse to Warehouse",
            content: "Coverage from the point of origin, through transit, to the final destination, including temporary storage."
        }
    ]
    const exclusion = []
    return (
        <>
            <Header />
            <div className="">
                <InnerHero
                    heading="Comprehensive Marine Open Policy Insurance for Your Business"
                    title="Protect your goods in transit with our Marine Open Policy Insurance. Whether you're transporting cargo by sea, air, or land, Buckss offers seamless coverage to safeguard your business from unforeseen risks. Get tailored protection for every shipment under one flexible policy."
                    image="/assets/img/do.png"
                />
                <Experience />
                <section className="w3-section my-5 px-lg-5 px-lg-80">
                    <div className="d-flex flex-row align-items-center justify-content-between px-4">
                        <div onClick={() => setActiveW('what')} className={`pointer ${activeW === 'what' ? 'active-w3' : ''}`}>
                            <p>What</p>
                        </div>
                        <div onClick={() => setActiveW('who')} className={`pointer ${activeW === 'who' ? 'active-w3' : ''}`}>
                            <p>Who</p>
                        </div>
                        <div onClick={() => setActiveW('why')} className={`pointer ${activeW === 'why' ? 'active-w3' : ''}`}>
                            <p>Why</p>
                        </div>
                        <div onClick={() => setActiveW('coverage')} className={`pointer ${activeW === 'coverage' ? 'active-w3' : ''}`}>
                            <p>Coverages & Exclusions</p>
                        </div>
                    </div>
                </section>
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">What is Marine Open Policy Insurance?</h1>
                        <p className="mt-5 content-regular">Marine Open Policy Insurance is a flexible and continuous coverage option designed for businesses involved in regular shipments of goods. Instead of taking out individual policies for each shipment, this policy provides ongoing coverage for all shipments made within a specified period. Whether your goods are transported by sea, air, or land, a Marine Open Policy ensures they are protected against risks like damage, loss, or theft throughout the journey.</p>
                    </div>
                </section>
                <section className="coverage-section">
                    <div className="px-lg-5 px-lg-80">
                        <h1 className="title-primary-color mb-4">Key Features of Marine Open Policy Insurance</h1>
                        <Slider {...settings}>
                            <Side
                                heading="Side A"
                                content="Continuous Coverage"
                                image="/assets/img/sideA.png"
                                description="Provides blanket coverage for multiple shipments over a set period."
                            />
                            <Side
                                heading="Side B"
                                content="Flexible and Customizable"
                                image="/assets/img/sideB.png"
                                description="Tailored to meet the specific needs of your business and the nature of your shipments."
                            />
                            <Side
                                heading="Side C"
                                content="Comprehensive Protection"
                                image="/assets/img/sideC.png"
                                description="Covers various modes of transportation, including sea, air, and land."
                            />
                            <Side
                                heading="Side D"
                                content="Efficient Administration"
                                image="/assets/img/sideC.png"
                                description="Simplifies the process of insuring shipments, reducing administrative burden."
                            />
                        </Slider>

                    </div>
                    <ServiceBlock
                        title="Benefits of Marine Open Policy Insurance"
                        subtitle="A narrative about how Buckss was founded, the inspiration behind the company, and its journey so far."
                        points={["Convenience: Eliminates the need to arrange separate insurance for each shipment.", "Cost-Effective:Potentially lower premiums compared to individual shipment policies.", "Comprehensive Coverage: Protects goods from the point of origin to the final destination.", "Tailored Solutions: Policies designed to fit the specific requirements of your business and cargo."]}
                        ctaLabel=""
                        ctaLink=""
                        image="/assets/img/do-advantage.png"
                        type="primary"
                        imageLocation="right"
                    />
                </section>
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">Who Needs Marine Open Policy Insurance?</h1>
                        <p className="mt-5 content-regular">Marine Open Policy Insurance is essential for businesses that frequently ship goods domestically or internationally. If your business involves regular transportation of products, raw materials, or equipment, this policy ensures that every shipment is automatically covered without the need to arrange separate insurance each time.</p>
                    </div>
                </section>
                <ServiceBlock
                    title="Ideal for:"
                    subtitle=""
                    points={["Importers and Exporters: Businesses involved in international trade", "Manufacturers:Companies shipping raw materials or finished products.", "Distributors:Businesses distributing goods to various locations.", "Retailers:E-commerce and retail businesses requiring frequent shipping of goods.", "Logistics Providers:Companies offering transport and logistics services to clients."]}
                    ctaLabel=""
                    ctaLink=""
                    image="/assets/img/do-coverage-factor.png"
                    type="primary"
                    imageLocation="right"
                />
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">Why Choose Marine Open Policy Insurance?</h1>
                        <p className="my-5 content-regular">Marine Open Policy Insurance is ideal for businesses that regularly ship goods and want a hassle-free, comprehensive insurance solution. It allows you to focus on your business operations, knowing that your cargo is covered from the moment it leaves the warehouse to its final destination. Buckss' Marine Open Policy offers flexibility, ease, and peace of mind, ensuring your goods are protected throughout their journey.
                        </p>
                    </div>
                </section>
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">Coverages & Exclusions in D&O Insurance*</h1>
                        <section className="w3-section my-5">
                            <div className="d-flex flex-row">
                                <div onClick={() => setCoverage(true)} className={`mr-4 pointer ${isCoverage ? 'active-w3' : ''}`}>
                                    <p>Coverages</p>
                                </div>
                                <div onClick={() => setCoverage(false)} className={`pointer ${!isCoverage ? 'active-w3' : ''}`}>
                                    <p>Exclusions</p>
                                </div>
                            </div>
                        </section>
                        <div>
                            {isCoverage && (
                                <p>Marine Open Policy Insurance from Buckss provides comprehensive coverage for goods in transit, whether by sea, air, or land. This policy is designed to protect your business from the various risks associated with shipping, ensuring that your cargo is safe throughout its journey.</p>
                            )}
                            

                        </div>
                        {isCoverage && (
                            <div className="row">
                                {coverages.map((c, i) => (
                                    <div className="col-12 col-lg-6">
                                        <h5 className="title-primary-color my-3">{`${i + 1}. ${c.title}`}</h5>
                                        <p>{`${c.content}`}</p>
                                    </div>
                                ))}
                            </div>
                        )}
                        {!isCoverage && (
                            <div className="row">
                                {exclusion.map((c, i) => (
                                    <div className="col-12 col-lg-6">
                                        <h5 className="title-primary-color my-3">{`${i + 1}. ${c.title}`}</h5>
                                        <p>{`${c.content}`}</p>
                                    </div>
                                ))}
                            </div>
                        )}

                    </div>
                </section>
                <ClaimsExample
                    title="Directors & Officers Claims Examples"
                    subtitle="Case of Employee Lawsuits for Unpaid Benefits"
                    incident="A group of employees sued a company's subsidiary in India for unpaid benefits and financial compensation."
                    resolution="Legal defense costs were incurred, and the insurer appointed a local law firm to handle the case. The outcome was favorable, with only the defense costs reimbursed under the D&O policy."
                    image="/assets/img/claims-example.png"
                />
                {/* <section className="my-4">
                    <p className={`service-title title-primary-color text-center`}>{'Frequently Asked Questions'}</p>
                    <div className=" p-5">
                        <div id="accordion" className="px-lg-5 px-lg-80 p-5">
                            {faq.do.map(f => (<Accordian faq={f} />))}
                        </div>
                    </div>
                </section> */}
                <ServiceBlock
                    title="Why Choose Buckss for Marine Open Policy Insurance?"
                    subtitle=""
                    points={
                        [
                            "Expertise:Leveraging over 50 years of combined experience in the insurance industry.",
                            "Customized Solutions:Tailored insurance plans to meet your company’s specific needs.",
                            "Dedicated Support:Comprehensive support throughout the claims process.",
                            "Top Partnerships:Strong relationships with leading insurance providers for optimal coverage.",
                        ]
                    }
                    ctaLabel=""
                    ctaLink=""
                    image="/assets/img/insurance-agent-vector.png"
                    type="primary"
                    imageLocation="right"
                />
                <div className="mt-4">
                    <InsuranceServices />
                </div>
                <div className="mt-4">
                    <ProtectBusiness />
                </div>
            </div>
            <Footer />
        </>
    )
}

export default MarineOpenInsurance