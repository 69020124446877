import { useState } from "react"
import Footer from "../components/Footer"
import Header from "../components/Header"
import InnerHero from "../components/InnerHero"
import Experience from "../components/Experience"
import Side from "../components/Side"
import ServiceBlock from "../components/ServiceBlock"
import BSServiceBlock from "../components/BSServiceBlock"
import InsuranceServices from "../components/InsuranceServices"
import ProtectBusiness from "../components/ProtectBusiness"
import ClaimsExample from "../components/ClaimsExample"
import faq from "../data/faq"
import Accordian from "../components/Accordian"
import Slider from "react-slick"
import settings from "../lib/SliderSettings"

const GroupTermInsurance = () => {
    const [activeW, setActiveW] = useState('what')
    const [isCoverage, setCoverage] = useState(true)
    const coverages = [
        {
            title: "Death Benefit",
            content: "Lump sum payment to the nominee in case of the insured's death."
        },
        {
            title: "Accidental Death Benefit",
            content: "Additional payout in case of death due to an accident."
        },
        {
            title: "Terminal Illness Cover",
            content: "Lump sum payment if the insured is diagnosed with a terminal illness."
        },
        {
            title: "Critical Illness Rider",
            content: "Optional coverage for critical illnesses, providing financial support during treatment."
        },
        {
            title: "Total and Permanent Disability",
            content: " Lump sum payment in case of total and permanent disability due to an accident or illness."
        }
    ]
    const exclusion = [
        {
            title: "Suicide",
            content: "Death due to suicide within the first year of the policy is excluded."
        },
        {
            title: "War and Terrorism",
            content: "Death resulting from war, terrorism, or related activities is not covered."
        },
        {
            title: "Substance Abuse",
            content: "Death due to drug or alcohol abuse is excluded."
        },
        {
            title: "Pre-Existing Conditions",
            content: "Death due to pre-existing medical conditions not disclosed at the time of policy issuance."
        },
        {
            title: "Participation in Hazardous Activities",
            content: "Death while participating in hazardous activities like skydiving, bungee jumping, etc."
        }
    ]
    return (
        <>
            <Header />
            <div className="">
                <InnerHero
                    heading="Group Term Insurance"
                    title="Secure the future of your employees and their families with Buckss Group Term Insurance. Comprehensive coverage at competitive rates to ensure peace of mind."
                    image="/assets/img/gta-hero.png"
                />
                <Experience />
                <section className="w3-section my-5 px-lg-5 px-lg-80">
                    <div className="d-flex flex-row align-items-center justify-content-between px-4">
                        <div onClick={() => setActiveW('what')} className={`pointer ${activeW === 'what' ? 'active-w3' : ''}`}>
                            <p>What</p>
                        </div>
                        <div onClick={() => setActiveW('who')} className={`pointer ${activeW === 'who' ? 'active-w3' : ''}`}>
                            <p>Who</p>
                        </div>
                        <div onClick={() => setActiveW('why')} className={`pointer ${activeW === 'why' ? 'active-w3' : ''}`}>
                            <p>Why</p>
                        </div>
                        <div onClick={() => setActiveW('coverage')} className={`pointer ${activeW === 'coverage' ? 'active-w3' : ''}`}>
                            <p>Coverages & Exclusions</p>
                        </div>
                    </div>
                </section>
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">What Is Group Term Insurance?</h1>
                        <p className="mt-5 content-regular">Group Term Insurance provides life coverage to a group of individuals under a single policy. Typically offered by employers to their employees, this insurance ensures financial security for the employee's family in the event of their untimely death. It offers a lump sum payout to the beneficiaries, helping them manage financial responsibilities and maintain their standard of living.
                        </p>
                    </div>
                </section>
                <section className="coverage-section">
                    <div className="px-lg-5 px-lg-80">
                        <h1 className="title-primary-color mb-4">Key Features of Group Term Insurance</h1>
                        <Slider {...settings}>
                            <Side
                                heading="Side A"
                                content="Affordable Premiums"
                                image="/assets/img/sideA.png"
                                description="Economical rates due to the pooling of risks"
                            />
                            <Side
                                heading="Side B"
                                content="Simplified Administration"
                                image="/assets/img/sideB.png"
                                description="One policy covers multiple employees, simplifying management."
                            />
                            <Side
                                heading="Side C"
                                content="No Medical Examinations Required"
                                image="/assets/img/sideC.png"
                                description="Employees are usually covered without the need for medical tests."
                            />
                            <Side
                                heading="Side D"
                                content="Customizable Coverage"
                                image="/assets/img/sideC.png"
                                description="Flexible plans to suit the specific needs of your organization and employees."
                            />
                        </Slider>

                    </div>
                    <ServiceBlock
                        title="Who Benefits from Group Term Insurance?"
                        subtitle=""
                        points={["Employees and their families","Employers looking to provide competitive benefits","HR professionals managing employee benefits","Business owners focused on workforce well-being"]}
                        ctaLabel=""
                        ctaLink=""
                        image="/assets/img/do-advantage.png"
                        type="primary"
                        imageLocation="right"
                    />
                </section>
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">Who Needs Group Term Insurance?</h1>
                        <p className="mt-5 content-regular">Group Term Insurance is ideal for any organization that aims to provide financial security and peace of mind to its employees. This insurance is particularly beneficial for: Large corporations with numerous employees, Small and medium-sized enterprises (SMEs), Startups looking to offer competitive employee benefits, Non-profit organizations and educational institutions, Any employer committed to the well-being of their workforce</p>
                    </div>
                </section>
                <ServiceBlock
                    title="Factors Influencing Group Term Insurance Coverage"
                    subtitle=""
                    points={["Size of the employee group:","Age and health profile of employees:","Coverage limits and policy duration:","Industry risk factors:","Budget and financial considerations:"]}
                    ctaLabel=""
                    ctaLink=""
                    image="/assets/img/do-coverage-factor.png"
                    type="primary"
                    imageLocation="right"
                />
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">Why Get Directors & Officers Liability Insurance?</h1>
                        <p className="my-5 content-regular">Directors and officers are vulnerable to numerous financial risks in their roles. D&O insurance provides a safety net, protecting them from a wide range of potential legal challenges, including:</p>
                        <BSServiceBlock points={["Stakeholder lawsuits:", "Allegations of wrongful acts:", "Regulatory investigations and fines:", "Financial mismanagement and reporting errors:", "Employment-related issues such as harassment or discrimination:"]} />
                    </div>
                </section>
                <ServiceBlock
                    title="Why Get Group Term Insurance?"
                    subtitle="Group Term Insurance provides essential financial protection for employees' families, ensuring peace of mind and stability. The key reasons to opt for this coverage include:"
                    points={["Financial Security: Ensures a financial safety net for the employee’s family in case of untimely death.", "Employee Retention: Demonstrates the employer's commitment to their employees' well-being","Increased Productivity: Employees with financial security for their families tend to be more focused and productive.","Tax Benefits: Premiums paid by the employer can be tax-deductible, providing financial advantages.","Enhanced Reputation: Companies offering comprehensive benefits are more attractive to potential hires and clients."]}
                    ctaLabel=""
                    ctaLink=""
                    image="/assets/img/litigation.png"
                    type="primary"
                    imageLocation="right"
                />
                <ServiceBlock
                    title="Benefits for Employers"
                    subtitle="Employers who offer Group Term Insurance can enjoy several advantages:"
                    points={["Attract and Retain Talent: Providing Group Term Insurance helps attract and retain skilled employees.","Tax Advantages: Premiums paid are often tax-deductible, reducing overall tax liability.","Enhanced Employee Loyalty: Demonstrating concern for employees' families boosts loyalty and morale.","Simplified Policy Management: One policy covers all employees, simplifying administration.","Competitive Edge: Offering comprehensive benefits enhances the company's reputation and competitiveness."]}
                    ctaLabel=""
                    ctaLink=""
                    image="/assets/img/litigation.png"
                    type="light"
                    imageLocation="left"
                />
                <ServiceBlock
                    title="Benefits for Employees"
                    subtitle="Employees covered under a Group Term Insurance plan enjoy numerous benefits:"
                    points={["Financial Security: Provides a lump sum payout to the family in case of the employee's death, ensuring financial stability.","No Medical Tests: Coverage without the need for medical examinations.","Peace of Mind: Employees can work with the assurance that their families are protected.","Cost-Effective: Coverage at lower premiums compared to individual policies.","Flexible Coverage: Options to customize coverage according to specific needs and preferences."]}
                    ctaLabel=""
                    ctaLink=""
                    image="/assets/img/litigation.png"
                    type="primary"
                    imageLocation="right"
                />
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">Coverages & Exclusions in Group Term Insurance*</h1>
                        <section className="w3-section my-5">
                            <div className="d-flex flex-row">
                                <div onClick={() => setCoverage(true)} className={`mr-4 pointer ${isCoverage ? 'active-w3' : ''}`}>
                                    <p>Coverages</p>
                                </div>
                                <div onClick={() => setCoverage(false)} className={`pointer ${!isCoverage ? 'active-w3' : ''}`}>
                                    <p>Exclusions</p>
                                </div>
                            </div>
                        </section>
                        <div>
                            {isCoverage && (
                                <p>Group Term Insurance provides extensive coverage to protect employees' families. Key coverages include:</p>
                            )}
                            {!isCoverage && (
                                <p>While Group Term Insurance offers comprehensive coverage, some exclusions typically include:</p>
                            )}

                        </div>
                        {isCoverage && (
                            <div className="row">
                                {coverages.map((c, i) => (
                                    <div className="col-12 col-lg-6">
                                        <h5 className="title-primary-color my-3">{`${i + 1}. ${c.title}`}</h5>
                                        <p>{`${c.content}`}</p>
                                    </div>
                                ))}
                            </div>
                        )}
                        {!isCoverage && (
                            <div className="row">
                                {exclusion.map((c, i) => (
                                    <div className="col-12 col-lg-6">
                                        <h5 className="title-primary-color my-3">{`${i + 1}. ${c.title}`}</h5>
                                        <p>{`${c.content}`}</p>
                                    </div>
                                ))}
                            </div>
                        )}

                    </div>
                </section>
                <ClaimsExample 
                    title="Group Term Insurance Claims Examples"
                    subtitle="Case of Untimely Death"
                    incident="An employee passed away unexpectedly due to a heart attack."
                    resolution="The Group Term Insurance policy provided a lump sum payment to the employee’s family, helping them manage funeral expenses and maintain their financial stability during a difficult time."
                    image="/assets/img/claims-example.png"
                />
                {/* <section className="my-4">
                    <p className={`service-title title-primary-color text-center`}>{'Frequently Asked Questions'}</p>
                    <div className=" p-5">
                    <div id="accordion" className="px-lg-5 px-lg-80 p-5">
                        {faq.groupTerm.map(f => (<Accordian faq={f} />))}
                    </div>
                </div>
                </section> */}
                <ServiceBlock
                    title="Why Choose Buckss for Group Term Insurance? "
                    subtitle=""
                    points={
                        [
                            "Expertise:Leveraging over 50 years of combined experience in the insurance industry.",
                            "Tailored Solutions: Customized Group Term Insurance plans to suit your business needs.",
                            "Dedicated Support:Comprehensive support throughout the claims process.",
                            "Top Partnerships:Strong relationships with leading insurance providers for optimal coverage.",
                        ]
                    }
                    ctaLabel=""
                    ctaLink=""
                    image="/assets/img/insurance-agent-vector.png"
                    type="primary"
                    imageLocation="right"
                />
                <div className="mt-4">
                        <InsuranceServices />
                    </div>
               <div className="mt-4">
               <ProtectBusiness />
               </div>
            </div>
            <Footer />
        </>
    )
}

export default GroupTermInsurance