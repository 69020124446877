import { useState } from "react"
import Footer from "../components/Footer"
import Header from "../components/Header"
import InnerHero from "../components/InnerHero"
import Experience from "../components/Experience"
import Side from "../components/Side"
import ServiceBlock from "../components/ServiceBlock"
import BSServiceBlock from "../components/BSServiceBlock"
import InsuranceServices from "../components/InsuranceServices"
import ProtectBusiness from "../components/ProtectBusiness"
import ClaimsExample from "../components/ClaimsExample"
import faq from "../data/faq"
import Accordian from "../components/Accordian"
import Slider from "react-slick"
import settings from "../lib/SliderSettings"

const MachineryBreakdownInsurance = () => {
    const [activeW, setActiveW] = useState('what')
    const [isCoverage, setCoverage] = useState(true)
    const coverages = [
        {
            title: "Repair Costs:",
            content: "Covers the cost of repairing damaged machinery"
        },
        {
            title: "Replacement Costs:",
            content: "Compensates for the replacement of irreparable machinery"
        },
        {
            title: "Business Interruption:",
            content: "Provides compensation for lost income and additional expenses incurred while restoring operations."
        },
        {
            title: "Debris Removal",
            content: " Covers the cost of removing debris after a machinery breakdown."
        },
        {
            title: "Temporary Relocation Costs",
            content: "Covers the expenses of temporarily relocating the business during machinery repairs."
        },
        {
            title: "Damage to Surrounding Property:",
            content: "Covers damages caused to surrounding properties due to the machinery breakdown."
        },
        {
            title: "Extra Expenses",
            content: "Covers additional expenses incurred to minimize business interruption and expedite repairs."
        },
        {
            title: "Expediting Costs:",
            content: "Covers the costs associated with expediting repairs or replacements of damaged machinery."
        }
    ]
    const exclusion = [
        {
            title: "Wear and Tear:",
            content: "Damages due to regular wear and tear or gradual deterioration."
        },
        {
            title: "Corrosion and Rust",
            content: "Losses caused by corrosion, rust, or oxidation."
        },
        {
            title: "Pre-existing Conditions:",
            content: "Damages or defects that existed before the policy inception."
        },
        {
            title: "Intentional Damage:",
            content: "Damages caused by deliberate actions or negligence."
        },
        {
            title: "Nuclear Risks:",
            content: "Losses due to nuclear reactions or radiation."
        },
        {
            title: "War and Terrorism",
            content: "Damages resulting from war, invasion, or acts of terrorism."
        },
        {
            title: "Improper Maintenance:",
            content: "Breakdowns due to improper or lack of maintenance."
        },
        {
            title: "Consequential Losses:",
            content: "Indirect losses not directly caused by machinery breakdown."
        }
    ]
    return (
        <>
            <Header />
            <div className="">
                <InnerHero
                    heading="Machinery Breakdown Insurance"
                    title="Protect your business from the financial impact of machinery failures with comprehensive Machinery Breakdown Insurance that covers repair costs, replacements, and business interruption losses. "
                    image="/assets/img/machinery-breakdown-insurance-hero.png"
                />
                <Experience />
                <section className="w3-section my-5 px-lg-5 px-lg-80">
                    <div className="d-flex flex-row align-items-center justify-content-between px-4">
                        <div onClick={() => setActiveW('what')} className={`pointer ${activeW === 'what' ? 'active-w3' : ''}`}>
                            <p>What</p>
                        </div>
                        <div onClick={() => setActiveW('who')} className={`pointer ${activeW === 'who' ? 'active-w3' : ''}`}>
                            <p>Who</p>
                        </div>
                        <div onClick={() => setActiveW('why')} className={`pointer ${activeW === 'why' ? 'active-w3' : ''}`}>
                            <p>Why</p>
                        </div>
                        <div onClick={() => setActiveW('coverage')} className={`pointer ${activeW === 'coverage' ? 'active-w3' : ''}`}>
                            <p>Coverages & Exclusions</p>
                        </div>
                    </div>
                </section>
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">What Is Machinery Breakdown Insurance?</h1>
                        <p className="mt-5 content-regular">Machinery Breakdown Insurance provides coverage for the sudden and accidental physical damage or breakdown of insured machinery. It ensures financial protection for the costs of repairs, replacements, and business interruption caused by machinery failures, helping businesses maintain operations and minimize losses.</p>
                    </div>
                </section>
                <section className="coverage-section">
                    <div className="px-lg-5 px-lg-80">
                        <h1 className="title-primary-color mb-4">Key Benefits of Machinery Breakdown Insurance</h1>
                        <Slider {...settings}>
                            <Side
                                heading="Side A"
                                content="Repair Costs Coverage"
                                image="/assets/img/sideA.png"
                                description="Covers the cost of repairing damaged machinery."
                            />
                            <Side
                                heading="Side B"
                                content="Replacement Costs Coverage"
                                image="/assets/img/sideB.png"
                                description="Compensates for the replacement of irreparable machinery."
                            />
                            <Side
                                heading="Side C"
                                content="Business Interruption Coverage"
                                image="/assets/img/sideC.png"
                                description="Provides compensation for income loss due to machinery breakdown."
                            />
                            <Side
                                heading="Side D"
                                content="Additional Expenses Coverage"
                                image="/assets/img/sideC.png"
                                description="Covers extra expenses incurred to minimize the business interruption."
                            />
                            <Side
                                heading="Side E"
                                content="Debris Removal Costs"
                                image="/assets/img/sideC.png"
                                description="Covers the cost of removing debris following a machinery breakdown."
                            />
                        </Slider>

                    </div>
                    <ServiceBlock
                        title="Advantages of Machinery Breakdown Insurance"
                        subtitle="A narrative about how Buckss was founded, the inspiration behind the company, and its journey so far."
                        points={["Comprehensive protection for critical machinery", "Minimizes financial impact of unexpected breakdowns", "Ensures business continuity", "Reduces downtime and operational disruptions", "Enhances business resilience and stability"]}
                        ctaLabel=""
                        ctaLink=""
                        image="/assets/img/do-advantage.png"
                        type="primary"
                        imageLocation="right"
                    />
                </section>
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">Who Needs Machinery Breakdown Insurance?</h1>
                        <p className="mt-5 content-regular">Machinery Breakdown Insurance is essential for any business that relies on machinery and equipment for its operations. It is particularly crucial for: Manufacturing companies, Construction businesses, Agricultural enterprises, Food processing units, Textile industries, Printing and packaging firms, Healthcare facilities with medical equipment</p>
                    </div>
                </section>
                <ServiceBlock
                    title="Factors Influencing Machinery Breakdown Insurance Coverage"
                    subtitle=""
                    points={["Type and age of machinery:", "Usage and maintenance practices:", "Operating environment:", "Business size and operations:", "Historical breakdown data:"]}
                    ctaLabel=""
                    ctaLink=""
                    image="/assets/img/do-coverage-factor.png"
                    type="primary"
                    imageLocation="right"
                />
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">Why Get Machinery Breakdown Insurance?</h1>
                        <p className="my-5 content-regular">Machinery Breakdown Insurance is vital for protecting businesses from the financial consequences of machinery failures. The policy provides:</p>
                        <BSServiceBlock points={["Coverage for repair and replacement costs:", "Compensation for business interruption and income loss:", "Financial support for debris removal and additional expensess:", "Protection against unforeseen machinery breakdown risks:", "Assurance of business continuity and operational stability:"]} />
                    </div>
                </section>
                <ServiceBlock
                    title="Who Can Benefit from Machinery Breakdown Insurance?"
                    subtitle=""
                    points={["Business owners seeking to protect their machinery assets:", "Companies aiming to minimize financial loss from machinery breakdowns:", "Enterprises looking to ensure quick recovery and continuity post-breakdown:", "Organizations needing to comply with legal and regulatory requirements:"]}
                    ctaLabel=""
                    ctaLink=""
                    image="/assets/img/litigation.png"
                    type="primary"
                    imageLocation="right"
                />
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">Coverages & Exclusions in D&O Insurance*</h1>
                        <section className="w3-section my-5">
                            <div className="d-flex flex-row">
                                <div onClick={() => setCoverage(true)} className={`mr-4 pointer ${isCoverage ? 'active-w3' : ''}`}>
                                    <p>Coverages</p>
                                </div>
                                <div onClick={() => setCoverage(false)} className={`pointer ${!isCoverage ? 'active-w3' : ''}`}>
                                    <p>Exclusions</p>
                                </div>
                            </div>
                        </section>
                        <div>
                            {isCoverage && (
                                <p>Machinery Breakdown Insurance provides extensive coverage to protect businesses from the financial impact of machinery failures. Key coverages include:</p>
                            )}
                            {!isCoverage && (
                                <p>Machinery Breakdown Insurance typically excludes certain situations, including:</p>
                            )}

                        </div>
                        {isCoverage && (
                            <div className="row">
                                {coverages.map((c, i) => (
                                    <div className="col-12 col-lg-6">
                                        <h5 className="title-primary-color my-3">{`${i + 1}. ${c.title}`}</h5>
                                        <p>{`${c.content}`}</p>
                                    </div>
                                ))}
                            </div>
                        )}
                        {!isCoverage && (
                            <div className="row">
                                {exclusion.map((c, i) => (
                                    <div className="col-12 col-lg-6">
                                        <h5 className="title-primary-color my-3">{`${i + 1}. ${c.title}`}</h5>
                                        <p>{`${c.content}`}</p>
                                    </div>
                                ))}
                            </div>
                        )}

                    </div>
                </section>
                <ClaimsExample 
                    title="Machinery Breakdown Insurance Claims Examples"
                    subtitle="Case of a Manufacturing Unit Breakdown"
                    incident="A critical machine in a manufacturing unit broke down, causing a halt in production and significant income loss."
                    resolution="The Machinery Breakdown Insurance policy covered the costs of repairing the machine, compensated for the business interruption, and provided additional funds for temporary relocation. The business was able to resume operations quickly, minimizing financial losses."
                    image="/assets/img/claims-example.png"
                />
                {/* <section className="my-4">
                    <p className={`service-title title-primary-color text-center`}>{'Frequently Asked Questions'}</p>
                    <div className=" p-5">
                    <div id="accordion" className="px-lg-5 px-lg-80 p-5">
                        {faq.machineryBreakdown.map(f => (<Accordian faq={f} />))}
                    </div>
                </div>
                </section> */}
                <ServiceBlock
                    title="Why Choose Buckss for Machinery Breakdown Insurance?"
                    subtitle=""
                    points={
                        [
                            "Expertise:Leveraging over 50 years of combined experience in the insurance industry.",
                            "Tailored Solutions:Customized Machinery Breakdown Insurance plans to suit your business needs.",
                            "Dedicated Support:Comprehensive support throughout the claims process.",
                            "Top Partnerships:Strong relationships with leading insurance providers for optimal coverage.",
                        ]
                    }
                    ctaLabel=""
                    ctaLink=""
                    image="/assets/img/insurance-agent-vector.png"
                    type="primary"
                    imageLocation="right"
                />
                <div className="mt-4">
                        <InsuranceServices />
                    </div>
               <div className="mt-4">
               <ProtectBusiness />
               </div>
            </div>
            <Footer />
        </>
    )
}

export default MachineryBreakdownInsurance