import { useState } from "react"
import Footer from "../components/Footer"
import Header from "../components/Header"
import InnerHero from "../components/InnerHero"
import Experience from "../components/Experience"
import Side from "../components/Side"
import ServiceBlock from "../components/ServiceBlock"
import { converge, pathOr } from "ramda"
import BSServiceBlock from "../components/BSServiceBlock"
import InsuranceServices from "../components/InsuranceServices"
import ProtectBusiness from "../components/ProtectBusiness"
import ClaimsExample from "../components/ClaimsExample"
import faq from "../data/faq"
import Accordian from "../components/Accordian"
import Slider from "react-slick"
import settings from "../lib/SliderSettings"

const CyberInsurance = () => {
    const [activeW, setActiveW] = useState('what')
    const [isCoverage, setCoverage] = useState(true)
    const coverages = [
        {
            title: "Incident Response Costs",
            content: " Covers expenses for forensic investigations, public relations efforts, and customer notifications."
        },
        {
            title: "Cyber Extortion",
            content: "Covers ransom payments and related expenses to deal with cyber extortion threats."
        },
        {
            title: "Data Restoration",
            content: "Covers costs to restore or recover lost or damaged data due to a cyber incident."
        },
        {
            title: "Legal Defense Costs",
            content: "Covers legal fees and expenses incurred in defending against claims related to a cyber incident."
        },
        {
            title: "Regulatory Fines and Penalties",
            content: "Covers fines and penalties imposed by regulatory bodies due to non-compliance with data protection laws."
        },
        {
            title: "Business Interruption",
            content: "Covers loss of income and additional expenses incurred during the downtime caused by a cyber incident."
        },
        {
            title: "Reputation Management",
            content: "Covers costs associated with managing and mitigating reputational damage following a cyber incident."
        },
        {
            title: "Third-Party Liability",
            content: "Covers claims from third parties affected by a cyber incident involving your business."
        },
        
    ]
    const exclusion = [
        {
            title: "Insider Threats ",
            content: "Claims arising from intentional acts or misconduct by employees."
        },
        {
            title: "Prior Known Incidents",
            content: "Claims related to incidents known before the policy inception."
        },
        {
            title: "War and Terrorism",
            content: "Claims arising from acts of war or terrorism."
        },
        {
            title: "Bodily Injury",
            content: "Claims related to physical injury or property damage."
        },
        {
            title: "Contractual Liability",
            content: "Claims assumed under contract, unless they would have existed without the contract."
        },
        {
            title: "Patent Infringement",
            content: "Claims related to infringement of intellectual property rights."
        },
        {
            title: "Failure to Implement Security Measures",
            content: "Claims arising from the failure to maintain agreed-upon security standards."
        },
        {
            title: "Financial Loss",
            content: "Direct financial losses not related to a covered cyber event."
        }
    ]
    return (
        <>
            <Header />
            <div className="">
                <InnerHero
                    heading="Cyber Insurance"
                    title="Shield your business from the financial fallout of cyber threats and data breaches with comprehensive Cyber Insurance."
                    image="/assets/img/cyber-insurance-hero.png"
                />
                <Experience />
                <section className="w3-section my-5 px-lg-5 px-lg-80">
                    <div className="d-flex flex-row align-items-center justify-content-between px-4">
                        <div onClick={() => setActiveW('what')} className={`pointer ${activeW === 'what' ? 'active-w3' : ''}`}>
                            <p>What</p>
                        </div>
                        <div onClick={() => setActiveW('who')} className={`pointer ${activeW === 'who' ? 'active-w3' : ''}`}>
                            <p>Who</p>
                        </div>
                        <div onClick={() => setActiveW('why')} className={`pointer ${activeW === 'why' ? 'active-w3' : ''}`}>
                            <p>Why</p>
                        </div>
                        <div onClick={() => setActiveW('coverage')} className={`pointer ${activeW === 'coverage' ? 'active-w3' : ''}`}>
                            <p>Coverages & Exclusions</p>
                        </div>
                    </div>
                </section>
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">What Is General Liability Insurance?</h1>
                        <p className="mt-5 content-regular">Cyber Insurance is designed to protect businesses from the financial consequences of cyber attacks and data breaches. It covers a wide range of cyber risks, including data theft, ransomware attacks, and network security failures. This insurance provides financial support for legal fees, regulatory fines, and recovery costs associated with cyber incidents.
                        </p>
                    </div>
                </section>
                <section className="coverage-section">
                    <div className="px-lg-5 px-lg-80">
                        <h1 className="title-primary-color mb-4">Key Coverage Areas of Cyber Insurance</h1>
                        <Slider {...settings}>
                            <Side
                                heading="Data Breach Response"
                                content=""
                                image="/assets/img/sideA.png"
                                description="Covers costs related to notifying affected individuals, credit monitoring, and public relations efforts."
                            />
                            <Side
                                heading="Cyber Extortion"
                                content=""
                                image="/assets/img/sideB.png"
                                description="Covers ransom payments and negotiation costs associated with ransomware attacks."
                            />
                            <Side
                                heading="Network Security Liability"
                                content=""
                                image="/assets/img/sideC.png"
                                description="Covers claims related to unauthorized access, data breaches, and malware transmission."
                            />
                             <Side
                                heading="Business Interruption"
                                content=""
                                image="/assets/img/sideC.png"
                                description=" Covers loss of income and operational expenses due to a cyber incident."
                            />
                             <Side
                                heading="Regulatory Compliance"
                                content=""
                                image="/assets/img/sideC.png"
                                description="Covers fines and penalties resulting from non-compliance with data protection regulations."
                            />
                        </Slider>

                    </div>
                    <ServiceBlock
                        title="Advantages of Cyber Insurance"
                        subtitle=""
                        points={["Financial protection against cyber threats:", "Coverage for legal and regulatory costs:", "Support for incident response and recovery:","Enhances business resilience:", "Builds customer trust and confidence:"]}
                        ctaLabel=""
                        ctaLink=""
                        image="/assets/img/do-advantage.png"
                        type="primary"
                        imageLocation="right"
                    />
                </section>
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">Who Needs Cyber Insurance?</h1>
                        <p className="mt-5 content-regular">Any business that relies on digital technology and stores sensitive data should consider Cyber Insurance. This includes:</p>
                        <BSServiceBlock points={["E-commerce Businesses:", "Financial Services:", "Healthcare Providers:", "Retailers:", "Educational Institutions:","Technology Companies:", "Manufacturing Firms:"]} />
                    </div>
                </section>
                <ServiceBlock
                    title="Factors Influencing Cyber Insurance Coverage"
                    subtitle=""
                    points={["Type of data stored:", "Volume of data processed:", "Level of cybersecurity measures in place:", "Regulatory environment:", "Past cyber incident history:"]}
                    ctaLabel=""
                    ctaLink=""
                    image="/assets/img/do-coverage-factor.png"
                    type="primary"
                    imageLocation="right"
                />
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">Why Get Cyber Insurance?</h1>
                        <p className="my-5 content-regular">Businesses face a growing number of cyber threats that can lead to significant financial losses and reputational damage. Cyber Insurance provides a safety net, protecting your business from:</p>
                        <BSServiceBlock points={["Data breaches and identity theft:","Ransomware attacks and cyber extortion:", "Business interruption and operational downtime:", "Legal and regulatory penalties:", "Damage to reputation and customer trust:"]} />
                    </div>
                </section>
                <ServiceBlock
                    title="Who Can Be Affected by Cyber Incidents?"
                    subtitle=""
                    points={["Customers and clients:", "Business partners and suppliers:", "Employees:",  "Regulatory authorities:", "Shareholders:", "Third-party vendors:" ]}
                    ctaLabel=""
                    ctaLink=""
                    image="/assets/img/litigation.png"
                    type="primary"
                    imageLocation="right"
                />
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">Coverages & Exclusions in Cyber Insurance</h1>
                        <section className="w3-section my-5">
                            <div className="d-flex flex-row">
                                <div onClick={() => setCoverage(true)} className={`mr-4 pointer ${isCoverage ? 'active-w3' : ''}`}>
                                    <p>Coverages</p>
                                </div>
                                <div onClick={() => setCoverage(false)} className={`pointer ${!isCoverage ? 'active-w3' : ''}`}>
                                    <p>Exclusions</p>
                                </div>
                            </div>
                        </section>
                        <div>
                            {isCoverage && (
                                <p>Cyber Insurance provides comprehensive protection against various cyber risks. Key coverages include:</p>
                            )}
                            {!isCoverage && (
                                <p>Certain situations are excluded from Cyber Insurance coverage, including:</p>
                            )}

                        </div>
                        {isCoverage && (
                            <div className="row">
                                {coverages.map((c, i) => (
                                    <div className="col-12 col-lg-6">
                                        <h5 className="title-primary-color my-3">{`${i + 1}. ${c.title}`}</h5>
                                        <p>{`${c.content}`}</p>
                                    </div>
                                ))}
                            </div>
                        )}
                        {!isCoverage && (
                            <div className="row">
                                {exclusion.map((c, i) => (
                                    <div className="col-12 col-lg-6">
                                        <h5 className="title-primary-color my-3">{`${i + 1}. ${c.title}`}</h5>
                                        <p>{`${c.content}`}</p>
                                    </div>
                                ))}
                            </div>
                        )}

                    </div>
                </section>
                <ClaimsExample 
                    title="Cyber Insurance Claims Examples"
                    subtitle="Case of Ransomware Attack Leading to Business Interruption"
                    incident="A medium-sized e-commerce business experienced a ransomware attack, leading to system downtime and loss of sales"
                    resolution="The Cyber Insurance policy covered the ransom payment, costs of forensic investigation, legal fees, and the loss of income during the downtime, ensuring the business could recover swiftly and minimize financial loss."
                    image="/assets/img/claims-example.png"
                />
               {/*  <section className="my-4">
                    <p className={`service-title title-primary-color text-center`}>{'Frequently Asked Questions'}</p>
                    <div className=" p-5">
                    <div id="accordion" className="px-lg-5 px-lg-80 p-5">
                        {faq.GeneralLiability.map(f => (<Accordian faq={f} />))}
                    </div>
                </div>
                </section> */}
                <ServiceBlock
                    title="Why Choose Buckss for Cyber Insurance? "
                    subtitle=""
                    points={
                        [
                            "Expertise:Over 50 years of combined experience in the insurance industry.",
                            "Tailored Solutions: Customized Cyber Insurance plans to suit your business needs.",
                            "Dedicated Support: Comprehensive support throughout the claims process.",
                            "Strong Partnerships:  Collaborations with leading insurance providers to offer the best coverage options.",
                        ]
                    }
                    ctaLabel=""
                    ctaLink=""
                    image="/assets/img/insurance-agent-vector.png"
                    type="primary"
                    imageLocation="right"
                />
                <div className="mt-4">
                        <InsuranceServices />
                    </div>
               <div className="mt-4">
               <ProtectBusiness />
               </div>
            </div>
            <Footer />
        </>
    )
}

export default CyberInsurance