import { useState } from "react"
import Footer from "../components/Footer"
import Header from "../components/Header"
import InnerHero from "../components/InnerHero"
import Experience from "../components/Experience"
import Side from "../components/Side"
import ServiceBlock from "../components/ServiceBlock"
import BSServiceBlock from "../components/BSServiceBlock"
import InsuranceServices from "../components/InsuranceServices"
import ProtectBusiness from "../components/ProtectBusiness"
import ClaimsExample from "../components/ClaimsExample"
import faq from "../data/faq"
import Accordian from "../components/Accordian"
import Slider from "react-slick"
import settings from "../lib/SliderSettings"

const GroupTravelInsurance = () => {
    const [activeW, setActiveW] = useState('what')
    const [isCoverage, setCoverage] = useState(true)

    const coverages = [
        {
            title: "Medical Expenses",
            content: "Covers costs of medical treatment due to illness or injury during the trip."
        },
        {
            title: "Trip Cancellation/Interruption",
            content: "Reimburses non-refundable expenses for trip cancellations or interruptions"
        },
        {
            title: "Lost Baggage",
            content: "Compensation for lost or damaged baggage and personal belongings."
        },
        {
            title: "Emergency Evacuation",
            content: "Covers costs for emergency medical evacuation and repatriation."
        },
        {
            title: "Accidental Death and Dismemberment",
            content: " Lump sum payment in case of accidental death or severe injury."
        },
        {
            title: "Travel Delay",
            content: "Compensation for additional expenses incurred due to travel delays."
        },
        {
            title: "24/7 Assistance",
            content: "Access to round-the-clock support for medical, legal, and travel-related emergencies."
        }
    ]
    const exclusion = [
        {
            title: "Pre-Existing Medical Conditions",
            content: "Costs related to pre-existing conditions not disclosed at the time of policy issuance."
        },
        {
            title: "War and Terrorism",
            content: " Injuries or damages resulting from war, terrorism, or related activities."
        },
        {
            title: "Substance Abuse",
            content: "Incidents arising from drug or alcohol abuse."
        },
        {
            title: "Participation in Hazardous Activities",
            content: " Injuries sustained during hazardous activities like extreme sports."
        },
        {
            title: "Non-Medical Evacuation",
            content: "Costs for non-medical evacuation not covered."
        },
        {
            title: "Traveling Against Medical Advice",
            content: " Traveling against the advice of a physician."
        }
    ]
    return (
        <>
            <Header />
            <div className="">
                <InnerHero
                    heading="Group Travel Insurance "
                    title="Ensure the safety and well-being of your employees during business trips with Buckss Group Travel Insurance. Comprehensive coverage for a worry-free travel experience."
                    image="/assets/img/gtt-hero.png"
                />
                <Experience />
                <section className="w3-section my-5 px-lg-5 px-lg-80">
                    <div className="d-flex flex-row align-items-center justify-content-between px-4">
                        <div onClick={() => setActiveW('what')} className={`pointer ${activeW === 'what' ? 'active-w3' : ''}`}>
                            <p>What</p>
                        </div>
                        <div onClick={() => setActiveW('who')} className={`pointer ${activeW === 'who' ? 'active-w3' : ''}`}>
                            <p>Who</p>
                        </div>
                        <div onClick={() => setActiveW('why')} className={`pointer ${activeW === 'why' ? 'active-w3' : ''}`}>
                            <p>Why</p>
                        </div>
                        <div onClick={() => setActiveW('coverage')} className={`pointer ${activeW === 'coverage' ? 'active-w3' : ''}`}>
                            <p>Coverages & Exclusions</p>
                        </div>
                    </div>
                </section>
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">What Is Group Travel Insurance?</h1>
                        <p className="mt-5 content-regular">Group Travel Insurance provides comprehensive coverage for a group of employees traveling together or individually on business trips. This policy covers medical emergencies, trip cancellations, lost baggage, and other travel-related risks, ensuring employees are protected and supported throughout their journey.</p>
                    </div>
                </section>
                <section className="coverage-section">
                    <div className="px-lg-5 px-lg-80">
                        <h1 className="title-primary-color mb-4">Key Features of Group Travel Insurance</h1>
                        <Slider {...settings}>
                            
                                
                                    <Side
                                        heading="Side A"
                                        content="Medical Coverage"
                                        image="/assets/img/sideA.png"
                                        description="Covers medical expenses incurred due to illness or injury during the trip."
                                    />
                                
                                
                                    <Side
                                        heading="Side B"
                                        content="Trip Cancellation/Interruption"
                                        image="/assets/img/sideB.png"
                                        description="Reimburses costs for trip cancellations or interruptions due to unforeseen events."
                                    />
                                
                                    <Side
                                        heading="Side C"
                                        content="Lost Baggage and Personal Belongings"
                                        image="/assets/img/sideC.png"
                                        description="Compensation for lost or damaged baggage and personal belongings."
                                    />
                                    <Side
                                        heading="Side D"
                                        content="Emergency Evacuation"
                                        image="/assets/img/sideC.png"
                                        description="Covers costs for emergency evacuation and repatriation."
                                    />
                                    <Side
                                        heading="Side E"
                                        content="24/7 Assistance"
                                        image="/assets/img/sideC.png"
                                        description="Round-the-clock support for travel-related emergencies."
                                    />
                                
                            
                    
                </Slider>

            </div>
            
        </section >
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">Who Needs Group Travel Insurance?</h1>
                        <p className="mt-5 content-regular">Group Travel Insurance is essential for any organization that sends employees on business trips. This insurance is particularly beneficial for: Large corporations with frequent business travel, Small and medium-sized enterprises (SMEs), Startups with employees traveling for business development, Non-profit organizations and educational institutions, Any employer looking to ensure the safety and well-being of their traveling workforce</p>
                    </div>
                </section>
                <ServiceBlock
                    title="Factors Influencing Group Travel Insurance Coverage"
                    subtitle=""
                    points={["Frequency and duration of trips:","Destination risk factors:","Number of employees traveling:","Nature of the business activities:","Budget and financial considerations:"]}
                    ctaLabel=""
                    ctaLink=""
                    image="/assets/img/do-coverage-factor.png"
                    type="primary"
                    imageLocation="right"
                />
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">Why Get Group Travel Insurance?</h1>
                        <p className="my-5 content-regular">Group Travel Insurance provides essential protection for employees during business trips. The key reasons to opt for this coverage include</p>
                        <BSServiceBlock points={["Financial Protection: Covers unexpected costs arising from medical emergencies, trip cancellations, and lost baggage.","Peace of Mind: Employees can travel with confidence, knowing they are protected.", "Employee Welfare: Demonstrates the employer's commitment to the safety and well-being of their employees.", "Increased Productivity: Employees can focus on their business objectives without worrying about travel risks.","Global Assistance: Access to 24/7 support and assistance worldwide."]} />
                    </div>
                </section>
                <ServiceBlock
                    title="Who Benefits from Group Travel Insurance?"
                    subtitle=""
                    points={["Employees traveling for business:", "Employers ensuring employee safety:", "HR professionals managing travel arrangements:","Business owners focused on employee welfare:"]}
                    ctaLabel=""
                    ctaLink=""
                    image="/assets/img/do-coverage-factor.png"
                    type="primary"
                    imageLocation="right"
                />
                <ServiceBlock
                    title="Benefits for Employers"
                    subtitle="Employers who offer Group Travel Insurance can enjoy several advantages:"
                    points={["Employee Safety: Ensures the safety and well-being of employees during business trips.","Cost Savings: Reduces financial risks associated with travel-related emergencies.","Enhanced Employee Loyalty: Demonstrates care for employees, boosting loyalty and morale.","Simplified Policy Management: One policy covers all traveling employees, simplifying administration.","Competitive Edge: Offering comprehensive travel benefits enhances the company's reputation and attractiveness to potential hires."]}
                    ctaLabel=""
                    ctaLink=""
                    image="/assets/img/do-coverage-factor.png"
                    type="light"
                    imageLocation="left"
                />
                <ServiceBlock
                    title="Benefits for Employees"
                    subtitle="Employees covered under a Group Travel Insurance plan enjoy numerous benefits:"
                    points={["Medical Coverage: Financial protection for medical emergencies during travel.","Trip Cancellation Coverage: Reimbursement for trip cancellations or interruptions due to unforeseen events.","Lost Baggage Compensation: Compensation for lost or damaged baggage and personal belongings.","Emergency Assistance: Access to 24/7 support and assistance for travel-related emergencies.","Peace of Mind: Assurance of protection and support during business trips."]}
                    ctaLabel=""
                    ctaLink=""
                    image="/assets/img/do-coverage-factor.png"
                    type="primary"
                    imageLocation="right"
                />
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">Coverages & Exclusions in D&O Insurance*</h1>
                        <section className="w3-section my-5">
                            <div className="d-flex flex-row">
                                <div onClick={() => setCoverage(true)} className={`mr-4 pointer ${isCoverage ? 'active-w3' : ''}`}>
                                    <p>Coverages</p>
                                </div>
                                <div onClick={() => setCoverage(false)} className={`pointer ${!isCoverage ? 'active-w3' : ''}`}>
                                    <p>Exclusions</p>
                                </div>
                            </div>
                        </section>
                        <div>
                            {isCoverage && (
                                <p>Group Travel Insurance provides extensive coverage to protect employees during business trips. Key coverages include:</p>
                            )}
                            {!isCoverage && (
                                <p>While Group Travel Insurance offers comprehensive coverage, some exclusions typically include:</p>
                            )}

                        </div>
                        {isCoverage && (
                            <div className="row">
                                {coverages.map((c, i) => (
                                    <div className="col-12 col-lg-6">
                                        <h5 className="title-primary-color my-3">{`${i + 1}. ${c.title}`}</h5>
                                        <p>{`${c.content}`}</p>
                                    </div>
                                ))}
                            </div>
                        )}
                        {!isCoverage && (
                            <div className="row">
                                {exclusion.map((c, i) => (
                                    <div className="col-12 col-lg-6">
                                        <h5 className="title-primary-color my-3">{`${i + 1}. ${c.title}`}</h5>
                                        <p>{`${c.content}`}</p>
                                    </div>
                                ))}
                            </div>
                        )}

                    </div>
                </section>
                <ClaimsExample
                    title="Group Travel Insurance Claims Examples"
                    subtitle="Medical Emergency Abroad"
                    incident="An employee fell ill during a business trip abroad and required hospitalization."
                    resolution="The Group Travel Insurance policy covered the medical expenses, including hospitalization, medication, and follow-up treatments, ensuring the employee received the necessary care without financial burden."
                    image="/assets/img/claims-example.png"
                />
                {/* <section className="my-4">
                    <p className={`service-title title-primary-color text-center`}>{'Frequently Asked Questions'}</p>
                    <div className=" p-5">
                        <div id="accordion" className="px-lg-5 px-lg-80 p-5">
                            {faq.do.map(f => (<Accordian faq={f} />))}
                        </div>
                    </div>
                </section> */}
                <ServiceBlock
                    title="Why Choose Buckss for D&O Insurance?"
                    subtitle=""
                    points={
                        [
                            "Expertise:Leveraging over 50 years of combined experience in the insurance industry.",
                            "Tailored Solutions: Customized Group Travel Insurance plans to suit your business needs.",
                            "Dedicated Support:Comprehensive support throughout the claims process.",
                            "Top Partnerships:Strong relationships with leading insurance providers for optimal coverage.",
                        ]
                    }
                    ctaLabel=""
                    ctaLink=""
                    image="/assets/img/insurance-agent-vector.png"
                    type="primary"
                    imageLocation="right"
                />
                <div className="mt-4">
                    <InsuranceServices />
                </div>
                <div className="mt-4">
                    <ProtectBusiness />
                </div>
            </div >
    <Footer />
        </>
    )
}

export default GroupTravelInsurance