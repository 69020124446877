import { useState } from "react"
import Footer from "../components/Footer"
import Header from "../components/Header"
import InnerHero from "../components/InnerHero"
import Experience from "../components/Experience"
import Side from "../components/Side"
import ServiceBlock from "../components/ServiceBlock"
import BSServiceBlock from "../components/BSServiceBlock"
import InsuranceServices from "../components/InsuranceServices"
import ProtectBusiness from "../components/ProtectBusiness"
import ClaimsExample from "../components/ClaimsExample"
import faq from "../data/faq"
import Accordian from "../components/Accordian"
import Slider from "react-slick"
import settings from "../lib/SliderSettings"

const OfficePackageInsurance = () => {
    const [activeW, setActiveW] = useState('what')
    const [isCoverage, setCoverage] = useState(true)
    const coverages = [
        {
            title: "Property Damage:",
            content: "Covers the cost of repairing or replacing office property damaged by fire, flood, storms, or other covered events"
        },
        {
            title: "Business Interruption:",
            content: "Provides compensation for loss of income due to the temporary closure of the office following a covered event."
        },
        {
            title: "Burglary and Theft:",
            content: "Covers losses due to burglary or theft of office contents."
        },
        {
            title: "Electronic Equipment",
            content: "Protection for electronic devices such as computers, servers, and other office equipment."
        },
        {
            title: "Liability Coverage",
            content: "Covers legal liabilities arising from third-party bodily injury or property damage within the office premises"
        },
        {
            title: "Employee Personal Accident",
            content: "Compensation for personal accidents or injuries to employees within the office."
        },
        {
            title: "Money Insurance",
            content: "Covers loss of money due to theft or burglary from cash registers, safes, or during transit."
        },
        {
            title: "Glass Breakage",
            content: "Covers costs associated with the accidental breakage of glass within the office premises."
        },
        {
            title: "Fidelity Guarantee",
            content: "Protects against financial loss due to dishonesty or fraud by employees."
        }
    ]
    const exclusion = [
        {
            title: "Wear and Tear",
            content: "Covers the cost of bail and bonds if directors or officers face criminal charges."
        },
        {
            title: "War and Terrorism",
            content: "Damages resulting from war, terrorism, or related events."
        },
        {
            title: "Illegal Activities",
            content: "Losses or damages resulting from illegal activities conducted within the office."
        },
        {
            title: "Nuclear Risks",
            content: "Damages due to nuclear reactions, radiation, or related events."
        },
        {
            title: "Deliberate Acts",
            content: "Losses arising from deliberate or intentional acts by the insured or employees."
        },
        {
            title: "Pre-existing Damage",
            content: "Damages or losses occurring before the policy inception."
        },
        {
            title: "Consequential Losses",
            content: "Indirect losses not explicitly covered by the policy"
        },
        {
            title: "Unoccupied Property",
            content: "Damages occurring while the office premises are unoccupied for an extended period."
        }
    ]
    return (
        <>
            <Header />
            <div className="">
                <InnerHero
                    heading="Office Package Policy"
                    title="Comprehensive protection for your office and its contents. Ensure business continuity with tailored coverage for your workplace."
                    image="/assets/img/package-insurance-hero.png"
                />
                <Experience />
                <section className="w3-section my-5 px-lg-5 px-lg-80">
                    <div className="d-flex flex-row align-items-center justify-content-between px-4">
                        <div onClick={() => setActiveW('what')} className={`pointer ${activeW === 'what' ? 'active-w3' : ''}`}>
                            <p>What</p>
                        </div>
                        <div onClick={() => setActiveW('who')} className={`pointer ${activeW === 'who' ? 'active-w3' : ''}`}>
                            <p>Who</p>
                        </div>
                        <div onClick={() => setActiveW('why')} className={`pointer ${activeW === 'why' ? 'active-w3' : ''}`}>
                            <p>Why</p>
                        </div>
                        <div onClick={() => setActiveW('coverage')} className={`pointer ${activeW === 'coverage' ? 'active-w3' : ''}`}>
                            <p>Coverages & Exclusions</p>
                        </div>
                    </div>
                </section>
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">What Is Office Package Policy?  </h1>
                        <p className="mt-5 content-regular">The Office Package Policy is designed to provide extensive coverage for office premises and its contents against a wide range of risks. This policy protects businesses from potential financial losses due to damages to the office building, furniture, equipment, and other valuable assets. It also includes coverage for liabilities arising from accidents or incidents that occur within the office premises.</p>
                    </div>
                </section>
                <section className="coverage-section">
                    <div className="px-lg-5 px-lg-80">
                        <h1 className="title-primary-color mb-4">Key Features of Office Package Policy</h1>
                       <Slider {...settings}>
                            <Side
                                heading="Property Damage"
                                content="Property Damage"
                                image="/assets/img/sideA.png"
                                description="Covers loss or damage to the office building and contents due to fire, theft, natural disasters, and other risks."
                            />
                            <Side
                                heading="Side B"
                                content="Business Interruption"
                                image="/assets/img/sideB.png"
                                description="Compensation for loss of income due to temporary closure of the office following a covered event."
                            />
                            <Side
                                heading="Side C"
                                content="Liability Coverage"
                                image="/assets/img/sideC.png"
                                description="Protects against third-party legal liabilities for bodily injury or property damage occurring within the office premises."
                            />
                            <Side
                                heading="Side D"
                                content="Employee Benefits"
                                image="/assets/img/sideC.png"
                                description="Includes coverage for personal accidents or injuries to employees within the office premises."
                            />
                            <Side
                                heading="Side E"
                                content="Electronic Equipment Cover"
                                image="/assets/img/sideC.png"
                                description=" Protection for electronic devices such as computers, servers, and other office equipment."
                            />
                            <Side
                                heading="Side E"
                                content="Burglary and Theft Cover"
                                image="/assets/img/sideC.png"
                                description="Compensation for losses due to burglary or theft of office assets."
                            />
                        </Slider>

                    </div>
                    <ServiceBlock
                        title="Advantages of D&O Liability Insurance"
                        subtitle="A narrative about how Buckss was founded, the inspiration behind the company, and its journey so far."
                        points={["Shields personal assets of directors & officers:", "Crucial for both large and small enterprises:", "Enhances the ability to attract and retain top executive talent:"]}
                        ctaLabel=""
                        ctaLink=""
                        image="/assets/img/package-advantage.png"
                        type="primary"
                        imageLocation="right"
                    />
                </section>
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">Who Needs Office Package Policy?</h1>
                        <p className="mt-5 content-regular">The Office Package Policy is essential for businesses of all sizes and sectors that operate from an office premises. This policy is particularly beneficial for: Corporate offices, IT and software companies, Consulting firms, Professional service providers (lawyers, accountants, etc.), Small and medium-sized enterprises (SMEs), Non-profit organizations                        </p>
                    </div>
                </section>
                <ServiceBlock
                    title="Factors Influencing Office Package Policy Coverage"
                    subtitle=""
                    points={["Office location and size", "Type of business and nature of operations", "Value of office contents and equipment", "Number of employees and visitors", "Historical claims and risk exposure"]}
                    ctaLabel=""
                    ctaLink=""
                    image="/assets/img/package-factor.png"
                    type="primary"
                    imageLocation="right"
                />
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">Why Get Office Package Policy?</h1>
                        <p className="my-5 content-regular">An Office Package Policy provides comprehensive protection for office premises and its contents. Key reasons to opt for this coverage include:</p>
                        <BSServiceBlock points={["Financial Protection:Covers repair and replacement costs, reducing financial strain.", "Business Continuity:Ensures minimal disruption to operations after a covered event.", "Legal Compliance:Meets regulatory requirements for workplace safety and liability.", "Employee Safety:Protects employees from accidents and injuries within the office.", "Asset Protection:Safeguards valuable business assets from unexpected losses."]} />
                    </div>
                </section>
                <ServiceBlock
                    title="Common Risks Covered by Office Package Policy"
                    subtitle=""
                    points={["Fire and explosions:","Theft and burglary:", "Natural disasters (floods, storms, earthquakes):", "Accidental damage:","Third-party liabilities:"," Employee injuries:"]}
                    ctaLabel=""
                    ctaLink=""
                    image="/assets/img/litigation.png"
                    type="primary"
                    imageLocation="right"
                />
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">Coverages & Exclusions in D&O Insurance*</h1>
                        <section className="w3-section my-5">
                            <div className="d-flex flex-row">
                                <div onClick={() => setCoverage(true)} className={`mr-4 pointer ${isCoverage ? 'active-w3' : ''}`}>
                                    <p>Coverages</p>
                                </div>
                                <div onClick={() => setCoverage(false)} className={`pointer ${!isCoverage ? 'active-w3' : ''}`}>
                                    <p>Exclusions</p>
                                </div>
                            </div>
                        </section>
                        <div>
                            {isCoverage && (
                                <p>The Office Package Policy provides extensive coverage to protect office premises and contents. Key coverages include:</p>
                            )}
                            {!isCoverage && (
                                <p>While the Office Package Policy offers comprehensive coverage, some exclusions typically include:</p>
                            )}

                        </div>
                        {isCoverage && (
                            <div className="row">
                                {coverages.map((c, i) => (
                                    <div className="col-12 col-lg-6">
                                        <h5 className="title-primary-color my-3">{`${i + 1}. ${c.title}`}</h5>
                                        <p>{`${c.content}`}</p>
                                    </div>
                                ))}
                            </div>
                        )}
                        {!isCoverage && (
                            <div className="row">
                                {exclusion.map((c, i) => (
                                    <div className="col-12 col-lg-6">
                                        <h5 className="title-primary-color my-3">{`${i + 1}. ${c.title}`}</h5>
                                        <p>{`${c.content}`}</p>
                                    </div>
                                ))}
                            </div>
                        )}

                    </div>
                </section>
                <ClaimsExample 
                    title="Office Package Policy Claims Examples"
                    subtitle="Fire Damage to Office Building"
                    incident="A fire broke out in the office premises, causing significant damage to the building and office contents."
                    resolution="The Office Package Policy covered the repair and replacement costs for the damaged property, ensuring the business could resume operations quickly without financial strain."
                    image="/assets/img/claims-example.png"
                />
                {/* <section className="my-4">
                    <p className={`service-title title-primary-color text-center`}>{'Frequently Asked Questions'}</p>
                    <div className=" p-5">
                    <div id="accordion" className="px-lg-5 px-lg-80 p-5">
                        {faq.officePackage.map(f => (<Accordian faq={f} />))}
                    </div>
                </div>
                </section> */}
                <ServiceBlock
                    title="Why Choose Buckss for D&O Insurance?"
                    subtitle=""
                    points={
                        [
                            "Expertise:Leveraging over 50 years of combined experience in the insurance industry.",
                            "Tailored Solutions:Customized Office Package Policy plans to suit your business needs.",
                            "Dedicated Support:Comprehensive support throughout the claims process.",
                            "Top Partnerships:Strong relationships with leading insurance providers for optimal coverage.",
                        ]
                    }
                    ctaLabel=""
                    ctaLink=""
                    image="/assets/img/insurance-agent-vector.png"
                    type="light"
                    imageLocation="left"
                />
                <div className="mt-4">
                        <InsuranceServices />
                    </div>
               <div className="mt-4">
               <ProtectBusiness />
               </div>
            </div>
            <Footer />
        </>
    )
}

export default OfficePackageInsurance