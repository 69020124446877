const Side = (props) => {
    return (
        <div className="d-flex flex-row col-12">
            <div className="side">

                <div className="d-flex flex-row align-items-center mb-4">
                    <img src={props.image} alt="sideA" className="sideImg mr-2" />
                    <div className="d-flex flex-column">
                        <h4 className="title-primary-color">{props.content}</h4>
                        <span className="light-color">{}</span>
                    </div>
                </div>
                <div>
                    <p>{props.description}</p>
                </div>
            </div>
        </div>
    )
}

export default Side