import { useState } from "react"
import Footer from "../components/Footer"
import Header from "../components/Header"
import InnerHero from "../components/InnerHero"
import Experience from "../components/Experience"
import Side from "../components/Side"
import ServiceBlock from "../components/ServiceBlock"
import { converge, pathOr } from "ramda"
import BSServiceBlock from "../components/BSServiceBlock"
import InsuranceServices from "../components/InsuranceServices"
import ProtectBusiness from "../components/ProtectBusiness"
import ClaimsExample from "../components/ClaimsExample"
import faq from "../data/faq"
import Accordian from "../components/Accordian"
import Slider from "react-slick"
import settings from "../lib/SliderSettings"

const ErectionInsurance = () => {
    const [activeW, setActiveW] = useState('what')
    const [isCoverage, setCoverage] = useState(true)
    const coverages = [
        {
            title: "Material Damage",
            content: " Covers physical loss or damage to the erection works, materials, and equipment."
        },
        {
            title: "Third-Party Liability",
            content: "Protects against legal liabilities for bodily injury or property damage to third parties due to erection activities."
        },
        {
            title: "Contract Works",
            content: " Covers the value of ongoing erection works until project completion."
        },
        {
            title: "Temporary Works",
            content: "ncludes coverage for temporary structures such as scaffolding and formwork."
        },
        {
            title: "Professional Fees",
            content: "Covers the fees of architects, engineers, and consultants involved in the project."
        },
        {
            title: "Debris Removal",
            content: "Covers the cost of removing debris following an insured event."
        },
        {
            title: "Additional Costs",
            content: "Covers losses from accepting counterfeit money or money orders in good faith."
        },
        {
            title: "Extended Maintenance Period",
            content: "Provides coverage for defects discovered during the maintenance period specified in the contract."
        },
        
        
    ]
    const exclusion = [
        {
            title: "Wear and Tear",
            content: "Damages due to regular wear and tear or gradual deterioration."
        },
        {
            title: "Defective Design",
            content: "Losses arising from faulty design, workmanship, or materials."
        },
        {
            title: "Pre-existing Conditions",
            content: "Losses or damages that existed before the policy inception."
        },
        {
            title: "War and Terrorism",
            content: "Damages resulting from war, invasion, or acts of terrorism."
        },
        {
            title: "Nuclear Risks",
            content: "Losses due to nuclear reactions or radiation"
        },
        {
            title: "Contractual Penalties",
            content: "Penalties imposed due to contractual breaches or delays."
        },
        {
            title: "Employee Dishonesty",
            content: "Losses caused by fraudulent acts of employees."
        },
        {
            title: "Unexplained Losses",
            content: "Losses for which the cause cannot be determined."
        },
        {
            title: "Government Action",
            content: "Losses due to actions or orders from the government."
        },
       
    ]
    return (
        <>
            <Header />
            <div className="">
                <InnerHero
                    heading="Erection All Risk Insurance"
                    title="Comprehensive coverage for your industrial and erection projects against unforeseen risks. Ensure the safety of your investments with our tailored Erection All Risk Insurance.   
"
                    image="/assets/img/erection-hero.png"
                />
                <Experience />
                <section className="w3-section my-5 px-lg-5 px-lg-80">
                    <div className="d-flex flex-row align-items-center justify-content-between px-4">
                        <div onClick={() => setActiveW('what')} className={`pointer ${activeW === 'what' ? 'active-w3' : ''}`}>
                            <p>What</p>
                        </div>
                        <div onClick={() => setActiveW('who')} className={`pointer ${activeW === 'who' ? 'active-w3' : ''}`}>
                            <p>Who</p>
                        </div>
                        <div onClick={() => setActiveW('why')} className={`pointer ${activeW === 'why' ? 'active-w3' : ''}`}>
                            <p>Why</p>
                        </div>
                        <div onClick={() => setActiveW('coverage')} className={`pointer ${activeW === 'coverage' ? 'active-w3' : ''}`}>
                            <p>Coverages & Exclusions</p>
                        </div>
                    </div>
                </section>
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">What Is Erection All Risk Insurance?</h1>
                        <p className="mt-5 content-regular">Erection All Risk (EAR) Insurance is a specialized insurance policy designed to cover the risks associated with the installation and erection of industrial plants, machinery, and equipment. This comprehensive policy protects against physical loss or damage to the projects, as well as third-party liabilities arising from these activities.


                        </p>
                    </div>
                </section>
                <section className="coverage-section">
                    <div className="px-lg-5 px-lg-80">
                        <h1 className="title-primary-color mb-4">Key Benefits of Erection All Risk Insurance</h1>
                        <Slider {...settings}>
                            <Side
                                heading="Comprehensive Coverage"
                                content="Comprehensive Coverage"
                                image="/assets/img/sideA.png"
                                description="Protects against a wide range of risks, including accidental damage, theft, and natural disasters."
                            />
                            <Side
                                heading="Third-Party Liability"
                                content="Third-Party Liability"
                                image="/assets/img/sideB.png"
                                description="Covers legal liabilities arising from third-party bodily injury or property damage due to erection activities."
                            />
                            <Side
                                heading="Material and Equipment Coverage"
                                content="Material and Equipment Coverage"
                                image="/assets/img/sideC.png"
                                description="Includes protection for materials, tools, and equipment used during erection."
                            />
                             <Side
                                heading="Contract Works Coverage"
                                content="Contract Works Coverage"
                                image="/assets/img/sideC.png"
                                description="Covers the value of ongoing erection works until completion."
                            />
                             <Side
                                heading="Flexible Policy Terms"
                                content="Flexible Policy Terms"
                                image="/assets/img/sideC.png"
                                description="Tailored coverage options to meet the specific needs of different projects."
                            />
                          
                        </Slider>

                    </div>
                    <ServiceBlock
                        title="Advantages of Erection All Risk Insurance:"
                        subtitle=""
                        points={["Ensures financial stability during erection projects:", "Covers both direct and indirect financial losses:", "Reduces the financial impact of project delays and disruptions:","Enhances project resilience and recovery capabilities:","Provides peace of mind during unforeseen events:"]}
                        ctaLabel=""
                        ctaLink=""
                        image="/assets/img/erection-advantage.png"
                        type="primary"
                        imageLocation="right"
                    />
                </section>
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">Who Needs Erection All Risk Insurance? </h1>
                        <p className="mt-5 content-regular">Erection All Risk Insurance is essential for any entity involved in the erection and installation of industrial plants and machinery. It is particularly crucial for:
                        </p>
                        <BSServiceBlock points={["Industrial companies:", "Contractors:", "Subcontractors:", "Project owners:", "Engineering firms:","Equipment suppliers:", "Infrastructure developers:"]} />
                    </div>
                </section>
                <ServiceBlock
                    title="Factors Influencing Erection All Risk Insurance Coverage"
                    subtitle=""
                    points={["Project type and size:", "Erection methods and materials used:", "Project location and environmental conditions:", "Contractor's experience and track record:", "Risk management practices and safety measures:"]}
                    ctaLabel=""
                    ctaLink=""
                    image="/assets/img/erection-factor.png"
                    type="primary"
                    imageLocation="right"
                />
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">Why Get Erection All Risk Insurance?</h1>
                        <p className="my-5 content-regular">Erection All Risk Insurance is vital for protecting projects from the financial consequences of various risks. The policy provides:
                        </p>
                        <BSServiceBlock points={["Coverage for physical loss or damage to erection works:","Protection for materials and equipment:", "Compensation for third-party liability claims:", "Financial support during erection delays and disruptions:", "Assurance of project continuity and operational stability:"]} />
                    </div>
                </section>
                <ServiceBlock
                    title="Who Can Benefit from Erection All Risk Insurance?"
                    subtitle=""
                    points={["Project owners seeking to protect their investment:", "Contractors aiming to minimize financial loss from project risks:", "Companies looking to ensure quick recovery and continuity post-incident:","Subcontractors needing to comply with contractual insurance requirements" ]}
                    ctaLabel=""
                    ctaLink=""
                    image="/assets/img/erection-who-can.png"
                    type="primary"
                    imageLocation="right"
                />
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">Coverages & Exclusions in Erection All Risk Insurance</h1>
                        <section className="w3-section my-5">
                            <div className="d-flex flex-row">
                                <div onClick={() => setCoverage(true)} className={`mr-4 pointer ${isCoverage ? 'active-w3' : ''}`}>
                                    <p>Coverages</p>
                                </div>
                                <div onClick={() => setCoverage(false)} className={`pointer ${!isCoverage ? 'active-w3' : ''}`}>
                                    <p>Exclusions</p>
                                </div>
                            </div>
                        </section>
                        <div>
                            {isCoverage && (
                                <p>Erection All Risk Insurance provides extensive coverage to protect projects from various risks. Key coverages include:</p>
                            )}
                            {!isCoverage && (
                                <p>Erection All Risk Insurance typically excludes certain situations, including:</p>
                            )}

                        </div>
                        {isCoverage && (
                            <div className="row">
                                {coverages.map((c, i) => (
                                    <div className="col-12 col-lg-6">
                                        <h5 className="title-primary-color my-3">{`${i + 1}. ${c.title}`}</h5>
                                        <p>{`${c.content}`}</p>
                                    </div>
                                ))}
                            </div>
                        )}
                        {!isCoverage && (
                            <div className="row">
                                {exclusion.map((c, i) => (
                                    <div className="col-12 col-lg-6">
                                        <h5 className="title-primary-color my-3">{`${i + 1}. ${c.title}`}</h5>
                                        <p>{`${c.content}`}</p>
                                    </div>
                                ))}
                            </div>
                        )}

                    </div>
                </section>
                <ClaimsExample 
                    title="Erection All Risk Insurance Claims Examples"
                    subtitle="Case of Equipment Damage During Installation"
                    incident="During the erection of a new industrial plant, a crane accidentally dropped a valuable piece of machinery, causing significant damage and project delay."
                    resolution="The Erection All Risk Insurance policy covered the cost of repairing the damaged machinery and compensated for the additional expenses incurred due to the delay. The business received financial support to resume the project promptly, minimizing the overall impact on the timeline and budget."
                    image="/assets/img/erection-claims-example.png"
                />
                {/* <section className="my-4">
                    <p className={`service-title title-primary-color text-center`}>{'Frequently Asked Questions'}</p>
                    <div className=" p-5">
                    <div id="accordion" className="px-lg-5 px-lg-80 p-5">
                        {faq.Workmen.map(f => (<Accordian faq={f} />))}
                    </div>
                </div>
                </section> */}
                <ServiceBlock
                    title="Why Choose Buckss for rection All Risk Insuranc?"
                    subtitle=""
                    points={
                        [
                            "Expertise:Over 50 years of combined experience in the insurance industry.",
                            "Tailored Solutions:Customized Erection All Risk Insurance plans to suit your project needs.",
                            "Dedicated Support:  Comprehensive support throughout the claims process.",
                            "Strong Partnerships: Collaborations with leading insurance providers to offer the best coverage options.",
                        ]
                    }
                    ctaLabel=""
                    ctaLink=""
                    image="/assets/img/insurance-agent-vector.png"
                    type="primary"
                    imageLocation="right"
                />
                <div className="mt-4">
                        <InsuranceServices />
                    </div>
               <div className="mt-4">
               <ProtectBusiness />
               </div>
            </div>
            <Footer />
        </>
    )
}

export default ErectionInsurance