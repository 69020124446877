const Experience = () => {
    return (
        <section className="experience-band">
            <div className="d-flex flex-row">
                <div className="band-small">Leveraging over </div>
                <div className="band-bold"> 50+ years </div>
                <div className="band-small"> of combined experience in the insurance industry.</div>
            </div>
        </section>
    )
}

export default Experience;
