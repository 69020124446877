import { useState } from "react"
import Footer from "../components/Footer"
import Header from "../components/Header"
import InnerHero from "../components/InnerHero"
import Experience from "../components/Experience"
import Side from "../components/Side"
import ServiceBlock from "../components/ServiceBlock"
import { converge, pathOr } from "ramda"
import BSServiceBlock from "../components/BSServiceBlock"
import InsuranceServices from "../components/InsuranceServices"
import ProtectBusiness from "../components/ProtectBusiness"
import ClaimsExample from "../components/ClaimsExample"
import faq from "../data/faq"
import Accordian from "../components/Accordian"
import Slider from "react-slick"
import settings from "../lib/SliderSettings"

const FireLossInsurance = () => {
    const [activeW, setActiveW] = useState('what')
    const [isCoverage, setCoverage] = useState(true)
    const coverages = [
        {
            title: "Income Loss",
            content: "Compensates for the loss of net profit during the business interruption period."
        },
        {
            title: "Fixed Costs",
            content: " Covers ongoing fixed expenses, such as salaries, rent, and utilities, even if the business operations are halted."
        },
        {
            title: "Extra Expenses",
            content: "Covers additional costs incurred to expedite the recovery process and resume operations."
        },
        {
            title: "Extended Indemnity",
            content: "Provides extended coverage for income loss until the business fully recovers."
        },
        {
            title: "Debris Removal",
            content: "Covers the cost of removing debris following a fire incident."
        },
        {
            title: "Professional Fees",
            content: "Covers the fees of accountants, auditors, and other professionals involved in preparing claims."
        },
        {
            title: "Temporary Relocation Costs",
            content: "Covers the expenses of temporarily relocating the business during repairs."
        },
        {
            title: "Utilities and Services",
            content: "Covers costs related to utilities and services that continue despite business interruption."
        },
        {
            title: "Advertising and Marketing",
            content: "Covers expenses for advertising and marketing efforts to regain customers after resuming operations."
        }
    ]
    const exclusion = [
        {
            title: "Normal Business Risks",
            content: "Losses not related to fire incidents, such as general market downturns."
        },
        {
            title: "Wear and Tear",
            content: "Damages due to regular wear and tear or gradual deterioration."
        },
        {
            title: "Intentional Damage",
            content: "Damages caused by deliberate actions or negligence."
        },
        {
            title: "Nuclear Risks",
            content: "Losses due to nuclear reactions or radiation."
        },
        {
            title: "War and Terrorism",
            content: "Damages resulting from war, invasion, or acts of terrorism."
        },
        {
            title: "Pre-existing Conditions",
            content: "Losses or damages that existed before the policy inception."
        },
        {
            title: "Utility Failure",
            content: "Losses due to utility failure not caused by fire incidents."
        },
        {
            title: "Government Action",
            content: "Losses due to actions or orders from the government."
        }
    ]
    return (
        <>
            <Header />
            <div className="">
                <InnerHero
                    heading="Fire Loss of Profit Insurance"
                    title="Safeguard your business from financial losses due to fire-related interruptions. Our Fire Loss of Profit Insurance ensures you remain financially secure during unexpected downtimes."
                    image="/assets/img/fire-loss-insurance.png"
                />
                <Experience />
                <section className="w3-section my-5 px-lg-5 px-lg-80">
                    <div className="d-flex flex-row align-items-center justify-content-between px-4">
                        <div onClick={() => setActiveW('what')} className={`pointer ${activeW === 'what' ? 'active-w3' : ''}`}>
                            <p>What</p>
                        </div>
                        <div onClick={() => setActiveW('who')} className={`pointer ${activeW === 'who' ? 'active-w3' : ''}`}>
                            <p>Who</p>
                        </div>
                        <div onClick={() => setActiveW('why')} className={`pointer ${activeW === 'why' ? 'active-w3' : ''}`}>
                            <p>Why</p>
                        </div>
                        <div onClick={() => setActiveW('coverage')} className={`pointer ${activeW === 'coverage' ? 'active-w3' : ''}`}>
                            <p>Coverages & Exclusions</p>
                        </div>
                    </div>
                </section>
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">What is Fire Loss of Profit Insurance?</h1>
                        <p className="mt-5 content-regular">Fire Loss of Profit Insurance, also known as Business Interruption Insurance, protects businesses from the financial impact of fire incidents that cause interruptions to operations. This insurance covers the loss of income and additional expenses incurred to maintain operations during the recovery period.</p>
                    </div>
                </section>
                <section className="coverage-section">
                    <div className="px-lg-5 px-lg-80">
                        <h1 className="title-primary-color mb-4">Key Benefits of Fire Loss of Profit Insurance</h1>
                        <Slider {...settings}>
                            <Side
                                heading="Side A"
                                content="Income Loss Coverage"
                                image="/assets/img/sideA.png"
                                description="Compensates for the loss of net profit due to fire-related interruptions."
                            />
                            <Side
                                heading="Side B"
                                content="Fixed Costs Coverage"
                                image="/assets/img/sideB.png"
                                description="Covers ongoing fixed expenses, such as salaries, rent, and utilities."
                            />
                            <Side
                                heading="Side C"
                                content="Extra Expenses Coverage"
                                image="/assets/img/sideC.png"
                                description=" Covers additional costs incurred to expedite recovery and resume operations."
                            />
                            <Side
                                heading="Side D"
                                content="Extended Coverage"
                                image="/assets/img/sideC.png"
                                description="Provides protection for income loss during the rebuilding phase and until the business fully recovers."
                            />
                            <Side
                                heading="Side E"
                                content="Debris Removal Costs"
                                image="/assets/img/sideC.png"
                                description="Covers the cost of removing debris following a fire incident."
                            />
                        </Slider>

                    </div>
                    <ServiceBlock
                        title="Advantages of Fire Loss of Profit Insurance"
                        subtitle="A narrative about how Buckss was founded, the inspiration behind the company, and its journey so far."
                        points={["Ensures financial stability during recovery:", "Covers both direct and indirect financial losses:", "Reduces the financial impact of business interruption:", "Enhances business resilience and recovery capabilities", "Provides peace of mind during unforeseen events"]}
                        ctaLabel=""
                        ctaLink=""
                        image="/assets/img/do-advantage.png"
                        type="primary"
                        imageLocation="right"
                    />
                </section>
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">Who Needs Fire Loss of Profit Insurance?</h1>
                        <p className="mt-5 content-regular">Fire Loss of Profit Insurance is essential for any business that faces a risk of fire-related interruptions. It is particularly crucial for: Manufacturing companies, Retail stores, Hospitality businesses, Warehousing and storage facilities, Restaurants and cafes, Office buildings, Educational institutions</p>
                    </div>
                </section>
                <ServiceBlock
                    title="Factors Influencing Fire Loss of Profit Insurance Coverage"
                    subtitle=""
                    points={["Business type and industry:", "Size and scale of operations", "Historical fire incident data", "Location and fire risk factors", "Business continuity plans and risk management practices"]}
                    ctaLabel=""
                    ctaLink=""
                    image="/assets/img/do-coverage-factor.png"
                    type="primary"
                    imageLocation="right"
                />
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color"> Why Get Fire Loss of Profit Insurance?</h1>
                        <p className="my-5 content-regular">Fire Loss of Profit Insurance is vital for protecting businesses from the financial consequences of fire-related interruptions. The policy provides:</p>
                        <BSServiceBlock points={["Coverage for income loss and fixed expenses:","Compensation for additional expenses incurred to resume operation:", "Financial support during the rebuilding and recovery phase:", "Protection against unforeseen fire risks:", "Assurance of business continuity and operational stability:"]} />
                    </div>
                </section>
                <ServiceBlock
                    title="Who Can Benefit from Fire Loss of Profit Insurance?"
                    subtitle=""
                    points={["Business owners seeking to protect their income and fixed costs:", "Companies aiming to minimize financial loss from fire-related interruptions:", "Enterprises looking to ensure quick recovery and continuity post-fire incident:", "Organizations needing to comply with legal and regulatory requirements:"]}
                    ctaLabel=""
                    ctaLink=""
                    image="/assets/img/litigation.png"
                    type="primary"
                    imageLocation="right"
                />
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">Coverages & Exclusions in Fire Loss of Profit Insurance</h1>
                        <section className="w3-section my-5">
                            <div className="d-flex flex-row">
                                <div onClick={() => setCoverage(true)} className={`mr-4 pointer ${isCoverage ? 'active-w3' : ''}`}>
                                    <p>Coverages</p>
                                </div>
                                <div onClick={() => setCoverage(false)} className={`pointer ${!isCoverage ? 'active-w3' : ''}`}>
                                    <p>Exclusions</p>
                                </div>
                            </div>
                        </section>
                        <div>
                            {isCoverage && (
                                <p>Fire Loss of Profit Insurance provides extensive coverage to protect businesses from the financial impact of fire-related interruptions. Key coverages include:</p>
                            )}
                            {!isCoverage && (
                                <p>Fire Loss of Profit Insurance typically excludes certain situations, including:</p>
                            )}

                        </div>
                        {isCoverage && (
                            <div className="row">
                                {coverages.map((c, i) => (
                                    <div className="col-12 col-lg-6">
                                        <h5 className="title-primary-color my-3">{`${i + 1}. ${c.title}`}</h5>
                                        <p>{`${c.content}`}</p>
                                    </div>
                                ))}
                            </div>
                        )}
                        {!isCoverage && (
                            <div className="row">
                                {exclusion.map((c, i) => (
                                    <div className="col-12 col-lg-6">
                                        <h5 className="title-primary-color my-3">{`${i + 1}. ${c.title}`}</h5>
                                        <p>{`${c.content}`}</p>
                                    </div>
                                ))}
                            </div>
                        )}

                    </div>
                </section>
                <ClaimsExample 
                    title="Fire Loss of Profit Insurance Claims Examples"
                    subtitle="Case of a Retail Store Fire Incident"
                    incident=" A fire broke out in a retail store, causing extensive damage and halting operations for several months."
                    resolution="The Fire Loss of Profit Insurance policy covered the loss of net profit, fixed expenses, and additional costs incurred to expedite repairs. The business received compensation for income loss during the recovery period, enabling them to resume operations and minimize financial losses."
                    image="/assets/img/claims-example.png"
                />
                {/* <section className="my-4">
                    <p className={`service-title title-primary-color text-center`}>{'Frequently Asked Questions'}</p>
                    <div className=" p-5">
                    <div id="accordion" className="px-lg-5 px-lg-80 p-5">
                        {faq.fireLoss.map(f => (<Accordian faq={f} />))}
                    </div>
                </div>
                </section> */}
                <ServiceBlock
                    title="Why Choose Buckss for Fire Loss of Profit Insurance?  "
                    subtitle=""
                    points={
                        [
                            "Expertise:Leveraging over 50 years of combined experience in the insurance industry.",
                            "Tailored Solutions:Customized Fire Loss of Profit Insurance plans to suit your business needs.",
                            "Dedicated Support:Comprehensive support throughout the claims process.",
                            "Top Partnerships:Strong relationships with leading insurance providers for optimal coverage.",
                        ]
                    }
                    ctaLabel=""
                    ctaLink=""
                    image="/assets/img/insurance-agent-vector.png"
                    type="primary"
                    imageLocation="right"
                />
                <div className="mt-4">
                        <InsuranceServices />
                    </div>
               <div className="mt-4">
               <ProtectBusiness />
               </div>
            </div>
            <Footer />
        </>
    )
}

export default FireLossInsurance