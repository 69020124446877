import { useState } from "react"
import Footer from "../components/Footer"
import Header from "../components/Header"
import InnerHero from "../components/InnerHero"
import Experience from "../components/Experience"
import Side from "../components/Side"
import ServiceBlock from "../components/ServiceBlock"
import BSServiceBlock from "../components/BSServiceBlock"
import InsuranceServices from "../components/InsuranceServices"
import ProtectBusiness from "../components/ProtectBusiness"
import ClaimsExample from "../components/ClaimsExample"
import faq from "../data/faq"
import Accordian from "../components/Accordian"
import Slider from "react-slick"
import settings from "../lib/SliderSettings"

const SalesTurnoverInsurance = () => {
    const [activeW, setActiveW] = useState('what')
    const [isCoverage, setCoverage] = useState(true)
    const coverages = [
        {
            title: "Natural Disasters",
            content: "Covers loss of income due to natural disasters such as floods, storms, and earthquakes."
        },
        {
            title: "Fire and Explosions",
            content: "Compensation for revenue losses resulting from fire and explosions."
        },
        {
            title: "Supply Chain Disruptions",
            content: "Protection against revenue loss due to interruptions in the supply chain."
        },
        {
            title: "Substance-related Claims",
            content: "Provides for costs related to extradition of directors or officers detained abroad."
        },
        {
            title: "Equipment Breakdown",
            content: "Covers income loss due to breakdown of critical equipment affecting production or sales."
        },
        {
            title: "Political and Civil Unrest",
            content: "Compensation for revenue losses due to political events or civil disturbances."
        },
        {
            title: "Market Fluctuations",
            content: "Coverage for revenue loss due to market changes caused by unexpected events."
        },
        {
            title: "Pandemic and Epidemic Impact",
            content: "Compensation for loss of income due to pandemics or epidemics affecting business operations."
        }
    ]
    const exclusion = [
        {
            title: "Intentional Acts",
            content: "Revenue losses resulting from deliberate or intentional acts by the insured."
        },
        {
            title: "Normal Business Risks",
            content: "Losses due to normal business risks such as poor management or business decisions."
        },
        {
            title: "War and Terrorism",
            content: "Revenue losses resulting from war, terrorism, or related events."
        },
        {
            title: "Nuclear Risks",
            content: "Losses due to nuclear reactions, radiation, or related events."
        },
        {
            title: "Pre-existing Conditions",
            content: "Losses or declines in revenue occurring before the policy inception."
        },
        {
            title: "Financial Insolvency",
            content: "Revenue losses due to the financial insolvency of the business."
        },
        {
            title: "Legal Violations",
            content: "Losses resulting from violations of laws or regulations by the insured."
        },
        {
            title: "Uninsured Events",
            content: "Revenue losses due to events not specifically covered by the policy."
        }
    ]
    return (
        <>
            <Header />
            <div className="">
                <InnerHero
                    heading="Sales Turnover Policy"
                    title="Protect your business revenue from unforeseen losses with our comprehensive Sales Turnover Policy. Ensure financial stability even during challenging times."
                    image="/assets/img/sales-turn-over-hero.png"
                />
                <Experience />
                <section className="w3-section my-5 px-lg-5 px-lg-80">
                    <div className="d-flex flex-row align-items-center justify-content-between px-4">
                        <div onClick={() => setActiveW('what')} className={`pointer ${activeW === 'what' ? 'active-w3' : ''}`}>
                            <p>What</p>
                        </div>
                        <div onClick={() => setActiveW('who')} className={`pointer ${activeW === 'who' ? 'active-w3' : ''}`}>
                            <p>Who</p>
                        </div>
                        <div onClick={() => setActiveW('why')} className={`pointer ${activeW === 'why' ? 'active-w3' : ''}`}>
                            <p>Why</p>
                        </div>
                        <div onClick={() => setActiveW('coverage')} className={`pointer ${activeW === 'coverage' ? 'active-w3' : ''}`}>
                            <p>Coverages & Exclusions</p>
                        </div>
                    </div>
                </section>
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">What Is Sales Turnover Policy?</h1>
                        <p className="mt-5 content-regular">The Sales Turnover Policy is designed to protect businesses against potential losses in revenue due to unforeseen events. This policy ensures that your business remains financially stable even if your sales turnover is affected by incidents such as natural disasters, supply chain disruptions, or other unexpected occurrences. It provides coverage for the loss of income that results from a decline in sales due to these events.</p>
                    </div>
                </section>
                <section className="coverage-section">
                    <div className="px-lg-5 px-lg-80">
                        <h1 className="title-primary-color mb-4">Key Features of Sales Turnover Policy</h1>
                        <Slider {...settings}>
                            <Side
                                heading="Side A"
                                content="Revenue Protection"
                                image="/assets/img/sideA.png"
                                description="Covers loss of income due to a decline in sales turnover caused by insured events."
                            />
                            <Side
                                heading="Side B"
                                content="Business Continuity"
                                image="/assets/img/sideB.png"
                                description="Ensures that businesses can maintain operations and financial stability during disruptions."
                            />
                            <Side
                                heading="Side C"
                                content="Customizable Coverage"
                                image="/assets/img/sideC.png"
                                description="Tailored to fit the specific needs and risk profile of your business."
                            />
                            <Side
                                heading="Side D"
                                content="Comprehensive Support"
                                image="/assets/img/sideC.png"
                                description="Assistance with claims and recovery processes to minimize financial impact."
                            />
                        </Slider>

                    </div>
                    
                </section>
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">Who Needs Sales Turnover Policy?</h1>
                        <p className="mt-5 content-regular">The Sales Turnover Policy is essential for businesses that rely heavily on consistent revenue streams and are vulnerable to disruptions. This policy is particularly beneficial for: Manufacturing companies, Retail businesses, Wholesale distributors, Service providers, Hospitality and tourism businesses, SMEs and large enterprises</p>
                    </div>
                </section>
                <ServiceBlock
                    title="Factors Influencing Sales Turnover Policy Coverage"
                    subtitle=""
                    points={["Nature of business and industry:","Historical revenue data:","Risk exposure to supply chain disruptions:","Geographical location and market conditions:","Business size and financial stability:"]}
                    ctaLabel=""
                    ctaLink=""
                    image="/assets/img/do-coverage-factor.png"
                    type="primary"
                    imageLocation="right"
                />
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">Why Get Sales Turnover Policy?</h1>
                        <p className="my-5 content-regular">A Sales Turnover Policy provides vital protection for businesses against potential revenue losses. Key reasons to opt for this coverage include:</p>
                        <BSServiceBlock points={["Financial Stability:Ensures a steady flow of income even during adverse conditions","Risk Mitigation:Reduces the financial impact of unforeseen events on your business.","Operational Continuity:Helps maintain business operations without major disruptions.","Peace of Mind:Provides assurance that your business revenue is protected.","Competitive Advantage:Allows businesses to recover quickly and maintain their market position."]} />
                    </div>
                </section>
                <ServiceBlock
                    title="Common Risks Covered by Sales Turnover Policy"
                    subtitle=""
                    points={["Natural disasters (floods, storms, earthquakes):","Fire and explosions:","Supply chain interruptions:","Equipment breakdowns:","Market fluctuations due to unforeseen events:","Political and civil unrest:"]}
                    ctaLabel=""
                    ctaLink=""
                    image="/assets/img/litigation.png"
                    type="primary"
                    imageLocation="right"
                />
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">Coverages & Exclusions in Sales Turnover Policy</h1>
                        <section className="w3-section my-5">
                            <div className="d-flex flex-row">
                                <div onClick={() => setCoverage(true)} className={`mr-4 pointer ${isCoverage ? 'active-w3' : ''}`}>
                                    <p>Coverages</p>
                                </div>
                                <div onClick={() => setCoverage(false)} className={`pointer ${!isCoverage ? 'active-w3' : ''}`}>
                                    <p>Exclusions</p>
                                </div>
                            </div>
                        </section>
                        <div>
                            {isCoverage && (
                                <p>The Sales Turnover Policy provides extensive coverage to protect your business revenue. Key coverages include:</p>
                            )}
                            {!isCoverage && (
                                <p>While the Sales Turnover Policy offers comprehensive coverage, some exclusions typically include:</p>
                            )}

                        </div>
                        {isCoverage && (
                            <div className="row">
                                {coverages.map((c, i) => (
                                    <div className="col-12 col-lg-6">
                                        <h5 className="title-primary-color my-3">{`${i + 1}. ${c.title}`}</h5>
                                        <p>{`${c.content}`}</p>
                                    </div>
                                ))}
                            </div>
                        )}
                        {!isCoverage && (
                            <div className="row">
                                {exclusion.map((c, i) => (
                                    <div className="col-12 col-lg-6">
                                        <h5 className="title-primary-color my-3">{`${i + 1}. ${c.title}`}</h5>
                                        <p>{`${c.content}`}</p>
                                    </div>
                                ))}
                            </div>
                        )}

                    </div>
                </section>
                <ClaimsExample 
                    title="Sales Turnover Policy Claims Examples"
                    subtitle="Supply Chain Disruption"
                    incident="A key supplier experienced a major production halt due to a natural disaster, disrupting the supply chain and affecting the company's ability to meet customer demand."
                    resolution="The Sales Turnover Policy provided compensation for the lost revenue during the supply chain disruption, helping the company maintain financial stability and recover quickly."
                    image="/assets/img/claims-example.png"
                />
               {/*  <section className="my-4">
                    <p className={`service-title title-primary-color text-center`}>{'Frequently Asked Questions'}</p>
                    <div className=" p-5">
                    <div id="accordion" className="px-lg-5 px-lg-80 p-5">
                        {faq.salesTurnover.map(f => (<Accordian faq={f} />))}
                    </div>
                </div>
                </section> */}
                <ServiceBlock
                    title="Why Choose Buckss for Sales Turnover Policy?"
                    subtitle=""
                    points={
                        [
                            "Expertise:Leveraging over 50 years of combined experience in the insurance industry.",
                            "Tailored Solutions: Customized Sales Turnover Policy plans to suit your business needs.",
                            "Dedicated Support:Comprehensive support throughout the claims process.",
                            "Top Partnerships:Strong relationships with leading insurance providers for optimal coverage.",
                        ]
                    }
                    ctaLabel=""
                    ctaLink=""
                    image="/assets/img/insurance-agent-vector.png"
                    type="primary"
                    imageLocation="right"
                />
                <div className="mt-4">
                        <InsuranceServices />
                    </div>
               <div className="mt-4">
               <ProtectBusiness />
               </div>
            </div>
            <Footer />
        </>
    )
}

export default SalesTurnoverInsurance