const downloadLink = () => {
    if (navigator.userAgent.toLowerCase().indexOf("iphone") > -1) {
        //window.location.href = 'https://apps.apple.com/us/app/briezi/id1585491694'; 
        return 'https://apps.apple.com/us/app/briezi/id1585491694';
    }

    if (navigator.userAgent.toLowerCase().indexOf("android") > -1) {
        //window.location.href = 'https://play.google.com/store/apps/details?id=com.briezi.customer'; 
        return 'https://play.google.com/store/apps/details?id=com.briezi.customer'
    }

    //Update #2
    if (!navigator.userAgent.match(/(iPhone|iPod|iPad|Android|BlackBerry|IEMobile)/)) {
        //window.location.href = 'https://play.google.com/store/apps/details?id=com.briezi.customer'; //Desktop Browser
        return 'https://play.google.com/store/apps/details?id=com.briezi.customer'
    }
}

export const downloadPartnerLink = () => {
    if (navigator.userAgent.toLowerCase().indexOf("iphone") > -1) {
        //window.location.href = 'https://apps.apple.com/us/app/briezi/id1585491694'; 
        return 'https://apps.apple.com/us/app/briezi/id1634359442';
    }

    if (navigator.userAgent.toLowerCase().indexOf("android") > -1) {
        //window.location.href = 'https://play.google.com/store/apps/details?id=com.briezi.customer'; 
        return 'https://play.google.com/store/apps/details?id=com.briezi.partner'
    }

    //Update #2
    if (!navigator.userAgent.match(/(iPhone|iPod|iPad|Android|BlackBerry|IEMobile)/)) {
        //window.location.href = 'https://play.google.com/store/apps/details?id=com.briezi.partner'; //Desktop Browser
        return 'https://play.google.com/store/apps/details?id=com.briezi.partner'
    }
}


export default downloadLink;