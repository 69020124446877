import { useState } from "react"
import Footer from "../components/Footer"
import Header from "../components/Header"
import InnerHero from "../components/InnerHero"
import Experience from "../components/Experience"
import Side from "../components/Side"
import ServiceBlock from "../components/ServiceBlock"
import BSServiceBlock from "../components/BSServiceBlock"
import InsuranceServices from "../components/InsuranceServices"
import ProtectBusiness from "../components/ProtectBusiness"
import ClaimsExample from "../components/ClaimsExample"
import faq from "../data/faq"
import Accordian from "../components/Accordian"
import Slider from "react-slick"
import settings from "../lib/SliderSettings"

const GroupPersonalAccident = () => {
    const [activeW, setActiveW] = useState('what')
    const [isCoverage, setCoverage] = useState(true)
    const coverages = [
        {
            title: "Accidental Death",
            content: "Lump-sum payment to the nominee in case of the insured's accidental death."
        },
        {
            title: "Permanent Total Disability (PTD)",
            content: "Lump-sum payment if the insured suffers a permanent total disability due to an accident."
        },
        {
            title: "Permanent Partial Disability (PPD):",
            content: "Lump-sum payment based on the severity of the partial disability caused by an accident"
        },
        {
            title: "Temporary Total Disability (TTD):",
            content: " Weekly benefit to compensate for loss of income during the period of temporary total disability."
        },
        {
            title: "Medical Expenses",
            content: "Reimbursement of medical expenses incurred due to an accident."
        },
        {
            title: "Hospital Cash",
            content: "Daily cash benefit for the number of days hospitalized due to an accident."
        },
        {
            title: "Transportation of Mortal Remains",
            content: "Covers expenses for transporting the insured's mortal remains to their residence."
        },
        {
            title: "Education Fund",
            content: "Financial assistance for the education of dependent children in case of accidental death or PTD."
        }
    ]
    const exclusion = [
        {
            title: "Self-Inflicted Injuries",
            content: "Injuries caused intentionally by the insured are not covered."
        },
        {
            title: "Suicide",
            content: "Death or disability resulting from suicide or attempted suicide is excluded."
        },
        {
            title: "Participation in Hazardous Activities",
            content: "Injuries sustained while participating in hazardous activities such as skydiving, bungee jumping, etc"
        },
        {
            title: "War and Nuclear Risks",
            content: "Damages due to war or nuclear activities are excluded."
        },
        {
            title: "Influence of Alcohol or Drugs",
            content: "Injuries sustained while under the influence of alcohol or drugs."
        },
        {
            title: "Pregnancy and Childbirth",
            content: "Any injury or condition arising from pregnancy or childbirth"
        },
        {
            title: "Pre-Existing Conditions",
            content: "Accidents resulting from pre-existing medical conditions."
        },
        {
            title: "Mental and Nervous Disorders:",
            content: "Conditions related to mental and nervous disorders are not covered."
        }
    ]
    return (
        <>
            <Header />
            <div className="">
                <InnerHero
                    heading="Group Personal Accident Insurance"
                    title="Ensure your employees' financial protection against accidents with our comprehensive Group Personal Accident Insurance plans."
                    image="/assets/img/gpa-hero.png"
                />
                <Experience />
                <section className="w3-section my-5 px-lg-5 px-lg-80">
                    <div className="d-flex flex-row align-items-center justify-content-between px-4">
                        <div onClick={() => setActiveW('what')} className={`pointer ${activeW === 'what' ? 'active-w3' : ''}`}>
                            <p>What</p>
                        </div>
                        <div onClick={() => setActiveW('who')} className={`pointer ${activeW === 'who' ? 'active-w3' : ''}`}>
                            <p>Who</p>
                        </div>
                        <div onClick={() => setActiveW('why')} className={`pointer ${activeW === 'why' ? 'active-w3' : ''}`}>
                            <p>Why</p>
                        </div>
                        <div onClick={() => setActiveW('coverage')} className={`pointer ${activeW === 'coverage' ? 'active-w3' : ''}`}>
                            <p>Coverages & Exclusions</p>
                        </div>
                    </div>
                </section>
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">What is Group Personal Accident Insurance?</h1>
                        <p className="mt-5 content-regular">Group Personal Accident Insurance provides financial compensation to employees in the event of accidental injuries, disabilities, or death. This type of insurance ensures that employees and their families are financially protected in case of unforeseen accidents, covering medical expenses, loss of income, and other related costs.                        </p>
                    </div>
                </section>
                <section className="coverage-section">
                    <div className="px-lg-5 px-lg-80">
                        <h1 className="title-primary-color mb-4">Key Features of Group Personal Accident Insurance</h1>
                       <Slider {...settings}>
                            <Side
                                heading="Side A"
                                content="Comprehensive Coverage"
                                image="/assets/img/sideA.png"
                                description="Includes accidental death, permanent total disability, permanent partial disability, and temporary total disability."
                            />
                            <Side
                                heading="Side B"
                                content="Immediate Financial Relief"
                                image="/assets/img/sideB.png"
                                description="Provides quick financial assistance to employees and their families during emergencies."
                            />
                            <Side
                                heading="Side C"
                                content="Worldwide Coverage"
                                image="/assets/img/sideC.png"
                                description="Offers protection against accidents occurring anywhere in the world."
                            />
                            <Side
                                heading="Side D"
                                content="No Medical Check-ups Required"
                                image="/assets/img/sideC.png"
                                description="Employees can be covered without the need for medical examinations."
                            />
                            <Side
                                heading="Side E"
                                content="Customizable Plans"
                                image="/assets/img/sideC.png"
                                description="Tailored to meet the specific needs of your business and employees."
                            />
                        </Slider>

                    </div>
                    <ServiceBlock
                        title="Who Can Benefit from Group Personal Accident Insurance"
                        subtitle="A narrative about how Buckss was founded, the inspiration behind the company, and its journey so far."
                        points={["Employers seeking to offer competitive benefits:","Employees and their families:","HR professionals managing employee benefits:","Business owners looking to enhance their workforce's safety:"]}
                        ctaLabel=""
                        ctaLink=""
                        image="/assets/img/do-advantage.png"
                        type="primary"
                        imageLocation="right"
                    />
                </section>
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">Who Needs Group Personal Accident Insurance?</h1>
                        <p className="mt-5 content-regular">Group Personal Accident Insurance is essential for any organization that wants to ensure the safety and well-being of its employees. It is particularly beneficial for: Companies with high-risk work environments, Small, medium, and large enterprises, Manufacturing and construction firms, Logistics and transportation companies, Any organization with employees exposed to potential accidents</p>
                    </div>
                </section>
                <ServiceBlock
                    title="Factors Influencing Group Personal Accident Insurance Coverage"
                    subtitle=""
                    points={["Size of the group:","Nature of work and associated risks","Age and health profile of the employees", "Coverage limits required", "Budget and financial considerations"]}
                    ctaLabel=""
                    ctaLink=""
                    image="/assets/img/do-coverage-factor.png"
                    type="primary"
                    imageLocation="right"
                />
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">Why Get Group Personal Accident Insurance?</h1>
                        <p className="my-5 content-regular">Group Personal Accident Insurance is crucial for ensuring the financial security of employees and their families. It provides:</p>
                        <BSServiceBlock points={["Financial Protection: Covers medical expenses, loss of income, and other costs related to accidental injuries.","Employee Retention:Demonstrates that the employer values their safety and well-being.", "Increased Productivity: Peace of mind leads to higher employee morale and productivity.", "Legal Compliance: Helps meet statutory requirements in certain industries.","Enhanced Reputation: Companies offering comprehensive benefits are viewed more favorably by potential hires and clients."]} />
                    </div>
                </section>
                <ServiceBlock
                    title="Benefits for Employers"
                    subtitle=""
                    points={["Attract and Retain Talent: Competitive accident coverage helps attract and retain skilled employees.","Tax Benefits: Premiums paid by the employer are tax-deductible, reducing overall tax liability.","Boosted Employee Morale: Offering accident insurance shows employees that their safety is a priority, boosting morale and loyalty.","Reduced Absenteeism: Employees are more likely to recover and return to work promptly when they have financial support.","Enhanced Company Reputation: Companies that provide comprehensive benefits are viewed more favorably by potential hires and clients."]}
                    ctaLabel=""
                    ctaLink=""
                    image="/assets/img/litigation.png"
                    type="primary"
                    imageLocation="right"
                />
                <ServiceBlock
                    title="Benefits for Employees"
                    subtitle=""
                    points={["Accidental Death Cover: Provides financial compensation to the family in the event of accidental death.","Disability Cover: Covers permanent total disability, permanent partial disability, and temporary total disability due to an accident.","Medical Expense Reimbursement: Covers hospitalization and medical treatment costs arising from an accident.","Weekly Benefit: Provides a weekly benefit to compensate for loss of income during the period of temporary total disability.","Education Fund: Financial assistance for the education of children in case of accidental death or permanent total disability.","Transportation and Repatriation Costs: Covers the cost of transporting the insured person to the hospital and repatriation in case of death.","Peace of Mind: Knowing they are covered in case of an accident allows employees to focus on their work."]}
                    ctaLabel=""
                    ctaLink=""
                    image="/assets/img/litigation.png"
                    type="light"
                    imageLocation="left"
                />
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">Coverages & Exclusions in D&O Insurance*</h1>
                        <section className="w3-section my-5">
                            <div className="d-flex flex-row">
                                <div onClick={() => setCoverage(true)} className={`mr-4 pointer ${isCoverage ? 'active-w3' : ''}`}>
                                    <p>Coverages</p>
                                </div>
                                <div onClick={() => setCoverage(false)} className={`pointer ${!isCoverage ? 'active-w3' : ''}`}>
                                    <p>Exclusions</p>
                                </div>
                            </div>
                        </section>
                        <div>
                            {isCoverage && (
                                <p>D&O insurance offers comprehensive coverage to protect against various risks. Key coverages include</p>
                            )}
                            {!isCoverage && (
                                <p>Certain situations are excluded from D&O coverage, including:</p>
                            )}

                        </div>
                        {isCoverage && (
                            <div className="row">
                                {coverages.map((c, i) => (
                                    <div className="col-12 col-lg-6">
                                        <h5 className="title-primary-color my-3">{`${i + 1}. ${c.title}`}</h5>
                                        <p>{`${c.content}`}</p>
                                    </div>
                                ))}
                            </div>
                        )}
                        {!isCoverage && (
                            <div className="row">
                                {exclusion.map((c, i) => (
                                    <div className="col-12 col-lg-6">
                                        <h5 className="title-primary-color my-3">{`${i + 1}. ${c.title}`}</h5>
                                        <p>{`${c.content}`}</p>
                                    </div>
                                ))}
                            </div>
                        )}

                    </div>
                </section>
                <ClaimsExample 
                    title="Group Personal Accident Insurance Claims Examples"
                    subtitle="Case of Workplace Accident"
                    incident="An employee suffered a severe injury while operating machinery at work, resulting in temporary total disability."
                    resolution="The Group Personal Accident Insurance policy provided a weekly benefit to compensate for the loss of income during the recovery period and covered all medical expenses, ensuring the employee could focus on recovery without financial worries."
                    image="/assets/img/claims-example.png"
                />
                {/* <section className="my-4">
                    <p className={`service-title title-primary-color text-center`}>{'Frequently Asked Questions'}</p>
                    <div className=" p-5">
                    <div id="accordion" className="px-lg-5 px-lg-80 p-5">
                        {faq.groupAccident.map(f => (<Accordian faq={f} />))}
                    </div>
                </div>
                </section> */}
                <ServiceBlock
                    title="Why Choose Buckss for D&O Insurance?"
                    subtitle=""
                    points={
                        [
                            "Expertise:Leveraging over 50 years of combined experience in the insurance industry.",
                            "Tailored Solutions: Customized Group Personal Accident Insurance plans to suit your business needs.",
                            "Dedicated Support:Comprehensive support throughout the claims process.",
                            "Top Partnerships:Strong relationships with leading insurance providers for optimal coverage.",
                        ]
                    }
                    ctaLabel=""
                    ctaLink=""
                    image="/assets/img/insurance-agent-vector.png"
                    type="primary"
                    imageLocation="right"
                />
                <div className="mt-4">
                        <InsuranceServices />
                    </div>
               <div className="mt-4">
               <ProtectBusiness />
               </div>
            </div>
            <Footer />
        </>
    )
}

export default GroupPersonalAccident