const ProtectBusiness = () => {
    return(
        <div className="row banner mx-lg-5 mx-4 my-4">
        <div className="col-12 col-lg-7 px-lg-5 carousel-slide">
          <p className="banner-smallTitle ">PROTECT YOUR BUSINESS</p>
          <p className="service-title">Get a Quick Insurance Quote</p>
          <p className="service-subtitle">Comprehensive suite of insurance benefits and insurance products to protect your team and your business.</p>
          <div>
            <div className="nav-item primary-button my-3 mr-3">
              <a className="nav-link" href="mailto:po@buckss.in">Get a Quote</a>
            </div>
            <div className="nav-item outline-button my-3 ml-3">
              <a className="nav-link" href="mailto:po@buckss.in">Contact Us</a>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-5">
          <img className="slider-img" src="/assets/img/get-quote.png" />
        </div>
      </div>
    )
}

export default ProtectBusiness;
