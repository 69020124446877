import Header from "./components/Header";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ServiceBlock from "./components/ServiceBlock";
import { useState } from "react";
import Footer from './components/Footer';
import insuranceTypeData from "./data/insuranceType";
import insurer from "./data/insurer";
import InsuranceServices from "./components/InsuranceServices";
import InsurerPartner from "./components/InsurancePartner";

function App() {





  return (
    <div>
      <Header />

      <div className="row px-lg-5 px-lg-80 p-5">
        <div className="col-12 col-lg-7 px-lg-5 my-3">
          <p className="service-title">Comprehensive Insurance Solutions for MSMEs, Corporates and Institutions</p>
          <p className="service-subtitle">Providing tailored insurance solutions for MSMEs, corporates and institutions.</p>
          <div>
            <div className="nav-item primary-button my-3 mr-3">
              <a className="nav-link" href="/contactus"  >Connect with us</a>
            </div>
            <div className="nav-item outline-button my-3 ml-3">
              <a className="nav-link" href="#know-more"  >Know more</a>
            </div>
          </div>
          <div className="d-flex justify-content-between my-3">
            <div className="d-flex flex-column justify-content-center align-items-center">
              <img src="/assets/img/MedicalAgent.png" className="icon my-3" />
              <p className="text-center primary-bold">Expert Advice</p>
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center">
              <img src="/assets/img/ConsultantSupport.png" className="icon my-3" />
              <p className="text-center primary-bold">Free Consultation</p>
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center">
              <img src="/assets/img/Insurance.png" className="icon my-3" />
              <p className="text-center primary-bold">25+ Insurers</p>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-5 my-3 d-flex align-items-center justify-content-center">
          <img className="slider-img" src="/assets/img/banner.png" />
        </div>
      </div>

      <InsuranceServices />
      <InsurerPartner />
      <ServiceBlock
        title="Features"
        subtitle=""
        points={["Specialized services for MSMEs: Tailored solutions to meet MSME insurance needs.", "Tailored solutions for institutions: Custom insurance plans for educational and corporate institutions.", "Individual insurance plans: Personalized insurance for individuals and families."]}
        ctaLabel=""
        ctaLink=""
        image="/assets/img/features.png"
        type="primary"
        imageLocation="right"
      />
      <ServiceBlock
        title="Benefits of Choosing Buckss Insurance"
        subtitle=""
        points={["Personalized Service: Customized insurance solutions to meet your specific needs.", "Expert Advice: Professional guidance to help you choose the best insurance plans.", "Competitive Rates: Affordable premiums without compromising on coverage.", "Claim Assistance: End-to-end support for a hassle-free claim process."]}
        ctaLabel=""
        ctaLink=""
        image="/assets/img/buckss-benefits.png"
        type="light"
        imageLocation="left"
      />
      <ServiceBlock
        title="WHY CHOOSE BUCKSS INSURANCE?"
        subtitle=""
        points={["Expertise: Our founding team has over 2 decades of industry experience.", "Tailored Solutions: Expert advice on selecting the best plans, we provide personalized advice to help you choose the most suitable insurance plans at best price.", "Comprehensive Coverage: Access a wide range of insurance products from trusted insurance companies.", "Personalized Support: Dedicated team providing end-to-end assistance."]}
        ctaLabel=""
        ctaLink="/"
        image="/assets/img/features.png"
        type="primary"
        imageLocation="right"
      />
      <div className="my-3">
        <p className="text-center service-title">Our Clients</p>

        <div className="my-5">
          <div className="d-flex flex-row justify-content-center flex-wrap">
            <div className="mx-3 my-3">
              <img src="/assets/img/excelsoft.png" className="client-logo" />
            </div>
            <div className="mx-3 my-3">
              <img src="/assets/img/rangsons.png" className="client-logo" />
            </div>
            <div className="mx-3 my-3">
              <img src="/assets/img/nrr.png" className="client-logo" />
            </div>
          </div>
          <div className="d-flex flex-row justify-content-center flex-wrap my-3">
          <div className="mx-3 justify-content-center align-items-center d-flex">
              <img src="/assets/img/ExcelPublicSchool.png" className="client-logo-square" />
            </div>
            <div className="mx-3 justify-content-center align-items-center d-flex">
              <img src="/assets/img/WorldOfSilver.png" className="client-logo-square" />
            </div>
            <div className="mx-3 justify-content-center align-items-center d-flex">
              <img src="/assets/img/MadanJewellers.png" className="client-logo-square" />
            </div>
            <div className="mx-3 justify-content-center align-items-center d-flex">
              <img src="/assets/img/Seshadripuram.png" className="client-logo-square" />
            </div>
          </div>
        </div>
      </div>
      <div className="my-3 primaryBox  px-lg-5 px-lg-80 p-5">
        <p className="text-center meet-team">Meet Our Team</p>
        <p className="text-center team-title">Meet the dedicated professionals behind Buckss Insurance. Our team brings a wealth of experience and expertise to help you navigate the complexities of insurance.</p>

        <div className="my-5">
          <div className="d-flex flex-row justify-content-center flex-wrap">
            <div className="mx-3 d-flex flex-column justify-content-center align-items-center">
              <img src="/assets/img/tiwari.png" className="client-logo" />
              <div className="d-flex flex-column justify-content-center align-items-center">
                <p className="text-center">Vinod R Tiwari</p>
                <p>Principal Officer & Chief Business Officer</p>
                <div className="slide-cta outline-button-white ml-2">
                  <a className="nav-link" href="tel:9342182134"  >{"Contact Vinod R Tiwari"}</a>
                </div>
              </div>
            </div>
            <div className="mx-3 d-flex flex-column justify-content-center align-items-center">
              <img src="/assets/img/krishna.png" className="client-logo" />
              <div className="d-flex flex-column justify-content-center align-items-center">
                <p className="text-center">Krishna M.V</p>
                <p>Broker Qualified Person</p>
                <div className="slide-cta outline-button-white ml-2">
                  <a className="nav-link" href="tel:9902317933"  >{"Contact Krishna"}</a>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div className="row banner mx-lg-5 mx-4 my-4">
        <div className="col-12 col-lg-7 px-lg-5 carousel-slide">
          <p className="banner-smallTitle ">PROTECT YOUR BUSINESS</p>
          <p className="service-title">Get a Quick Insurance Quote</p>
          <p className="service-subtitle">Comprehensive suite of insurance benefits and insurance products to protect your team and your business.</p>
          <div>
            <div className="nav-item primary-button my-3 mr-3">
              <a className="nav-link" href="mailto:po@buckss.in">Get a Quote</a>
            </div>
            <div className="nav-item outline-button my-3 ml-3">
              <a className="nav-link" href="mailto:po@buckss.in">Contact Us</a>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-5">
          <img className="slider-img" src="/assets/img/get-quote.png" />
        </div>
      </div>
      <Footer />
    </div >
  );
}

export default App;
