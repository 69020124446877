import { useState } from "react"
import Footer from "../components/Footer"
import Header from "../components/Header"
import InnerHero from "../components/InnerHero"
import Experience from "../components/Experience"
import Side from "../components/Side"
import ServiceBlock from "../components/ServiceBlock"
import BSServiceBlock from "../components/BSServiceBlock"
import InsuranceServices from "../components/InsuranceServices"
import ProtectBusiness from "../components/ProtectBusiness"
import ClaimsExample from "../components/ClaimsExample"
import faq from "../data/faq"
import Accordian from "../components/Accordian"
import Slider from "react-slick"
import settings from "../lib/SliderSettings"

const ContractorPlantMachiner = () => {
    const [activeW, setActiveW] = useState('what')
    const [isCoverage, setCoverage] = useState(true)
    const coverages = [
        {
            title: "Material Damage",
            content: "Covers physical loss or damage to machinery due to fire, theft, accidental damage, and natural disasters."
        },
        {
            title: "Third-Party Liability",
            content: "Protects against legal liabilities for bodily injury or property damage to third parties arising from the use of machinery."
        },
        {
            title: "Transit Coverage:",
            content: " Includes protection for machinery while in transit to and from project sites."
        },
        {
            title: "Breakdown Coverage",
            content: "Covers mechanical or electrical breakdown of machinery."
        },
        {
            title: "Professional Fees",
            content: "Covers the fees of architects, engineers, and consultants involved in the project."
        },
        {
            title: "Debris Removal",
            content: "Covers the cost of removing debris following an insured event."
        },
        {
            title: "Additional Costs",
            content: "Covers extra expenses incurred to expedite repairs and minimize project delays."
        },
        {
            title: "Replacement Costs",
            content: " Covers the cost of replacing machinery if it is damaged beyond repair."
        },
        
    ]
    const exclusion = [
        {
            title: "Wear and Tear",
            content: "Damages due to regular wear and tear or gradual deterioration."
        },
        {
            title: "Defective Design",
            content: " Losses arising from faulty design, workmanship, or materials."
        },
        {
            title: "Pre-existing Conditions",
            content: "Losses or damages that existed before the policy inception."
        },
        {
            title: "War and Terrorism",
            content: "Damages resulting from war, invasion, or acts of terrorism."
        },
        {
            title: "Nuclear Risks",
            content: "Losses due to nuclear reactions or radiation."
        },
        {
            title: "Contractual Penalties",
            content: "Penalties imposed due to contractual breaches or delays."
        },
        {
            title: "Employee Dishonesty",
            content: "Losses caused by fraudulent acts of employees."
        },
        {
            title: "Unexplained Losses",
            content: " Losses for which the cause cannot be determined."
        },
        {
            title: "Government Action",
            content: "Losses due to actions or orders from the government."
        },
    ]
    return (
        <>
            <Header />
            <div className="">
                <InnerHero
                    heading="Contractor's Plant & Machinery Insurance"
                    title="Protect your valuable construction equipment and machinery from unexpected damages and losses with our comprehensive Contractor's Plant & Machinery Insurance. "
                    image="/assets/img/contractor-machiner-hero.png"
                />
                <Experience />
                <section className="w3-section my-5 px-lg-5 px-lg-80">
                    <div className="d-flex flex-row align-items-center justify-content-between px-4">
                        <div onClick={() => setActiveW('what')} className={`pointer ${activeW === 'what' ? 'active-w3' : ''}`}>
                            <p>What</p>
                        </div>
                        <div onClick={() => setActiveW('who')} className={`pointer ${activeW === 'who' ? 'active-w3' : ''}`}>
                            <p>Who</p>
                        </div>
                        <div onClick={() => setActiveW('why')} className={`pointer ${activeW === 'why' ? 'active-w3' : ''}`}>
                            <p>Why</p>
                        </div>
                        <div onClick={() => setActiveW('coverage')} className={`pointer ${activeW === 'coverage' ? 'active-w3' : ''}`}>
                            <p>Coverages & Exclusions</p>
                        </div>
                    </div>
                </section>
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">What is Contractor's Plant & Machinery Insurance?</h1>
                        <p className="mt-5 content-regular">Contractor's Plant & Machinery (CPM) Insurance is designed to provide extensive coverage for plant and machinery used by contractors at construction sites. This policy covers the machinery against a wide range of risks, including physical loss or damage from external causes while at work, rest, or during maintenance.
                        </p>
                    </div>
                </section>
                <section className="coverage-section">
                    <div className="px-lg-5 px-lg-80">
                        <h1 className="title-primary-color mb-4">Key Benefits of Contractor's Plant & Machinery Insurance</h1>
                        <Slider {...settings}>
                            <Side
                                heading="Side A"
                                content="All-Risk Coverage"
                                image="/assets/img/sideA.png"
                                description="Protects against various risks, including fire, theft, accidental damage, and natural calamities"
                            />
                            <Side
                                heading="Side B"
                                content="On-Site and Off-Site Coverage"
                                image="/assets/img/sideB.png"
                                description="Covers equipment both on-site and during transit to different locations."
                            />
                            <Side
                                heading="Side C"
                                content="Third-Party Liability"
                                image="/assets/img/sideC.png"
                                description="Includes protection against third-party legal liabilities arising from the use of machinery."
                            />
                             <Side
                                heading="Side D"
                                content="Cost of Repairs and Replacement"
                                image="/assets/img/sideC.png"
                                description="Covers expenses related to repairing or replacing damaged machinery."
                            />
                             <Side
                                heading="Side E"
                                content="Financial Stability"
                                image="/assets/img/sideC.png"
                                description="Ensures continuity of projects by mitigating financial losses due to equipment damage."
                            />
                        </Slider>

                    </div>
                    <ServiceBlock
                        title="Advantages of CPM Insurance"
                        subtitle=""
                        points={["Comprehensive protection for all types of construction machinery:","Reduces financial burden from unforeseen equipment damages:","Helps maintain project timelines by minimizing delays:","Enhances project resilience and operational efficiency:","Provides peace of mind with reliable coverage:"]}
                        ctaLabel=""
                        ctaLink=""
                        image="/assets/img/do-advantage.png"
                        type="primary"
                        imageLocation="right"
                    />
                </section>
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">Who Needs Contractor's Plant & Machinery Insurance?                        </h1>
                        <p className="mt-5 content-regular">Contractor's Plant & Machinery Insurance is essential for any entity involved in construction and infrastructure development. It is particularly beneficial for:</p>
                        <BSServiceBlock points={["Construction companies:","Infrastructure developers:","Contractors and subcontractors","Engineering firms:","Equipment rental companies:","Project owners:"]} />
                    </div>
                </section>
                <ServiceBlock
                    title="Factors Influencing CPM Insurance Coverage"
                    subtitle=""
                    points={["Type and value of machinery:","Nature and location of construction projects:","Frequency of equipment usage:","Maintenance and safety practices:","Contractor's experience and track record:"]}
                    ctaLabel=""
                    ctaLink=""
                    image="/assets/img/contractor-machiner-factor.png"
                    type="primary"
                    imageLocation="right"
                />
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color"> Why Get Contractor's Plant & Machinery Insurance?</h1>
                        <p className="my-5 content-regular">Contractor's Plant & Machinery Insurance is crucial for protecting your equipment investment and ensuring smooth project execution. The policy provides:</p>
                        <BSServiceBlock points={["Coverage for physical damage to machinery","Protection against theft and vandalism"," Financial support for repairs and replacements","Compensation for third-party liability claims:","Assurance of project continuity and reduced downtime:"]} />
                    </div>
                </section>
                <ServiceBlock
                    title="Who Can Benefit from CPM Insurance?"
                    subtitle=""
                    points={["Construction companies aiming to protect their machinery investment:","Contractors seeking to minimize financial loss from equipment damage:","Project owners looking for uninterrupted project execution:","Equipment rental companies needing comprehensive coverage:" ]}
                    ctaLabel=""
                    ctaLink=""
                    image="/assets/img/litigation.png"
                    type="primary"
                    imageLocation="right"
                />
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">Coverages & Exclusions in Contractor's All Risk Insurance</h1>
                        <section className="w3-section my-5">
                            <div className="d-flex flex-row">
                                <div onClick={() => setCoverage(true)} className={`mr-4 pointer ${isCoverage ? 'active-w3' : ''}`}>
                                    <p>Coverages</p>
                                </div>
                                <div onClick={() => setCoverage(false)} className={`pointer ${!isCoverage ? 'active-w3' : ''}`}>
                                    <p>Exclusions</p>
                                </div>
                            </div>
                        </section>
                        <div>
                            {isCoverage && (
                                <p>Contractor's Plant & Machinery Insurance offers extensive coverage to protect your valuable equipment. Key coverages include:</p>
                            )}
                            {!isCoverage && (
                                <p>Contractor's Plant & Machinery Insurance typically excludes certain situations, including:</p>
                            )}

                        </div>
                        {isCoverage && (
                            <div className="row">
                                {coverages.map((c, i) => (
                                    <div className="col-12 col-lg-6">
                                        <h5 className="title-primary-color my-3">{`${i + 1}. ${c.title}`}</h5>
                                        <p>{`${c.content}`}</p>
                                    </div>
                                ))}
                            </div>
                        )}
                        {!isCoverage && (
                            <div className="row">
                                {exclusion.map((c, i) => (
                                    <div className="col-12 col-lg-6">
                                        <h5 className="title-primary-color my-3">{`${i + 1}. ${c.title}`}</h5>
                                        <p>{`${c.content}`}</p>
                                    </div>
                                ))}
                            </div>
                        )}

                    </div>
                </section>
                <ClaimsExample 
                    title="Contractor's Plant & Machinery Insurance Claims Examples"
                    subtitle="Case of Machinery Damage During Construction"
                    incident="During a large infrastructure project, an excavator accidentally hit a buried utility line, causing significant damage to the machinery and a project delay."
                    resolution="The Contractor's Plant & Machinery Insurance policy covered the cost of repairing the damaged excavator and compensated for the additional expenses incurred due to the delay. The project was able to resume promptly, minimizing the impact on the timeline and budget."
                    image="/assets/img/claims-example.png"
                />
                {/* <section className="my-4">
                    <p className={`service-title title-primary-color text-center`}>{'Frequently Asked Questions'}</p>
                    <div className=" p-5">
                    <div id="accordion" className="px-lg-5 px-lg-80 p-5">
                        {faq.Contractor.map(f => (<Accordian faq={f} />))}
                    </div>
                </div>
                </section> */}
                <ServiceBlock
                    title="Why Choose Buckss for Contractor's Plant & Machinery Insurance?"
                    subtitle=""
                    points={
                        [
                            "Expertise:Over 50 years of combined experience in the insurance industry.",
                            "Tailored Solutions: Customized Contractor's All Risk Insurance plans to suit your project needs.",
                            "Dedicated Support:  Comprehensive support throughout the claims process.",
                            "Strong Partnerships: Collaborations with leading insurance providers to offer the best coverage options.",
                        ]
                    }
                    ctaLabel=""
                    ctaLink=""
                    image="/assets/img/insurance-agent-vector.png"
                    type="primary"
                    imageLocation="right"
                />
                <div className="mt-4">
                        <InsuranceServices />
                    </div>
               <div className="mt-4">
               <ProtectBusiness />
               </div>
            </div>
            <Footer />
        </>
    )
}

export default ContractorPlantMachiner