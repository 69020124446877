import { useState } from "react"
import Footer from "../components/Footer"
import Header from "../components/Header"
import InnerHero from "../components/InnerHero"
import Experience from "../components/Experience"
import Side from "../components/Side"
import ServiceBlock from "../components/ServiceBlock"
import BSServiceBlock from "../components/BSServiceBlock"
import InsuranceServices from "../components/InsuranceServices"
import ProtectBusiness from "../components/ProtectBusiness"
import ClaimsExample from "../components/ClaimsExample"
import faq from "../data/faq"
import Accordian from "../components/Accordian"
import Slider from "react-slick"
import settings from "../lib/SliderSettings"

const WorkmanCompensation = () => {
    const [activeW, setActiveW] = useState('what')
    const [isCoverage, setCoverage] = useState(true)
    const coverages = [
        {
            title: "Medical Expenses",
            content: " Covers all medical costs related to treatment of work-related injuries or illnesses."
        },
        {
            title: "Lost Wages",
            content: "Compensates for income lost while the employee is recovering and unable to work."
        },
        {
            title: "Permanent Disability Benefits",
            content: "Provides financial support for employees who suffer permanent disabilities due to workplace incidents."
        },
        {
            title: "Temporary Disability Benefits",
            content: "Offers financial assistance for employees temporarily unable to work."
        },
        {
            title: "Death Benefits",
            content: "Provides compensation to the dependents of employees who die as a result of work-related injuries or illnesses."
        },
        {
            title: "Rehabilitation and Retraining Costs",
            content: "Covers costs associated with rehabilitating and retraining employees to return to work."
        },
        {
            title: "Legal Fees",
            content: "Covers legal expenses incurred in defending against claims made by employees."
        },
        
        
    ]
    const exclusion = [
        {
            title: "Intentional Injuries",
            content: "Injuries or illnesses resulting from intentional actions by the employee."
        },
        {
            title: "Self-Inflicted Injuries",
            content: " Injuries or illnesses that are self-inflicted or arise from willful misconduct."
        },
        {
            title: "Non-Work-Related Incidents",
            content: "Injuries or illnesses that occur outside the course of employment."
        },
        {
            title: "War and Terrorism",
            content: "Damages resulting from war, invasion, or acts of terrorism."
        },
        {
            title: "Pre-Existing Conditions",
            content: "Conditions that existed before the employee's current employment."
        },
        {
            title: "Criminal Activities",
            content: "Injuries or illnesses sustained while engaging in illegal activities."
        },
        {
            title: "Non-Compliant Employers",
            content: " Claims made by employees of employers who fail to comply with safety regulations."
        },
        {
            title: "Off-Duty Incidents",
            content: "Injuries or illnesses occurring while the employee is off-duty and not performing work-related tasks."
        },
        {
            title: "Mental Health Issues",
            content: "Mental health conditions not directly related to work."
        },
    ]
    return (
        <>
            <Header />
            <div className="">
                <InnerHero
                    heading="Workmen's Compensation Insurance"
                    title="Ensure the well-being of your employees with comprehensive Workmen's Compensation Insurance that covers medical expenses and lost wages due to work-related injuries.  
"
                    image="/assets/img/workman-insurance-hero.png"
                />
                <Experience />
                <section className="w3-section my-5 px-lg-5 px-lg-80">
                    <div className="d-flex flex-row align-items-center justify-content-between px-4">
                        <div onClick={() => setActiveW('what')} className={`pointer ${activeW === 'what' ? 'active-w3' : ''}`}>
                            <p>What</p>
                        </div>
                        <div onClick={() => setActiveW('who')} className={`pointer ${activeW === 'who' ? 'active-w3' : ''}`}>
                            <p>Who</p>
                        </div>
                        <div onClick={() => setActiveW('why')} className={`pointer ${activeW === 'why' ? 'active-w3' : ''}`}>
                            <p>Why</p>
                        </div>
                        <div onClick={() => setActiveW('coverage')} className={`pointer ${activeW === 'coverage' ? 'active-w3' : ''}`}>
                            <p>Coverages & Exclusions</p>
                        </div>
                    </div>
                </section>
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">What Is Workmen's Compensation Insurance?</h1>
                        <p className="mt-5 content-regular">Workmen's Compensation Insurance provides coverage for employees who suffer job-related injuries or illnesses. This insurance ensures that employees receive necessary medical treatment and compensation for lost wages, reducing financial strain on both employees and employers. It is a legal requirement in many jurisdictions, ensuring that businesses provide a safe working environment and adequate support in case of accidents.
                        </p>
                    </div>
                </section>
                <section className="coverage-section">
                    <div className="px-lg-5 px-lg-80">
                        <h1 className="title-primary-color mb-4">Key Benefits of Workmen's Compensation Insurance</h1>
                        <Slider {...settings}>
                            <Side
                                heading="Medical Benefits"
                                content=""
                                image="/assets/img/sideA.png"
                                description="Covers the cost of medical treatment for work-related injuries or illnesses."
                            />
                            <Side
                                heading="Wage Replacement"
                                content=""
                                image="/assets/img/sideB.png"
                                description="Provides compensation for lost wages while the employee is unable to work."
                            />
                            <Side
                                heading="Disability Benefits"
                                content=""
                                image="/assets/img/sideC.png"
                                description="Offers financial support for temporary or permanent disabilities resulting from work-related incidents."
                            />
                             <Side
                                heading="Legal Compliance"
                                content=""
                                image="/assets/img/sideC.png"
                                description="Helps businesses comply with legal requirements for employee protection."
                            />
                             <Side
                                heading="Rehabilitation Support"
                                content=""
                                image="/assets/img/sideC.png"
                                description="Covers costs for rehabilitation and retraining of injured employees."
                            />
                        </Slider>

                    </div>
                    <ServiceBlock
                        title="Advantages of Workmen's Compensation Insurance"
                        subtitle=""
                        points={["Ensures employee well-being:", "Promotes workplace safety:", "Enhances employee morale and loyalty:","Complies with legal obligations:"]}
                        ctaLabel=""
                        ctaLink=""
                        image="/assets/img/do-advantage.png"
                        type="primary"
                        imageLocation="right"
                    />
                </section>
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color"> Who Needs Workmen's Compensation Insurance?</h1>
                        <p className="mt-5 content-regular">Workmen's Compensation Insurance is essential for all businesses, regardless of size or industry, to protect their employees and comply with legal requirements. It is particularly crucial for:</p>
                        <BSServiceBlock points={["Manufacturing companies:", "Construction firms:", "Healthcare providers:", "Retail businesses:", "Hospitality industry:","Educational institutions:", "Transportation and logistics companies:"]} />
                    </div>
                </section>
                <ServiceBlock
                    title="Factors Influencing Workmen's Compensation Insurance Coverage"
                    subtitle=""
                    points={["Nature of business operations:", "Number of employees:", "Industry risk level:", "Safety measures in place:", "History of workplace incidents:"]}
                    ctaLabel=""
                    ctaLink=""
                    image="/assets/img/do-coverage-factor.png"
                    type="primary"
                    imageLocation="right"
                />
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">Why Get Workmen's Compensation Insurance? </h1>
                        <p className="my-5 content-regular">Workmen's Compensation Insurance is crucial for protecting both employees and employers from the financial consequences of workplace injuries and illnesses. The policy provides:</p>
                        <BSServiceBlock points={["Medical coverage for injuries sustained on the job:","Compensation for lost wages during recovery:", "inancial support for temporary or permanent disabilities:", "Death benefits to support the family of a deceased employee:", "Rehabilitation and retraining support for injured employees:"]} />
                    </div>
                </section>
                <ServiceBlock
                    title="Who Can Benefit from Workmen's Compensation Insurance?"
                    subtitle=""
                    points={["Employees facing medical emergencies due to workplace accidents:", "Employers needing protection against legal claims and financial liabilities:", "Families of employees who suffer fatal work-related injuries:","Businesses aiming to enhance workplace safety and employee well-being:" ]}
                    ctaLabel=""
                    ctaLink=""
                    image="/assets/img/litigation.png"
                    type="primary"
                    imageLocation="right"
                />
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">Coverages & Exclusions in Workmen's Compensation Insurance</h1>
                        <section className="w3-section my-5">
                            <div className="d-flex flex-row">
                                <div onClick={() => setCoverage(true)} className={`mr-4 pointer ${isCoverage ? 'active-w3' : ''}`}>
                                    <p>Coverages</p>
                                </div>
                                <div onClick={() => setCoverage(false)} className={`pointer ${!isCoverage ? 'active-w3' : ''}`}>
                                    <p>Exclusions</p>
                                </div>
                            </div>
                        </section>
                        <div>
                            {isCoverage && (
                                <p>Workmen's Compensation Insurance provides extensive coverage to protect employees and employers from the financial impact of work-related incidents. Key coverages include:</p>
                            )}
                            {!isCoverage && (
                                <p>Workmen's Compensation Insurance typically excludes certain situations, including:</p>
                            )}

                        </div>
                        {isCoverage && (
                            <div className="row">
                                {coverages.map((c, i) => (
                                    <div className="col-12 col-lg-6">
                                        <h5 className="title-primary-color my-3">{`${i + 1}. ${c.title}`}</h5>
                                        <p>{`${c.content}`}</p>
                                    </div>
                                ))}
                            </div>
                        )}
                        {!isCoverage && (
                            <div className="row">
                                {exclusion.map((c, i) => (
                                    <div className="col-12 col-lg-6">
                                        <h5 className="title-primary-color my-3">{`${i + 1}. ${c.title}`}</h5>
                                        <p>{`${c.content}`}</p>
                                    </div>
                                ))}
                            </div>
                        )}

                    </div>
                </section>
                <ClaimsExample 
                    title="Workmen's Compensation Claims Examples"
                    subtitle="Case of a Construction Worker Injury"
                    incident="A construction worker suffered a severe injury while operating heavy machinery on-site, leading to hospitalization and an extended recovery period."
                    resolution="The Workmen's Compensation Insurance policy covered the worker's medical expenses, provided wage replacement during the recovery, and supported rehabilitation efforts. The company avoided significant financial liability and maintained compliance with legal requirements."
                    image="/assets/img/claims-example.png"
                />
                {/* <section className="my-4">
                    <p className={`service-title title-primary-color text-center`}>{'Frequently Asked Questions'}</p>
                    <div className=" p-5">
                    <div id="accordion" className="px-lg-5 px-lg-80 p-5">
                        {faq.Workmen.map(f => (<Accordian faq={f} />))}
                    </div>
                </div>
                </section> */}
                <ServiceBlock
                    title="Why Choose Buckss for Workmen's Compensation Insurance?"
                    subtitle=""
                    points={
                        [
                            "Expertise:Over 50 years of combined experience in the insurance industry.",
                            "Tailored Solutions:Customized Workmen's Compensation Insurance plans to suit your business needs.",
                            "Dedicated Support:  Comprehensive support throughout the claims process.",
                            "Strong Partnerships: Collaborations with leading insurance providers to offer the best coverage options.",
                        ]
                    }
                    ctaLabel=""
                    ctaLink=""
                    image="/assets/img/insurance-agent-vector.png"
                    type="primary"
                    imageLocation="right"
                />
                <div className="mt-4">
                        <InsuranceServices />
                    </div>
               <div className="mt-4">
               <ProtectBusiness />
               </div>
            </div>
            <Footer />
        </>
    )
}

export default WorkmanCompensation