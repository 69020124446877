import React from 'react'
import Header from './components/Header'
import Footer from './components/Footer'
const PrivacyPolicy = () => {
    return (
        <>
            <div id="main_content">
                <Header />
                <section id="Team" class="light-wrapper">
                    <div class="container inner">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="title-section text-left">
                                    <p>Website </p>
                                    <h3 style={{color: '#0c59e2'}} className='text-center'>Privacy Policy</h3>
                                </div>
                                <div class="description-welcome text-left">
                                    <p>At Buckss Insurance Brokers Pvt Ltd, we take the security and privacy of customer data very seriously. We are dedicated to safeguarding the information shared with us for our services. Our Privacy Policy is designed to provide users with clear details about how we collect, use, and protect their data to offer our services
                                        and benefits through our platform. This policy applies to all users who use Buckss services across all our platforms.
                                    </p>
                                    <p>
                                        <strong>Data Collection: </strong>
                                            Buckss collects personal information from users to provide personalized services. This information can be related to insurance, online activities, or other interactions with the company. The data collected may include contact information, transaction details, and device information.
                                    </p>
                                    <p>
                                        <strong>Usage of Data: </strong>
                                            The collected data is primarily used to improve user experiences and provide services. This includes contacting users for product updates, reminders, and analysis to enhance the quality of services. Data may also be shared with partners and service providers for these purposes.
                                    </p>

                                    <p>
                                        <strong>Retention and Sharing: </strong>
                                            Buckss retains data for as long as necessary for service improvement and compliance with legal requirements. Sensitive personal data or information is protected and stored securely, even when transferred outside India. Buckss does not sell user data to third parties.
                                    </p>
                                    <p>
                                        <strong>Corrections/Updates: </strong>
                                            Users can request corrections to their personal information if errors are found. Buckss may charge a service fee for certain requests.
                                    </p>

                                    <p>
                                        <strong>Cookies and Logs: </strong>
                                            Buckss uses cookies to enhance user experiences, but users can choose to disable cookies in their browser settings. The company also uses tools for analytics and advertising.
                                    </p>

                                    <p>
                                        <strong>Links to Third-Party Websites: </strong>
                                            Buckss website may contain links to third-party sites, and users are encouraged to review the terms and conditions of those sites separately.
                                    </p>
                                    <p>
                                        <strong>Protection of Information: </strong>
                                            Buckss has security measures in place to protect user data from unauthorized access or disclosure. However, it's noted that internet transmissions may not be entirely secure.
                                    </p>

                                </div>
                            </div>
                        </div>
                        <div class="divcod55"></div>
                    </div>
                </section>
                <Footer />
            </div>
        </>
    )
}

export default PrivacyPolicy