import { pathOr } from "ramda";

const BSServiceBlock = (props) => {
    return (
        <div className="row">
            {props.points.map(p => {
                const splitTitle = p.split(":");
                let boldTitle = ""
                let regularTitle = ""
                if (splitTitle.length > 1) {
                    boldTitle = pathOr("", ["0"], splitTitle)
                    regularTitle = pathOr("", ["1"], splitTitle)
                } else {
                    boldTitle = ""
                    regularTitle = p
                }
                return (
                    <div className="d-flex col-12 col-lg-6">
                        <div>
                            <img src={`/assets/img/chevron-dark.png`} alt="" className="chevron" />
                        </div>
                        <div>
                            <p className={`service-points  black`}><span className="boldTitle">{boldTitle != "" ? boldTitle : ""}</span></p>
                            <p>{regularTitle}</p>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default BSServiceBlock;
