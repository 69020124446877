import { useState } from "react";
import Banner from "./components/Banner";
import Header from "./components/Header"
import insuranceTypeData from "./data/insuranceType";
import downloadLink from "./lib/Download";
import ServiceBlock from "./components/ServiceBlock";
import Footer from "./components/Footer";
import InsuranceServices from "./components/InsuranceServices";
import InsurerPartner from "./components/InsurancePartner";

const Services = () => {
    const [insuranceType, setInsuranceType] = useState("Business")
    const banner = {
        title: "Comprehensive Insurance Solutions Tailored to Your Needs",
        subtitle: "We specialize in offering tailored insurance solutions to institutions and corporations, and our founding team has over 20 years of experience.We specialize in offering tailored insurance solutions to institutions and corporations, and our founding team has over 20 years of experience.",
        ctaLabel: "Connect with us",
        ctaLink: '/contactus',
        image: "/assets/img/services.png",
        smallTitle: "OUR SERVICES"
    }
    const getInsuranceType = (index) => {
        return insuranceTypeData[index].type.map(data => (
            <div className="icon-container icon-box d-flex flex-column justify-content-center align-items-center">
                <div className="icon-wrapper">
                    <div className="icon-box">
                        <img className="" src={data.img} className="icon" />
                    </div>
                </div>
                <div className="white-box mt-2">
                    <p className="text-center break-all">{data.name}</p>
                </div>
            </div>
        ))
    }
    return (
        <>
            <Header />
            <Banner banner={banner} />
            <InsuranceServices />
            <InsurerPartner />
            <ServiceBlock
                title="Why Choose Buckss Insurance?"
                subtitle="Comprehensive suite of insurance products to protect you and your business."
                points={[
                    "Expertise: Our founding team has over 2 decades of industry experience.",
                    "Tailored Solutions: With our expertise, we provide personalized advice to help you choose the most suitable insurance plans.",
                    "Comprehensive Coverage: Access a wide range of insurance products from trusted insurance companies.",
                    "Personalized Support: Dedicated team providing end-to-end assistance."
                ]}
                ctaLabel=""
                ctaLink=""
                image="/assets/img/whoarewe.png"
                type="light"
                imageLocation="right"
            />
            <ServiceBlock
                title="Benefits of Choosing Buckss Insurance"
                subtitle=""
                points={[
                    "Customized Solutions: Insurance plans tailored to the specific needs of your institution.",
                    "Comprehensive Coverage: Our suite of business insurance products to help you protect your business.",
                    "Cost Efficiency: Helping you find the most cost-effective solutions without compromising coverage.",
                    "Unmatched Claims Support: Our team will assist you at every step of the claims process, ensuring quick and fair claim settlements."
                ]}
                ctaLabel=""
                ctaLink=""
                image="/assets/img/whoarewe.png"
                type="primary"
                imageLocation="left"
            />
             <ServiceBlock
                title="Solving MSME Insurance Needs"
                subtitle="Buckss Insurance understands the unique challenges faced by MSMEs in India. From limited awareness to mis-selling and trust issues, we are here to provide tailored insurance solutions that build a robust insurance infrastructure from scratch."
                points={[
                    
                ]}
                ctaLabel=""
                ctaLink=""
                image="/assets/img/msme.png"
                type="light"
                imageLocation="right"
            />
                  <div className="my-3">
        <p className="text-center service-title">Our Clients</p>

        <div className="my-5">
          <div className="d-flex flex-row justify-content-center">
            <div className="mx-3">
              <img src="/assets/img/excelsoft.png" className="client-logo" />
            </div>
            <div className="mx-3">
              <img src="/assets/img/rangsons.png" className="client-logo" />
            </div>
            <div className="mx-3">
              <img src="/assets/img/nrr.png" className="client-logo" />
            </div>
          </div>
          <div className="d-flex flex-row justify-content-center flex-wrap my-3">
          <div className="mx-3 justify-content-center align-items-center d-flex">
              <img src="/assets/img/ExcelPublicSchool.png" className="client-logo-square" />
            </div>
            <div className="mx-3 justify-content-center align-items-center d-flex">
              <img src="/assets/img/WorldOfSilver.png" className="client-logo-square" />
            </div>
            <div className="mx-3 justify-content-center align-items-center d-flex">
              <img src="/assets/img/MadanJewellers.png" className="client-logo-square" />
            </div>
            <div className="mx-3 justify-content-center align-items-center d-flex">
              <img src="/assets/img/Seshadripuram.png" className="client-logo-square" />
            </div>
          </div>
        </div>
      </div>
      <div className="row banner mx-lg-5 mx-4 my-4">
        <div className="col-12 col-lg-7 px-lg-5 carousel-slide">
          <p className="banner-smallTitle ">PROTECT YOUR BUSINESS</p>
          <p className="service-title">Get a Quick Insurance Quote</p>
          <p className="service-subtitle">Comprehensive suite of insurance benefits and insurance products to protect your team and your business.</p>
          <div>
            <div className="nav-item primary-button my-3 mr-3">
              <a className="nav-link" href="mailto:po@buckss.in">Get a Quote</a>
            </div>
            <div className="nav-item outline-button my-3 ml-3">
              <a className="nav-link" href="mailto:po@buckss.in">Contact Us</a>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-5">
          <img className="slider-img" src="/assets/img/get-quote.png" />
        </div>
      </div>
      <Footer />
        </>
    )
}

export default Services;