import { useState } from "react"
import Footer from "../components/Footer"
import Header from "../components/Header"
import InnerHero from "../components/InnerHero"
import Experience from "../components/Experience"
import Side from "../components/Side"
import ServiceBlock from "../components/ServiceBlock"
import { converge, pathOr } from "ramda"
import BSServiceBlock from "../components/BSServiceBlock"
import InsuranceServices from "../components/InsuranceServices"
import ProtectBusiness from "../components/ProtectBusiness"
import ClaimsExample from "../components/ClaimsExample"
import faq from "../data/faq"
import Accordian from "../components/Accordian"
import Slider from "react-slick"
import settings from "../lib/SliderSettings"

const DoctorsInsurance = () => {
    const [activeW, setActiveW] = useState('what')
    const [isCoverage, setCoverage] = useState(true)
    const coverages = [
        {
            title: "Professional Liability Coverage",
            content: "Protects against claims of errors, omissions, or negligence in professional services."
        },
        {
            title: "Defense Costs",
            content: "Covers legal expenses incurred in defending against claims."
        },
        {
            title: "Settlements and Judgments",
            content: "Provides funds to cover settlements or judgments awarded to clients."
        },
        {
            title: "Disciplinary Proceedings Coverage",
            content: "overs legal costs related to defending against disciplinary actions."
        },
        {
            title: "Lost Documents Coverage",
            content: "Covers the costs of reproducing documents lost or damaged during professional services."
        },
        {
            title: "Privacy and Data Breach Coverage",
            content: "Protects against claims related to data breaches and confidentiality issues"
        },
        {
            title: "Worldwide Coverage",
            content: "Provides protection for claims made anywhere in the world, subject to policy terms."
        }
    ]
    const exclusion = [
        {
            title: "Intentional Acts",
            content: "Claims arising from intentional, dishonest, or fraudulent acts"
        },
        {
            title: "Bodily Injury and Property Damage",
            content: "Claims related to physical injury or property damage."
        },
        {
            title: "Employment Practices",
            content: " Claims arising from employment-related issues, such as wrongful termination or discrimination."
        },
        {
            title: "Patents and Trade Secrets",
            content: "Claims related to infringement of patents or trade secrets."
        },
        {
            title: "Product Liability",
            content: "Claims related to defective products."
        },
        {
            title: "Contractual Liability",
            content: "Claims arising from liabilities assumed under contract, unless they would have existed in the absence of the contract."
        },
        {
            title: "Regulatory Violations",
            content: "Claims arising from violations of regulations or laws."
        }
    ]
    return (
        <>
            <Header />
            <div className="">
                <InnerHero
                    heading="Professional Indemnity for Doctors Insurance "
                    title="Protect your medical practice from legal claims and ensure peace of mind with our comprehensive Professional Indemnity Insurance for Doctors."
                    image="/assets/img/doctor-insurance-hero.png"
                />
                <Experience />
                <section className="w3-section my-5 px-lg-5 px-lg-80">
                    <div className="d-flex flex-row align-items-center justify-content-between px-4">
                        <div onClick={() => setActiveW('what')} className={`pointer ${activeW === 'what' ? 'active-w3' : ''}`}>
                            <p>What</p>
                        </div>
                        <div onClick={() => setActiveW('who')} className={`pointer ${activeW === 'who' ? 'active-w3' : ''}`}>
                            <p>Who</p>
                        </div>
                        <div onClick={() => setActiveW('why')} className={`pointer ${activeW === 'why' ? 'active-w3' : ''}`}>
                            <p>Why</p>
                        </div>
                        <div onClick={() => setActiveW('coverage')} className={`pointer ${activeW === 'coverage' ? 'active-w3' : ''}`}>
                            <p>Coverages & Exclusions</p>
                        </div>
                    </div>
                </section>
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">What is Professional Indemnity for Doctors Insurance?  </h1>
                        <p className="mt-5 content-regular">Professional Indemnity Insurance for Doctors is a specialized insurance policy designed to protect medical professionals against legal claims arising from their practice. It covers legal expenses, settlements, and damages awarded in lawsuits alleging malpractice, negligence, or errors and omissions in professional services.
                        </p>
                    </div>
                </section>
                <section className="coverage-section">
                    <div className="px-lg-5 px-lg-80">
                        <h1 className="title-primary-color mb-4">Key Coverage Areas of Professional Indemnity for Doctors</h1>
                        <Slider {...settings}>
                            <Side
                                heading="Malpractice Claims"
                                content=""
                                image="/assets/img/sideA.png"
                                description="Coverage for claims arising from professional negligence or failure to perform medical duties competently."
                            />
                            <Side
                                heading="Legal Defense Costs"
                                content=""
                                image="/assets/img/sideB.png"
                                description="Covers legal fees and expenses incurred in defending against malpractice claims."
                            />
                            <Side
                                heading="Settlements and Damages"
                                content=""
                                image="/assets/img/sideC.png"
                                description="Pays for settlements or damages awarded to claimants."
                            />
                             <Side
                                heading="Breach of Confidentiality"
                                content=""
                                image="/assets/img/sideC.png"
                                description="Covers claims related to the unauthorized disclosure of patient information."
                            />
                              <Side
                                heading="Libel and Slander"
                                content=""
                                image="/assets/img/sideC.png"
                                description="Protection against claims of defamation related to professional services."
                            />
                        </Slider>

                    </div>
                    <ServiceBlock
                        title="Advantages of Professional Indemnity for Doctors Insurance"
                        subtitle=""
                        points={["Protects personal and professional assets:", "Ensures financial stability in case of lawsuits:", "Enhances professional credibility and trust:","Provides peace of mind to focus on patient care:"]}
                        ctaLabel=""
                        ctaLink=""
                        image="/assets/img/doctor-insurance-advantage.png"
                        type="primary"
                        imageLocation="right"
                    />
                </section>
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">Who Needs Errors & Omissions Insurance?</h1>
                        <p className="mt-5 content-regular">E & O Insurance is essential for any business or professional providing advice, services, or solutions to clients. This includes</p>
                        <BSServiceBlock points={["Consultants and advisors:", "Lawyers and legal professionals:", "Accountants and financial planners:", "Real estate agents and brokers:", "IT service providers and software developers:","Marketing and advertising agencies:","Healthcare professionals:"]} />
                    </div>
                </section>
                <ServiceBlock
                    title="Factors Influencing E & O Policy Coverage"
                    subtitle=""
                    points={["Nature of services provided:", "Industry-specific risks:", "Size and scope of the business:", "Previous claims history:", "Level of client interaction:"]}
                    ctaLabel=""
                    ctaLink=""
                    image="/assets/img/do-coverage-factor.png"
                    type="primary"
                    imageLocation="right"
                />
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">Why Get Errors & Omissions Insurance</h1>
                        <p className="my-5 content-regular">E & O insurance is crucial for protecting businesses and professionals from financial losses due to legal claims. It covers a range of potential issues, including</p>
                        <BSServiceBlock points={["Allegations of professional negligence:","Errors in advice or service delivery:", "Misrepresentation or breach of contract:", "Failure to meet client expectations:", "Confidentiality breaches and data protection issues:"]} />
                    </div>
                </section>
                <ServiceBlock
                    title="Who Can File Claims Against Professionals?"
                    subtitle=""
                    points={["Clients and customers:", "Business partners:", "Regulatory authorities:",  "Third-party vendors:"]}
                    ctaLabel=""
                    ctaLink=""
                    image="/assets/img/litigation.png"
                    type="primary"
                    imageLocation="right"
                />
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">Coverages & Exclusions in E & O Insurance*</h1>
                        <section className="w3-section my-5">
                            <div className="d-flex flex-row">
                                <div onClick={() => setCoverage(true)} className={`mr-4 pointer ${isCoverage ? 'active-w3' : ''}`}>
                                    <p>Coverages</p>
                                </div>
                                <div onClick={() => setCoverage(false)} className={`pointer ${!isCoverage ? 'active-w3' : ''}`}>
                                    <p>Exclusions</p>
                                </div>
                            </div>
                        </section>
                        <div>
                            {isCoverage && (
                                <p>E & O insurance provides comprehensive protection against various risks. Key coverages include:</p>
                            )}
                            {!isCoverage && (
                                <p>Certain situations are excluded from E & O coverage, including:</p>
                            )}

                        </div>
                        {isCoverage && (
                            <div className="row">
                                {coverages.map((c, i) => (
                                    <div className="col-12 col-lg-6">
                                        <h5 className="title-primary-color my-3">{`${i + 1}. ${c.title}`}</h5>
                                        <p>{`${c.content}`}</p>
                                    </div>
                                ))}
                            </div>
                        )}
                        {!isCoverage && (
                            <div className="row">
                                {exclusion.map((c, i) => (
                                    <div className="col-12 col-lg-6">
                                        <h5 className="title-primary-color my-3">{`${i + 1}. ${c.title}`}</h5>
                                        <p>{`${c.content}`}</p>
                                    </div>
                                ))}
                            </div>
                        )}

                    </div>
                </section>
                <ClaimsExample 
                    title=" Errors & Omissions Claims Examples"
                    subtitle="Case of Miscommunication Leading to Financial Loss"
                    incident="A financial advisor provided incorrect investment advice, resulting in significant financial losses for a client."
                    resolution="The client sued the advisor for negligence. The E&O policy covered the legal defense costs and the settlement awarded to the client, ensuring the advisor’s financial stability and professional reputation were preserved."
                    image="/assets/img/claims-example.png"
                />
                {/* <section className="my-4">
                    <p className={`service-title title-primary-color text-center`}>{'Frequently Asked Questions'}</p>
                    <div className=" p-5">
                    <div id="accordion" className="px-lg-5 px-lg-80 p-5">
                        {faq.do.map(f => (<Accordian faq={f} />))}
                    </div>
                </div>
                </section> */}
                <ServiceBlock
                    title="Why Choose Buckss for E & O Insurance?"
                    subtitle=""
                    points={
                        [
                            "Expertise:Leveraging over 50 years of combined experience in the insurance industry.",
                            "Customized Solutions:Tailored insurance plans to meet your company’s specific needs.",
                            "Dedicated Support:Comprehensive support throughout the claims process.",
                            "Top Partnerships:Strong relationships with leading insurance providers for optimal coverage.",
                        ]
                    }
                    ctaLabel=""
                    ctaLink=""
                    image="/assets/img/insurance-agent-vector.png"
                    type="primary"
                    imageLocation="right"
                />
                <div className="mt-4">
                        <InsuranceServices />
                    </div>
               <div className="mt-4">
               <ProtectBusiness />
               </div>
            </div>
            <Footer />
        </>
    )
}

export default DoctorsInsurance