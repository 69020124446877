const ClaimsExample = (props) => (
    <section className="">
        <div className={"primaryBox row px-lg-5 px-lg-80 p-5"} id="know-more">
            <div className="col-12 col-lg-6 px-4 my-3 d-flex flex-column justify-content-center">
                <p className={`service-title black`}>{props.title}</p>
                <p className="service-subtitle title-primary-color">{props.subtitle}</p>
                <div>
                    <p className="title-primary-color">Incident</p>
                    <p>{props.incident}</p>
                </div>
                <div>
                    <p className="title-primary-color">Resolution</p>
                    <p>{props.resolution}</p>
                </div>
            </div>
            <div className="col-12 col-lg-6 my-3 d-flex align-items-center justify-content-center">
                <img src={props.image} className="slider-img" alt="" />
            </div>
        </div>
    </section>
)

export default ClaimsExample