import { useState } from "react"
import Slider from "react-slick"
import insurer from "../data/insurer"

const InsurerPartner = () => {
    const [insurerType, setInsurerType] = useState("Life")
    const NextArrow  = (props) => {
        const { className, style, onClick } = props;
        return (
          <img src="/assets/img/circle-chevron-right.png" className={`${className} icon`} onClick={onClick} />
        );
      }
      
      const PrevArrow  = (props) => {
        const { className, style, onClick } = props;
        return (
          <img src="/assets/img/circle-chevron-left.png" className={`${className} icon`} onClick={onClick} />
        );
      }
    var settings = {
        infinite: true,
        speed: 100,
        slidesToShow: 6,
        slidesToScroll: 1,
        initialSlide: 0,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        autoplay: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 6,
              slidesToScroll: 6,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          }
        ]
      };
      
    return(
        <div>
        <p className="text-center primary-bold">OUR PARTNERS</p>
        <p className="text-center service-title">We associated with India's popular insurance companies.</p>
        <div className="my-3 px-lg-5 px-lg-80 p-5">
          <div className="d-flex flex-row justify-content-center">
            <div onClick={() => { setInsurerType("Life") }} className={`mx-3 tab ${insurerType === 'Life' ? 'activeBorder' : ""} `}>
              <span>Life Insurance</span>
            </div>
            <div onClick={() => { setInsurerType("Health") }} className={`mx-3 tab ${insurerType === 'Health' ? 'activeBorder' : ""} `}>
              <span>Health Insurance</span>
            </div>
            <div onClick={() => { setInsurerType("General") }} className={`mx-3 tab ${insurerType === 'General' ? 'activeBorder' : ""} `}>
              <span>General Insurance</span>
            </div>
          </div>
          <Slider {...settings}>
            {insurer.filter(i => i.type === insurerType).map(i => (
              <div className="insurer-box">
                <div className="d-flex justify-content-center align-items-center insurer-logo-box">
                  <img src={i.img} alt="" className="insurer-icon" />
                </div>
                
              </div>
            ))}
          </Slider>
        </div>
      </div>
    )
}

export default InsurerPartner;
