import { useState } from "react"
import Footer from "../components/Footer"
import Header from "../components/Header"
import InnerHero from "../components/InnerHero"
import Experience from "../components/Experience"
import Side from "../components/Side"
import ServiceBlock from "../components/ServiceBlock"
import BSServiceBlock from "../components/BSServiceBlock"
import InsuranceServices from "../components/InsuranceServices"
import ProtectBusiness from "../components/ProtectBusiness"
import ClaimsExample from "../components/ClaimsExample"
import faq from "../data/faq"
import Accordian from "../components/Accordian"
import Slider from "react-slick"
import settings from "../lib/SliderSettings"

const CommercialVehicleInsurance = () => {
    const [activeW, setActiveW] = useState('what')
    const [isCoverage, setCoverage] = useState(true)

    const coverages = [
        {
            title: "Accidental Damage",
            content: "Covers costs of repairs or replacement if the vehicle is damaged in an accident."
        },
        {
            title: "Theft Protection",
            content: "Compensation for the loss of a vehicle due to theft."
        },
        {
            title: "Third-Party Liability",
            content: "Covers legal liabilities for damages or injuries caused to third parties."
        },
        {
            title: "Natural Calamities",
            content: "Covers damages caused by natural disasters such as floods, storms, and earthquakes."
        },
        {
            title: "Fire Damage:",
            content: "Protection against damage due to fire or explosion."
        },
        {
            title: "Personal Accident Cover",
            content: "Compensation for injuries to the driver or owner"
        },
        {
            title: "Roadside Assistance",
            content: "24/7 support for breakdowns, towing, and minor repairs."
        },
        {
            title: "IPO-related Claims",
            content: "Covers costs incurred during investigations related to the directors' or officers' duties."
        },
        {
            title: "Uninsured Motorist Protection",
            content: " Covers damages caused by uninsured or underinsured drivers."
        },
    ]
    const exclusion = [
        {
            title: "Wear and Tear",
            content: "Routine wear and tear or mechanical breakdowns are not covered."
        },
        {
            title: "Drunk Driving",
            content: "Accidents occurring while the driver is under the influence of alcohol or drugs."
        },
        {
            title: "Unlicensed Drivers",
            content: "Incidents involving drivers without a valid commercial driving license."
        },
        {
            title: "Illegal Activities",
            content: "Damages incurred while the vehicle is used for illegal purposes."
        },
        {
            title: "War and Nuclear Risks",
            content: "Damages due to war, nuclear risks, or related events."
        },
        {
            title: "Insolvency Issues",
            content: "Reimburses costs related to kidnapping incidents involving directors or officers."
        },
        {
            title: "War and Hostilities",
            content: "overs expenses associated with self-reporting potential legal issues to authorities."
        },
        {
            title: "Contractual Liability",
            content: "Liabilities assumed under any contract or agreement."
        },
        {
            title: "Usage Beyond Limits",
            content: "Damages from using the vehicle outside the geographic limits or for unauthorized purposes"
        }
    ]
    return (
        <>
            <Header />
            <div className="">
                <InnerHero
                    heading="Commercial Vehicle Insurance"
                    title="Secure your business vehicles with Buckss Commercial Vehicle Insurance. Comprehensive coverage for your fleet to keep your operations running smoothly. "
                    image="/assets/img/do.png"
                />
                <Experience />
                <section className="w3-section my-5 px-lg-5 px-lg-80">
                    <div className="d-flex flex-row align-items-center justify-content-between px-4">
                        <div onClick={() => setActiveW('what')} className={`pointer ${activeW === 'what' ? 'active-w3' : ''}`}>
                            <p>What</p>
                        </div>
                        <div onClick={() => setActiveW('who')} className={`pointer ${activeW === 'who' ? 'active-w3' : ''}`}>
                            <p>Who</p>
                        </div>
                        <div onClick={() => setActiveW('why')} className={`pointer ${activeW === 'why' ? 'active-w3' : ''}`}>
                            <p>Why</p>
                        </div>
                        <div onClick={() => setActiveW('coverage')} className={`pointer ${activeW === 'coverage' ? 'active-w3' : ''}`}>
                            <p>Coverages & Exclusions</p>
                        </div>
                    </div>
                </section>
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">What Is Commercial Vehicle Insurance?</h1>
                        <p className="mt-5 content-regular">Commercial Vehicle Insurance provides coverage for vehicles used for business purposes. This insurance protects against damages and losses due to accidents, theft, natural disasters, and third-party liabilities, ensuring that your business operations are not disrupted by unforeseen events.</p>
                    </div>
                </section>
                <section className="coverage-section">
                    <div className="px-lg-5 px-lg-80">
                        <h1 className="title-primary-color mb-4">Key Features of Commercial Vehicle Insurance</h1>
                        <Slider {...settings}>
                            
                                
                                    <Side
                                        heading="Side A"
                                        content="Damage Protection"
                                        image="/assets/img/sideA.png"
                                        description="Covers repair or replacement costs for vehicles damaged in accidents or natural disasters."
                                    />
                                
                                
                                    <Side
                                        heading="Side B"
                                        content="Theft Coverage"
                                        image="/assets/img/sideB.png"
                                        description="Provides compensation if a commercial vehicle is stolen."
                                    />
                                
                                    <Side
                                        heading="Side C"
                                        content="Third-Party Liability"
                                        image="/assets/img/sideC.png"
                                        description="Covers legal liabilities arising from damage or injury caused to third parties."
                                    />

<Side
                                        heading="Side D"
                                        content="24/7 Roadside Assistance:"
                                        image="/assets/img/sideC.png"
                                        description="Access to emergency services like towing, fuel delivery, and repair."
                                    />

<Side
                                        heading="Side E"
                                        content="Personal Accident Cover"
                                        image="/assets/img/sideC.png"
                                        description="Provides compensation for injuries to the driver or owner"
                                    />
                                
                            
                    
                </Slider>

            </div>
        </section >
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color"> Who Needs Commercial Vehicle Insurance?                        </h1>
                        <p className="mt-5 content-regular">Commercial Vehicle Insurance is essential for any business that uses vehicles for its operations. This insurance is particularly beneficial for: Logistics and transportation companies, Delivery and courier services, Construction businesses with heavy machinery, Companies with sales or service fleets, Small businesses with vehicles used for commercial purposes                        </p>
                    </div>
                </section>
                <ServiceBlock
                    title="Factors Influencing Commercial Vehicle Insurance Coverage"
                    subtitle=""
                    points={["Type and number of vehicles","Usage and distance covered", "Driver experience and records", "Nature of goods transported", "Business location and operational risks"]}
                    ctaLabel=""
                    ctaLink=""
                    image="/assets/img/do-coverage-factor.png"
                    type="primary"
                    imageLocation="right"
                />
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">Why Get Commercial Vehicle Insurance?                        </h1>
                        <p className="my-5 content-regular">Commercial Vehicle Insurance provides essential protection for business vehicles. Key reasons to opt for this coverage include:</p>
                        <BSServiceBlock points={["Financial Protection:Covers repair and replacement costs, reducing financial strain.", "Legal Compliance:Ensures compliance with legal requirements for commercial vehicles.", "Minimized Downtime:Quick claims processing and repairs to keep vehicles operational.", "Employee Safety:Protects drivers and employees using the vehicles.", "Asset Protection:Safeguards valuable business assets from unexpected losses."]} />
                    </div>
                </section>
                <ServiceBlock
                    title="Common Risks Covered by Commercial Vehicle Insurance:"
                    subtitle=""
                    points={["Road accidents:","Theft and vandalism:", "Fire and explosions:", "Natural disasters like floods and storm:", "Third-party injuries and property damage:"]}
                    ctaLabel=""
                    ctaLink=""
                    image="/assets/img/litigation.png"
                    type="primary"
                    imageLocation="right"
                />
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">Coverages & Exclusions in D&O Insurance*</h1>
                        <section className="w3-section my-5">
                            <div className="d-flex flex-row">
                                <div onClick={() => setCoverage(true)} className={`mr-4 pointer ${isCoverage ? 'active-w3' : ''}`}>
                                    <p>Coverages</p>
                                </div>
                                <div onClick={() => setCoverage(false)} className={`pointer ${!isCoverage ? 'active-w3' : ''}`}>
                                    <p>Exclusions</p>
                                </div>
                            </div>
                        </section>
                        <div>
                            {isCoverage && (
                                <p>Commercial Vehicle Insurance provides extensive coverage to protect business vehicles. Key coverages include:</p>
                            )}
                            {!isCoverage && (
                                <p>While Commercial Vehicle Insurance offers comprehensive coverage, some exclusions typically include:</p>
                            )}

                        </div>
                        {isCoverage && (
                            <div className="row">
                                {coverages.map((c, i) => (
                                    <div className="col-12 col-lg-6">
                                        <h5 className="title-primary-color my-3">{`${i + 1}. ${c.title}`}</h5>
                                        <p>{`${c.content}`}</p>
                                    </div>
                                ))}
                            </div>
                        )}
                        {!isCoverage && (
                            <div className="row">
                                {exclusion.map((c, i) => (
                                    <div className="col-12 col-lg-6">
                                        <h5 className="title-primary-color my-3">{`${i + 1}. ${c.title}`}</h5>
                                        <p>{`${c.content}`}</p>
                                    </div>
                                ))}
                            </div>
                        )}

                    </div>
                </section>
                <ClaimsExample
                    title="Commercial Vehicle Insurance Claims Examples"
                    subtitle="Delivery Van Accident"
                    incident="A delivery van was involved in a collision with another vehicle, causing significant damage to both vehicles and minor injuries to the driver."
                    resolution="The Commercial Vehicle Insurance policy covered the repair costs for the van, medical expenses for the driver, and third-party liability for the damages to the other vehicle, ensuring the business operations continued without major financial impact."
                    image="/assets/img/claims-example.png"
                />
                {/* <section className="my-4">
                    <p className={`service-title title-primary-color text-center`}>{'Frequently Asked Questions'}</p>
                    <div className=" p-5">
                        <div id="accordion" className="px-lg-5 px-lg-80 p-5">
                            {faq.do.map(f => (<Accordian faq={f} />))}
                        </div>
                    </div>
                </section> */}
                <ServiceBlock
                    title="Why Choose Buckss for D&O Insurance?"
                    subtitle=""
                    points={
                        [
                            "Expertise:Leveraging over 50 years of combined experience in the insurance industry.",
                            "Tailored Solutions:Customized Commercial Vehicle Insurance plans to suit your business needs.",
                            "Dedicated Support:Comprehensive support throughout the claims process.",
                            "Top Partnerships:Strong relationships with leading insurance providers for optimal coverage.",
                        ]
                    }
                    ctaLabel=""
                    ctaLink=""
                    image="/assets/img/insurance-agent-vector.png"
                    type="primary"
                    imageLocation="right"
                />
                <div className="mt-4">
                    <InsuranceServices />
                </div>
                <div className="mt-4">
                    <ProtectBusiness />
                </div>
            </div >
    <Footer />
        </>
    )
}

export default CommercialVehicleInsurance