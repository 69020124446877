import { useState } from "react"
import Footer from "../components/Footer"
import Header from "../components/Header"
import InnerHero from "../components/InnerHero"
import Experience from "../components/Experience"
import Side from "../components/Side"
import ServiceBlock from "../components/ServiceBlock"
import { converge, pathOr } from "ramda"
import BSServiceBlock from "../components/BSServiceBlock"
import InsuranceServices from "../components/InsuranceServices"
import ProtectBusiness from "../components/ProtectBusiness"
import ClaimsExample from "../components/ClaimsExample"
import faq from "../data/faq"
import Accordian from "../components/Accordian"
import Slider from "react-slick"
import settings from "../lib/SliderSettings"

const GeneralLiability = () => {
    const [activeW, setActiveW] = useState('what')
    const [isCoverage, setCoverage] = useState(true)
    const coverages = [
        {
            title: "Bodily Injury Liability",
            content: "Covers medical expenses, legal fees, and compensation for injuries sustained by third parties on your business premises."
        },
        {
            title: "Property Damage Liability",
            content: "Covers repair or replacement costs for third-party property damaged by your business operations."
        },
        {
            title: "Personal and Advertising Injury Liability",
            content: "Protects against claims of libel, slander, and copyright infringement arising from your business activities."
        },
        {
            title: "Medical Payments Coverage",
            content: "Pays for medical expenses incurred by injured third parties, regardless of fault."
        },
        {
            title: "Legal Defense Costs",
            content: "Covers legal fees and expenses related to defending your business against covered claims."
        },
        {
            title: "Tenant’s Legal Liability",
            content: "Covers damages to premises rented or leased to your business due to covered perils."
        }
        
    ]
    const exclusion = [
        {
            title: "Intentional Acts",
            content: "Claims arising from intentional, dishonest, or fraudulent acts."
        },
        {
            title: "Professional Errors",
            content: " Claims related to professional services errors, which require Professional Liability Insurance."
        },
        {
            title: "Employee Injuries",
            content: "Claims related to employee injuries, which require Workers' Compensation Insurance."
        },
        {
            title: "Automobile Liability",
            content: "Claims related to vehicle use, which require Commercial Auto Insurance."
        },
        {
            title: "Pollution Liability",
            content: "Claims arising from pollution or environmental damage."
        },
        {
            title: "Product Liability",
            content: "Claims related to defective products, which require Product Liability Insurance."
        },
        {
            title: "Contractual Liability",
            content: "Claims arising from liabilities assumed under contract, unless they would have existed in the absence of the contract."
        },
        {
            title: "Liquor Liability",
            content: "Claims related to serving alcohol, which require Liquor Liability Insurance."
        }
    ]
    return (
        <>
            <Header />
            <div className="">
                <InnerHero
                    heading="General Liability Insurance"
                    title="Shield your business from unexpected risks and legal claims. Protect your assets with comprehensive General Liability Insurance."
                    image="/assets/img/product-liability-hero.png"
                />
                <Experience />
                <section className="w3-section my-5 px-lg-5 px-lg-80">
                    <div className="d-flex flex-row align-items-center justify-content-between px-4">
                        <div onClick={() => setActiveW('what')} className={`pointer ${activeW === 'what' ? 'active-w3' : ''}`}>
                            <p>What</p>
                        </div>
                        <div onClick={() => setActiveW('who')} className={`pointer ${activeW === 'who' ? 'active-w3' : ''}`}>
                            <p>Who</p>
                        </div>
                        <div onClick={() => setActiveW('why')} className={`pointer ${activeW === 'why' ? 'active-w3' : ''}`}>
                            <p>Why</p>
                        </div>
                        <div onClick={() => setActiveW('coverage')} className={`pointer ${activeW === 'coverage' ? 'active-w3' : ''}`}>
                            <p>Coverages & Exclusions</p>
                        </div>
                    </div>
                </section>
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">What Is General Liability Insurance?</h1>
                        <p className="mt-5 content-regular">General Liability Insurance provides coverage for businesses against claims of bodily injury, property damage, and personal or advertising injury. It is essential for protecting the financial health of your business from lawsuits and unforeseen events.
                        </p>
                    </div>
                </section>
                <section className="coverage-section">
                    <div className="px-lg-5 px-lg-80">
                        <h1 className="title-primary-color mb-4">Key Coverage Areas of General Liability Insurance</h1>
                        <Slider {...settings}>
                            <Side
                                heading="Bodily Injury Liability"
                                content=""
                                image="/assets/img/sideA.png"
                                description="Covers medical expenses, legal fees, and damages if someone is injured on your business premises or due to your business operations."
                            />
                            <Side
                                heading="Property Damage Liability"
                                content=""
                                image="/assets/img/sideB.png"
                                description="Covers costs related to the damage or destruction of third-party property caused by your business operations."
                            />
                            <Side
                                heading="Personal and Advertising Injury"
                                content=""
                                image="/assets/img/sideC.png"
                                description="Covers claims of libel, slander, copyright infringement, and misappropriation of advertising ideas."
                            />
                             <Side
                                heading="Legal Defense Cost"
                                content=""
                                image="/assets/img/sideC.png"
                                description="Provides coverage for legal defense expenses in case of lawsuits."
                            />
                        </Slider>

                    </div>
                    <ServiceBlock
                        title="Advantages of General Liability Insurance"
                        subtitle=""
                        points={["Protects against costly legal claims:", "Preserves business reputation:", "Essential for client trust and retention:","Provides peace of mind for business owners:"]}
                        ctaLabel=""
                        ctaLink=""
                        image="/assets/img/do-advantage.png"
                        type="primary"
                        imageLocation="right"
                    />
                </section>
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">Who Needs General Liability Insurance?</h1>
                        <p className="mt-5 content-regular">General Liability Insurance is vital for any business, regardless of size or industry. It is particularly important for</p>
                        <BSServiceBlock points={["Retailers and shops:", "Restaurants and cafes:", "Contractors and construction businesses:", "Professional services firms:", "Wholesalers and warehouses:","Healthcare providers:"]} />
                    </div>
                </section>
                <ServiceBlock
                    title="Factors Influencing General Liability Policy Coverage"
                    subtitle=""
                    points={["Nature of business operations:", "Industry-specific risks:", "Business size and revenue:", "Claims history:", "Level of customer interaction:"]}
                    ctaLabel=""
                    ctaLink=""
                    image="/assets/img/do-coverage-factor.png"
                    type="primary"
                    imageLocation="right"
                />
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">Why Get General Liability Insurance?</h1>
                        <p className="my-5 content-regular">General Liability Insurance is crucial for protecting businesses from financial losses due to legal claims. It covers a range of potential issues, including:</p>
                        <BSServiceBlock points={["Injuries sustained by third parties on business premises:","Property damage caused by business operations:", "Allegations of defamation, slander, and libel:", "Claims of false advertising or copyright infringement:", "Legal defense costs in case of lawsuits:"]} />
                    </div>
                </section>
                <ServiceBlock
                    title="Who Can File Claims Against Your Business?"
                    subtitle=""
                    points={["Customers and clients:", "Vendors and suppliers:", "Business partners:",  "Regulatory authorities:", "Third-party individuals:" ]}
                    ctaLabel=""
                    ctaLink=""
                    image="/assets/img/litigation.png"
                    type="primary"
                    imageLocation="right"
                />
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">Coverages & Exclusions in General Liability Insurance</h1>
                        <section className="w3-section my-5">
                            <div className="d-flex flex-row">
                                <div onClick={() => setCoverage(true)} className={`mr-4 pointer ${isCoverage ? 'active-w3' : ''}`}>
                                    <p>Coverages</p>
                                </div>
                                <div onClick={() => setCoverage(false)} className={`pointer ${!isCoverage ? 'active-w3' : ''}`}>
                                    <p>Exclusions</p>
                                </div>
                            </div>
                        </section>
                        <div>
                            {isCoverage && (
                                <p>General Liability Insurance offers comprehensive protection against various risks. Key coverages include</p>
                            )}
                            {!isCoverage && (
                                <p>Certain situations are excluded from General Liability coverage, including</p>
                            )}

                        </div>
                        {isCoverage && (
                            <div className="row">
                                {coverages.map((c, i) => (
                                    <div className="col-12 col-lg-6">
                                        <h5 className="title-primary-color my-3">{`${i + 1}. ${c.title}`}</h5>
                                        <p>{`${c.content}`}</p>
                                    </div>
                                ))}
                            </div>
                        )}
                        {!isCoverage && (
                            <div className="row">
                                {exclusion.map((c, i) => (
                                    <div className="col-12 col-lg-6">
                                        <h5 className="title-primary-color my-3">{`${i + 1}. ${c.title}`}</h5>
                                        <p>{`${c.content}`}</p>
                                    </div>
                                ))}
                            </div>
                        )}

                    </div>
                </section>
                <ClaimsExample 
                    title=" General Liability Claims Examples"
                    subtitle="Case of Customer Slip and Fall"
                    incident="A customer slipped and fell on a wet floor in a retail store, resulting in a broken arm and medical expenses."
                    resolution="The customer sued the store for negligence. The General Liability policy covered the medical expenses and legal fees, protecting the store from significant financial loss and ensuring the customer's expenses were reimbursed."
                    image="/assets/img/claims-example.png"
                />
               {/*  <section className="my-4">
                    <p className={`service-title title-primary-color text-center`}>{'Frequently Asked Questions'}</p>
                    <div className=" p-5">
                    <div id="accordion" className="px-lg-5 px-lg-80 p-5">
                        {faq.GeneralLiability.map(f => (<Accordian faq={f} />))}
                    </div>
                </div>
                </section> */}
                <ServiceBlock
                    title="Why Choose Buckss for General Liability Insurance? "
                    subtitle=""
                    points={
                        [
                            "Expertise: Over 50 years of combined experience in the insurance industry.",
                            "Tailored Solutions: Customized General Liability insurance plans to suit your business needs.",
                            "Dedicated Support: Comprehensive support throughout the claims process.",
                            "Strong Partnerships: SCollaborations with leading insurance providers to offer the best coverage options.",
                        ]
                    }
                    ctaLabel=""
                    ctaLink=""
                    image="/assets/img/insurance-agent-vector.png"
                    type="primary"
                    imageLocation="right"
                />
                <div className="mt-4">
                        <InsuranceServices />
                    </div>
               <div className="mt-4">
               <ProtectBusiness />
               </div>
            </div>
            <Footer />
        </>
    )
}

export default GeneralLiability