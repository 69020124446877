
import { useState } from "react";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Swal from 'sweetalert2'
function ContactUs() {
    const initialInfo = {
        firstName: "",
        lastName: "",
        email: "",
        mobileNumber: "",
        message: ""
    }
    const initialError = {
        firstName: {
            hasError: true,
            message: ""
        },
        lastName: {
            hasError: false,
            message: ""
        },
        email: {
            hasError: true,
            message: ""
        },
        mobileNumber: {
            hasError: true,
            message: ""
        },
        message: {
            hasError: true,
            message: ""
        },
    }
    const [error, setError] = useState(initialError)
    const [info, setInfo] = useState(initialInfo)

    const canProceed = () => {
        const flatError = Object.values(error).flat();
        console.log(error)
        const isInValid = flatError.every(e => e.hasError === false);
        return !isInValid;
    };


    const handleSubmit = () => {
        let timerInterval;
        Swal.fire({
            title: "Thank you!",
            html: "Your request has been submitted",
            timer: 2000,
            didOpen: () => {
                Swal.showLoading();
                const timer = Swal.getPopup().querySelector("b");
                timerInterval = setInterval(() => {
                    timer.textContent = `${Swal.getTimerLeft()}`;
                }, 100);
            },
            willClose: () => {
                clearInterval(timerInterval);
            }
        }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
                console.log("I was closed by the timer");
            }
        });
    }

    return (
        <div>
            <Header />
            <div className="contact-box">
                <p className="contact-title text-center">Contact Us</p>
                <p className="contact-subtitle text-center">We're here to help you with any questions or concerns.</p>
            </div>
            <div className="row">
                <div className="col-12 col-lg-6 p-5">
                    <form className="p-5 form-box">
                        <div class="form-row my-3">
                            <div class="col">
                                <label for="firstName">First Name</label>
                                <input onChange={(event) => {
                                    if (new RegExp("^[a-zA-Z ]{3,}$").test(event.target.value)) {
                                        setInfo({
                                            ...info,
                                            firstName: event.target.value
                                        })
                                        setError({
                                            ...error,
                                            firstName: {
                                                hasError: false,
                                                message: ""
                                            }
                                        })

                                    } else {
                                        setError({
                                            ...error,
                                            firstName: {
                                                hasError: true,
                                                message: ""
                                            }
                                        })
                                    }
                                }} type="text"  class="form-control" placeholder="First name" />
                            </div>
                            <div class="col">
                                <label for="lastName">Last Name</label>
                                <input onChange={(event) => {

                                    setInfo({
                                        ...info,
                                        lastName: event.target.value
                                    })
                                    setError({
                                        ...error,
                                        lastName: {
                                            hasError: false,
                                            message: ""
                                        }
                                    })


                                }} type="text"  class="form-control" placeholder="Last name" />
                            </div>
                        </div>
                        <div class="form-row my-3">
                            <div class="col">
                                <label for="email">Email</label>
                                <input onChange={(event) => {
                                    if (new RegExp("^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$").test(event.target.value)) {
                                        setInfo({
                                            ...info,
                                            email: event.target.value
                                        })
                                        setError({
                                            ...error,
                                            email: {
                                                hasError: false,
                                                message: ""
                                            }
                                        })

                                    } else {
                                        setError({
                                            ...error,
                                            email: {
                                                hasError: true,
                                                message: ""
                                            }
                                        })
                                    }
                                }} type="text"  class="form-control" placeholder="Email" />
                            </div>
                            <div class="col">
                                <label for="mobileNumber">Mobile number</label>
                                <input onChange={(event) => {
                                    if (new RegExp("^[6789][0-9]{9}").test(event.target.value)) {
                                        setInfo({
                                            ...info,
                                            mobileNumber: event.target.value
                                        })
                                        setError({
                                            ...error,
                                            mobileNumber: {
                                                hasError: false,
                                                message: ""
                                            }
                                        })

                                    } else {
                                        setError({
                                            ...error,
                                            mobileNumber: {
                                                hasError: true,
                                                message: ""
                                            }
                                        })
                                    }
                                }} type="text"  class="form-control" placeholder="Mobile number" />
                            </div>
                        </div>
                        <div class="form-row my-3">
                            <div class="col">
                                <label for="mobileNumber">Message</label>
                                <textarea onChange={(event) => {
                                    if (new RegExp("[a-zA-Z0-9 ]{3,}").test(event.target.value)) {
                                        setInfo({
                                            ...info,
                                            message: event.target.value
                                        })
                                        setError({
                                            ...error,
                                            message: {
                                                hasError: false,
                                                message: ""
                                            }
                                        })

                                    } else {
                                        setError({
                                            ...error,
                                            message: {
                                                hasError: true,
                                                message: ""
                                            }
                                        })
                                    }
                                }} type="text"  class="form-control" placeholder="" rows={5} />
                            </div>
                        </div>
                        <div className="form-row my-3">
                            <small>I acknowledge that I have read and accepted the <a href="/terms">Terms of Use</a> and the <a href="/privacy">Privacy Policy</a> regarding
                                the processing of my personal data.</small>
                        </div>
                        <div className="form-row my-3 d-flex justify-content-center">
                            <input onClick={() => handleSubmit()} disabled={canProceed()} type="button" className="btn btn-primary" value="Send Message" />
                        </div>
                    </form>
                </div>
                <div className="col-12 col-lg-6 contactus-box p-5">
                    <div>
                        <p className="contactus-title">Do you have questions?</p>
                    </div>
                    <div>
                        <img src="/assets/img/call-center.png" alt="" className="callcenter-img" />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default ContactUs;
