import { useState } from "react"
import Footer from "../components/Footer"
import Header from "../components/Header"
import InnerHero from "../components/InnerHero"
import Experience from "../components/Experience"
import Side from "../components/Side"
import ServiceBlock from "../components/ServiceBlock"
import { converge, pathOr } from "ramda"
import BSServiceBlock from "../components/BSServiceBlock"
import InsuranceServices from "../components/InsuranceServices"
import ProtectBusiness from "../components/ProtectBusiness"
import ClaimsExample from "../components/ClaimsExample"
import faq from "../data/faq"
import Accordian from "../components/Accordian"
import Slider from "react-slick"
import settings from "../lib/SliderSettings"

const IndemnityInsurance = () => {
    const [activeW, setActiveW] = useState('what')
    const [isCoverage, setCoverage] = useState(true)
    const coverages = [
        {
            title: "Medical Malpractice Liability",
            content: "Covers claims related to professional negligence or errors in medical services."
        },
        {
            title: "Legal Defense Costs:",
            content: "Covers legal fees and expenses incurred in defending against covered claims."
        },
        {
            title: "Public Relations Cover:",
            content: "Covers costs associated with managing reputation damage resulting from a claim."
        },
        {
            title: "Loss of Documents",
            content: "Covers costs for restoring or replacing lost or damaged patient records."
        },
        {
            title: "Breach of Confidentiality",
            content: "Covers claims arising from unauthorized disclosure of patient information."
        },
        {
            title: "Court Attendance Costs",
            content: "Covers expenses incurred for attending court proceedings related to a claim."
        },
        {
            title: "Good Samaritan Acts:",
            content: "Covers claims arising from voluntary medical assistance provided in emergencies"
        },
        {
            title: "Libel and Slander:",
            content: "Covers claims of defamation related to professional services."
        },
    ]
    const exclusion = [
        {
            title: "Intentional Acts:",
            content: "Claims arising from intentional, dishonest, or fraudulent acts."
        },
        {
            title: "Criminal Acts:",
            content: "Claims related to criminal activities or conduct."
        },
        {
            title: "ERISA Violations",
            content: "Covers the cost of managing public relations and mitigating reputation damage."
        },
        {
            title: "Sexual Misconduct",
            content: "Claims arising from allegations of sexual misconduct or harassment."
        },
        {
            title: "Insolvency",
            content: "Claims related to the financial insolvency of the insured."
        },
        {
            title: "Contractual Liability",
            content: "Claims arising from liabilities assumed under contract, unless they would have existed in the absence of the contract."
        },
        {
            title: "Prior Known Incidents",
            content: "Claims related to incidents known before the policy inception."
        },
        {
            title: "Employment Practices",
            content: "Claims arising from employment-related disputes, such as wrongful termination or discrimination."
        }
    ]
    return (
        <>
            <Header />
            <div className="">
                <InnerHero
                    heading="Professional Indemnity for Doctors Insurance  "
                    title="Protect your medical practice from legal claims and ensure peace of mind with our comprehensive Professional Indemnity Insurance for Doctors"
                    image="/assets/img/doctor-insurance-hero.png"
                />
                <Experience />
                <section className="w3-section my-5 px-lg-5 px-lg-80">
                    <div className="d-flex flex-row align-items-center justify-content-between px-4">
                        <div onClick={() => setActiveW('what')} className={`pointer ${activeW === 'what' ? 'active-w3' : ''}`}>
                            <p>What</p>
                        </div>
                        <div onClick={() => setActiveW('who')} className={`pointer ${activeW === 'who' ? 'active-w3' : ''}`}>
                            <p>Who</p>
                        </div>
                        <div onClick={() => setActiveW('why')} className={`pointer ${activeW === 'why' ? 'active-w3' : ''}`}>
                            <p>Why</p>
                        </div>
                        <div onClick={() => setActiveW('coverage')} className={`pointer ${activeW === 'coverage' ? 'active-w3' : ''}`}>
                            <p>Coverages & Exclusions</p>
                        </div>
                    </div>
                </section>
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">What Is Professional Indemnity for Doctors Insurance?</h1>
                        <p className="mt-5 content-regular">Professional Indemnity Insurance for Doctors is a specialized insurance policy designed to protect medical professionals against legal claims arising from their practice. It covers legal expenses, settlements, and damages awarded in lawsuits alleging malpractice, negligence, or errors and omissions in professional services.</p>
                    </div>
                </section>
                <section className="coverage-section">
                    <div className="px-lg-5 px-lg-80">
                        <h1 className="title-primary-color mb-4">Key Coverage Areas of Professional Indemnity for Doctors</h1>
                        <Slider {...settings}>
                            <Side
                                heading="Side A"
                                content="Malpractice Claims:"
                                image="/assets/img/sideA.png"
                                description=" Coverage for claims arising from professional negligence or failure to perform medical duties competently."
                            />
                            <Side
                                heading="Side B"
                                content="Legal Defense Costs:"
                                image="/assets/img/sideB.png"
                                description="Covers legal fees and expenses incurred in defending against malpractice claims."
                            />
                            <Side
                                heading="Side C"
                                content="Settlements and Damages"
                                image="/assets/img/sideC.png"
                                description="Pays for settlements or damages awarded to claimants."
                            />
                            <Side
                                heading="Side D"
                                content="Breach of Confidentiality:"
                                image="/assets/img/sideC.png"
                                description="Covers claims related to the unauthorized disclosure of patient information."
                            />
                            <Side
                                heading="Side C"
                                content="Libel and Slander:"
                                image="/assets/img/sideC.png"
                                description="Protection against claims of defamation related to professional services."
                            />
                        </Slider>

                    </div>
                    <ServiceBlock
                        title="Advantages of Professional Indemnity for Doctors Insurance"
                        subtitle=""
                        points={["Shields personal assets of directors & officers:", "Crucial for both large and small enterprises:", "Enhances the ability to attract and retain top executive talent:"]}
                        ctaLabel=""
                        ctaLink=""
                        image="/assets/img/doctor-insurance-advantage.png"
                        type="primary"
                        imageLocation="right"
                    />
                </section>
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">Who Needs Professional Indemnity for Doctors Insurance?</h1>
                        <p className="mt-5 content-regular">Professional Indemnity Insurance is essential for all medical professionals, including: General Practitioners (GPs), Surgeons, Specialists, Dentists, Nurses and Midwives, Allied Health Professionals, Allied Health Professionals, Medical Clinics and Hospitals</p>
                    </div>
                </section>
                <ServiceBlock
                    title="Factors Influencing Professional Indemnity Policy Coverage"
                    subtitle=""
                    points={["Type of medical practice:", "Specialty and area of expertise:", "Claims history:", "Volume of patients treated:", "Regulatory requirements:"]}
                    ctaLabel=""
                    ctaLink=""
                    image="/assets/img/do-coverage-factor.png"
                    type="primary"
                    imageLocation="right"
                />
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">Why Get Professional Indemnity for Doctors Insurance?</h1>
                        <p className="my-5 content-regular">Medical professionals face numerous risks in their practice. Professional Indemnity Insurance provides a safeguard against potential financial losses due to legal claims. It covers:</p>
                        <BSServiceBlock points={["Allegations of medical malpractice and negligence:","Claims of misdiagnosis or delayed diagnosis:","Errors in treatment or surgical procedures:","Breach of confidentiality and patient data protection:","Defamation claims related to professional conduct:"]} />
                    </div>
                </section>
                <ServiceBlock
                    title="Who Can File Claims Against Doctors?"
                    subtitle=""
                    points={["Patients and their families:","Regulatory authorities:","Medical institutions:","Colleagues and other healthcare professionals:"]}
                    ctaLabel=""
                    ctaLink=""
                    image="/assets/img/litigation.png"
                    type="primary"
                    imageLocation="right"
                />
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">Coverages & Exclusions in Professional Indemnity for Doctors Insurance*</h1>
                        <section className="w3-section my-5">
                            <div className="d-flex flex-row">
                                <div onClick={() => setCoverage(true)} className={`mr-4 pointer ${isCoverage ? 'active-w3' : ''}`}>
                                    <p>Coverages</p>
                                </div>
                                <div onClick={() => setCoverage(false)} className={`pointer ${!isCoverage ? 'active-w3' : ''}`}>
                                    <p>Exclusions</p>
                                </div>
                            </div>
                        </section>
                        <div>
                            {isCoverage && (
                                <p>Professional Indemnity Insurance for Doctors provides extensive coverage against various risks. Key coverages include:</p>
                            )}
                            {!isCoverage && (
                                <p>Certain situations are excluded from Professional Indemnity coverage, including:</p>
                            )}

                        </div>
                        {isCoverage && (
                            <div className="row">
                                {coverages.map((c, i) => (
                                    <div className="col-12 col-lg-6">
                                        <h5 className="title-primary-color my-3">{`${i + 1}. ${c.title}`}</h5>
                                        <p>{`${c.content}`}</p>
                                    </div>
                                ))}
                            </div>
                        )}
                        {!isCoverage && (
                            <div className="row">
                                {exclusion.map((c, i) => (
                                    <div className="col-12 col-lg-6">
                                        <h5 className="title-primary-color my-3">{`${i + 1}. ${c.title}`}</h5>
                                        <p>{`${c.content}`}</p>
                                    </div>
                                ))}
                            </div>
                        )}

                    </div>
                </section>
                <ClaimsExample 
                    title="Professional Indemnity Claims Examples"
                    subtitle="Case of Misdiagnosis Leading to Legal Action"
                    incident="A patient filed a lawsuit against a doctor alleging misdiagnosis of a serious condition, resulting in delayed treatment and further complications."
                    resolution="The Professional Indemnity policy covered the legal defense costs and the settlement awarded to the patient, protecting the doctor from significant financial loss and ensuring the patient received compensation."
                    image="/assets/img/claims-example.png"
                />
                {/* <section className="my-4">
                    <p className={`service-title title-primary-color text-center`}>{'Frequently Asked Questions'}</p>
                    <div className=" p-5">
                    <div id="accordion" className="px-lg-5 px-lg-80 p-5">
                        {faq.indemnity.map(f => (<Accordian faq={f} />))}
                    </div>
                </div>
                </section> */}
                <ServiceBlock
                    title="Why Choose Buckss for Professional Indemnity for Doctors Insurance?"
                    subtitle=""
                    points={
                        [
                            "Expertise:Leveraging over 50 years of combined experience in the insurance industry.",
                            "Customized Solutions:Tailored insurance plans to meet your company’s specific needs.",
                            "Dedicated Support:Comprehensive support throughout the claims process.",
                            "Top Partnerships:Strong relationships with leading insurance providers for optimal coverage.",
                        ]
                    }
                    ctaLabel=""
                    ctaLink=""
                    image="/assets/img/insurance-agent-vector.png"
                    type="primary"
                    imageLocation="right"
                />
                <div className="mt-4">
                        <InsuranceServices />
                    </div>
               <div className="mt-4">
               <ProtectBusiness />
               </div>
            </div>
            <Footer />
        </>
    )
}

export default IndemnityInsurance