import { useState } from "react"
import Footer from "../components/Footer"
import Header from "../components/Header"
import InnerHero from "../components/InnerHero"
import Experience from "../components/Experience"
import Side from "../components/Side"
import ServiceBlock from "../components/ServiceBlock"
import BSServiceBlock from "../components/BSServiceBlock"
import InsuranceServices from "../components/InsuranceServices"
import ProtectBusiness from "../components/ProtectBusiness"
import ClaimsExample from "../components/ClaimsExample"
import faq from "../data/faq"
import Accordian from "../components/Accordian"
import Slider from "react-slick"
import settings from "../lib/SliderSettings"

const GroupHealthInsurance = () => {
    const [activeW, setActiveW] = useState('what')
    const [isCoverage, setCoverage] = useState(true)
    const coverages = [
        {
            title: "Hospitalization Expenses",
            content: "Covers room charges, ICU charges, doctor fees, and more during hospital stays."
        },
        {
            title: "Daycare Procedures",
            content: "Covers medical procedures that do not require overnight hospitalization."
        },
        {
            title: "Pre and Post-Hospitalization",
            content: "Expenses incurred before and after hospitalization are covered."
        },
        {
            title: "Maternity Benefits",
            content: "Includes expenses related to childbirth and newborn care."
        },
        {
            title: "Emergency Ambulance",
            content: "Covers the cost of ambulance services in emergencies."
        },
        {
            title: "Preventive Health Checkups",
            content: "Regular health checkups to ensure early detection of illnesses."
        },
        {
            title: "Critical Illness Cover",
            content: "Provides coverage for specific critical illnesses like cancer, heart attack, etc."
        },
        {
            title: "Dental and Vision Care",
            content: "Optional coverage for dental and vision treatments."
        }
    ]
    const exclusion = [
        {
            title: "Pre-existing Conditions",
            content: "Certain pre-existing conditions may not be covered immediately."
        },
        {
            title: "Cosmetic Procedures",
            content: "Treatments for cosmetic purposes are usually excluded."
        },
        {
            title: "Self-Inflicted Injuries",
            content: "Injuries caused intentionally by the insured are not covered."
        },
        {
            title: "Experimental Treatments",
            content: "Unproven or experimental treatments are not included."
        },
        {
            title: "War and Nuclear Risks",
            content: "Damages due to war or nuclear activities are excluded."
        },
        {
            title: "Substance Abuse",
            content: "Treatments related to drug or alcohol abuse are not covered."
        },
        {
            title: "Fertility Treatments",
            content: "Procedures like IVF are generally excluded."
        },
        {
            title: "Non-Medical Expenses",
            content: "Costs not directly related to medical treatment, like administrative fees, are not covered."
        }
    ]
    return (
        <>
            <Header />
            <div className="">
                <InnerHero
                    heading="Group Health Insurance"
                    title="Protect your employees and their families with comprehensive Group Health Insurance plans tailored to meet your business needs. "
                    image="/assets/img/group-health-hero.png"
                />
                <Experience />
                <section className="w3-section my-5 px-lg-5 px-lg-80">
                    <div className="d-flex flex-row align-items-center justify-content-between px-4">
                        <div onClick={() => setActiveW('what')} className={`pointer ${activeW === 'what' ? 'active-w3' : ''}`}>
                            <p>What</p>
                        </div>
                        <div onClick={() => setActiveW('who')} className={`pointer ${activeW === 'who' ? 'active-w3' : ''}`}>
                            <p>Who</p>
                        </div>
                        <div onClick={() => setActiveW('why')} className={`pointer ${activeW === 'why' ? 'active-w3' : ''}`}>
                            <p>Why</p>
                        </div>
                        <div onClick={() => setActiveW('coverage')} className={`pointer ${activeW === 'coverage' ? 'active-w3' : ''}`}>
                            <p>Coverages & Exclusions</p>
                        </div>
                    </div>
                </section>
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">What Is Group Health Insurance?</h1>
                        <p className="mt-5 content-regular">Group Health Insurance is a type of health insurance plan that covers a group of people, typically employees of a company or members of an organization. It provides health benefits to the insured group members and often includes coverage for their dependents. Group Health Insurance offers comprehensive medical coverage, including hospitalization, outpatient treatments, preventive care, and more, ensuring the well-being of your team.</p>
                    </div>
                </section>
                <section className="coverage-section">
                    <div className="px-lg-5 px-lg-80">
                        <h1 className="title-primary-color mb-4">Key Features of Group Health Insurance</h1>
                        <Slider {...settings}>
                            <Side
                                heading="Side A"
                                content="Comprehensive Coverage"
                                image="/assets/img/sideA.png"
                                description="Includes hospitalization, outpatient treatments, preventive care, and emergency services."
                            />
                            <Side
                                heading="Side B"
                                content="Cost-Effective"
                                image="/assets/img/sideB.png"
                                description="Premiums are often lower than individual health plans due to group discounts."
                            />
                            <Side
                                heading="Side C"
                                content="Customizable Plans"
                                image="/assets/img/sideC.png"
                                description="Tailored to meet the specific needs of your business and employees."
                            />
                            <Side
                                heading="Side D"
                                content="Tax Benefits"
                                image="/assets/img/sideC.png"
                                description="Premiums paid by the employer are tax-deductible."
                            />
                            <Side
                                heading="Side E"
                                content="Peace of Mind"
                                image="/assets/img/sideC.png"
                                description="Provides financial security for employees and their families."
                            />
                        </Slider>

                    </div>
                    <ServiceBlock
                        title="Benefits for Employers"
                        subtitle="A narrative about how Buckss was founded, the inspiration behind the company, and its journey so far."
                        points={["Attract and Retain Talent: Competitive health benefits help attract and retain skilled employees.","Tax Advantages: Premiums paid by the employer are tax-deductible, reducing overall tax liability.","Boosted Employee Morale: Offering health insurance shows employees that their well-being is a priority, boosting morale and loyalty.","Reduced Absenteeism: Healthy employees take fewer sick days, leading to better overall productivity.","Enhanced Company Reputation: Companies that provide comprehensive benefits are viewed more favorably by potential hires and clients."]}
                        ctaLabel=""
                        ctaLink=""
                        image="/assets/img/do-advantage.png"
                        type="primary"
                        imageLocation="right"
                    />
                </section>
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">Who Needs Group Health Insurance?</h1>
                        <p className="mt-5 content-regular">Group Health Insurance is essential for any organization that values the health and well-being of its employees. It is particularly beneficial for: Small, medium, and large enterprises, Startups looking to attract top talent, Non-profit organizations, Educational institutions, Any organization with a group of members or employees</p>
                    </div>
                </section>
                <ServiceBlock
                    title="Factors Influencing Group Health Insurance Coverage"
                    subtitle=""
                    points={["Size of the group"," Age and health profile of the members","Type of coverage required","Industry and business type","Budget and financial considerations"]}
                    ctaLabel=""
                    ctaLink=""
                    image="/assets/img/do-coverage-factor.png"
                    type="primary"
                    imageLocation="right"
                />
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">Why Get Group Health Insurance?</h1>
                        <p className="my-5 content-regular">Group Health Insurance is a crucial component of any employee benefits package. It provides:
                        </p>
                        <BSServiceBlock points={["Financial Security:** Protects employees from high medical expenses","Employee Retention:** Attracts and retains top talent.","Increased Productivity:** Healthy employees are more productive and engaged.","Employee Satisfaction:** Shows that the employer values their well-being.","Legal Compliance:** Helps meet statutory health insurance requirements."]} />
                    </div>
                </section>
                <ServiceBlock
                    title="Who Can Benefit from Group Health Insurance?"
                    subtitle=""
                    points={["Employers seeking to offer competitive benefits:","Employees and their families:","HR professionals managing employee benefits:","Business owners looking to enhance their workforce's well-being:"]}
                    ctaLabel=""
                    ctaLink=""
                    image="/assets/img/litigation.png"
                    type="primary"
                    imageLocation="right"
                />
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">Coverages & Exclusions in D&O Insurance*</h1>
                        <section className="w3-section my-5">
                            <div className="d-flex flex-row">
                                <div onClick={() => setCoverage(true)} className={`mr-4 pointer ${isCoverage ? 'active-w3' : ''}`}>
                                    <p>Coverages</p>
                                </div>
                                <div onClick={() => setCoverage(false)} className={`pointer ${!isCoverage ? 'active-w3' : ''}`}>
                                    <p>Exclusions</p>
                                </div>
                            </div>
                        </section>
                        <div>
                            {isCoverage && (
                                <p>Group Health Insurance offers extensive coverage to protect employees and their families. Key coverages include:</p>
                            )}
                            {!isCoverage && (
                                <p>While Group Health Insurance provides comprehensive coverage, some exclusions typically include:</p>
                            )}

                        </div>
                        {isCoverage && (
                            <div className="row">
                                {coverages.map((c, i) => (
                                    <div className="col-12 col-lg-6">
                                        <h5 className="title-primary-color my-3">{`${i + 1}. ${c.title}`}</h5>
                                        <p>{`${c.content}`}</p>
                                    </div>
                                ))}
                            </div>
                        )}
                        {!isCoverage && (
                            <div className="row">
                                {exclusion.map((c, i) => (
                                    <div className="col-12 col-lg-6">
                                        <h5 className="title-primary-color my-3">{`${i + 1}. ${c.title}`}</h5>
                                        <p>{`${c.content}`}</p>
                                    </div>
                                ))}
                            </div>
                        )}

                    </div>
                </section>
                <ClaimsExample 
                    title="Group Health Insurance Claims Examples"
                    subtitle="Case of Emergency Surgery"
                    incident="An employee needed an emergency appendectomy. The hospitalization and surgery costs amounted to ₹1,50,000"
                    resolution="The Group Health Insurance policy covered the entire cost, relieving the employee of any financial burden. The quick claim settlement process ensured the employee received timely medical care without any hassles."
                    image="/assets/img/claims-example.png"
                />
                {/* <section className="my-4">
                    <p className={`service-title title-primary-color text-center`}>{'Frequently Asked Questions'}</p>
                    <div className=" p-5">
                    <div id="accordion" className="px-lg-5 px-lg-80 p-5">
                        {faq.groupHealth.map(f => (<Accordian faq={f} />))}
                    </div>
                </div>
                </section> */}
                <ServiceBlock
                    title="Why Choose Buckss for D&O Insurance?"
                    subtitle=""
                    points={
                        [
                            "Expertise:Leveraging over 50 years of combined experience in the insurance industry.",
                            "Tailored Solutions: Customized Group Health Insurance plans to suit your business needs.",
                            "Dedicated Support:Comprehensive support throughout the claims process.",
                            "Top Partnerships:Strong relationships with leading insurance providers for optimal coverage.",
                        ]
                    }
                    ctaLabel=""
                    ctaLink=""
                    image="/assets/img/insurance-agent-vector.png"
                    type="primary"
                    imageLocation="right"
                />
                <div className="mt-4">
                        <InsuranceServices />
                    </div>
               <div className="mt-4">
               <ProtectBusiness />
               </div>
            </div>
            <Footer />
        </>
    )
}

export default GroupHealthInsurance