import moment from "moment";

const Footer = () => {
    return (
        <div className="footer-section px-80">
            <div className="row">
                <div className="col-lg-12 col-12">
                    <img className="white-logo" src="/assets/img/footer-logo.png" alt="" />

                </div>
                <div className="col-lg-2 col-12">
                     <div className="my-3">
                        <p>Company</p>
                         <div className="my-3">
                            <a className="white-text" href="/services">Services</a>
                        </div>
                         <div className="my-3">
                            <a className="white-text" href="/aboutus">About Us</a>
                        </div>
                         <div className="my-3">
                            <a className="white-text" href="/claims">Claims</a>
                        </div>
                         <div className="my-3">
                            <a className="white-text" href="/contactus">Contact us</a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-2 col-12">
                     <div className="my-3">
                        <p>Group Insurance</p>
                         <div className="my-3">
                            <span className="white-text">Group Health</span>
                        </div>
                         <div className="my-3">
                            <span className="white-text">Group  Life</span>
                        </div>
                         <div className="my-3">
                            <span className="white-text">Group Accidental</span>
                        </div>
                         <div className="my-3">
                            <span className="white-text">Travel Insurance</span>
                        </div>
                    </div>
                </div>
                <div className="col-lg-2 col-12">
                     <div className="my-3">
                        <p>Motor Insurance</p>
                         <div className="my-3">
                            <span className="white-text">Bus Insurance</span>
                        </div>
                         <div className="my-3">
                            <span className="white-text">Bike  Insurance</span>
                        </div>
                         <div className="my-3">
                            <span className="white-text">Heavy Vehicle</span>
                        </div>
                         <div className="my-3">
                            <span className="white-text">Car Insurance</span>
                        </div>
                    </div>
                </div>
                <div className="col-lg-2 col-12">
                     <div className="my-3">
                        <p>Property Insurance</p>
                         <div className="my-3">
                            <span className="white-text">Fire Insurance</span>
                        </div>
                         <div className="my-3">
                            <span className="white-text">Shop Insurance</span>
                        </div>
                         <div className="my-3">
                            <span className="white-text">Office Package</span>
                        </div>
                         <div className="my-3">
                            <span className="white-text">Burglary Insurance</span>
                        </div>
                    </div>
                </div>
                <div className="col-lg-2 col-12">
                     <div className="my-3">
                        <p>Other Insurance</p>
                         <div className="my-3">
                            <span className="white-text">Cyber Insurance</span>
                        </div>
                         <div className="my-3">
                            <span className="white-text">Directors & Officers Insurance</span>
                        </div>
                         <div className="my-3">
                            <span className="white-text">Marine Insurance</span>
                        </div>
                         <div className="my-3">
                            <span className="white-text">Asset  Insurance</span>
                        </div>
                    </div>
                </div>
                <div className="col-lg-2 col-12">
                     <div className="my-3">
                        <p>Policies</p>
                        <div className="">
                             <div className="my-3">
                                <a className="white-text" href="/terms">Terms & Conditions</a>
                            </div>
                             <div className="my-3">
                                <a className="white-text" href="/privacy">Privacy Policy</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="my-5">
                <p className="white-text text-center">Regd. Office: Buckss Insurance Brokers Pvt Ltd,     No 160, Vijayanagar 1st Stage, 6th Cross, 2nd Main, Vijaynagar, Mysore, Karnataka - 570017</p>
                <p>Insurance products are offered and serviced by Buckss Insurance Brokers Pvt Ltd | IRDAI Registration Code: IRDA/DB1085/2023 , Certificate No. 951 , License category - Direct Broker (Life, Health & General), Valid till 27/03/2027 | CIN U66220KA2023PTC178245</p>
            </div>
        </div>
    )
}

export default Footer;
