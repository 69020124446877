const InnerHero = (props) => {
    return (
        <section className="service-form row px-lg-5 px-lg-80 p-5">
            <div className="col-12 col-lg-6 row">
                <p className="service-form-title">{props.heading}</p>
                <p className="service-form-subTitle">{props.content}</p>
                <div className="col-12 col-lg-12">
                    <form className="p-5 form-box inner-form-box">
                        <div class="form-row my-3">
                            <div class="col">
                                <input type="text" class="form-control" placeholder="Your Company Name" />
                            </div>
                        </div>
                        <div class="form-row my-3">
                            <div class="col">
                                <input type="text" class="form-control" placeholder="Your Name" />
                            </div>
                        </div>
                        <div class="form-row my-3">
                            <div class="col">
                                <input type="text" class="form-control" placeholder="Your Phone Number" />
                            </div>
                        </div>
                        <div class="form-row my-3">
                            <div class="col">
                                <input type="text" class="form-control" placeholder="Your Work Email Address" />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="slide-cta  primary-button-full my-3 col-12">
                                <span className="nav-link">{"Get Quote"}</span>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className="col-12 col-lg-6">
                <img src={props.image} className="container-fluid" />
            </div>

        </section>
    )
}

export default InnerHero;
