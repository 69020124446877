import { useState } from "react"
import Footer from "../components/Footer"
import Header from "../components/Header"
import InnerHero from "../components/InnerHero"
import Experience from "../components/Experience"
import Side from "../components/Side"
import ServiceBlock from "../components/ServiceBlock"
import { converge, pathOr } from "ramda"
import BSServiceBlock from "../components/BSServiceBlock"
import InsuranceServices from "../components/InsuranceServices"
import ProtectBusiness from "../components/ProtectBusiness"
import ClaimsExample from "../components/ClaimsExample"
import faq from "../data/faq"
import Accordian from "../components/Accordian"
import Slider from "react-slick"
import settings from "../lib/SliderSettings"

const CrimeInsurance = () => {
    const [activeW, setActiveW] = useState('what')
    const [isCoverage, setCoverage] = useState(true)
    const coverages = [
        {
            title: "Employee Theft",
            content: "Covers financial losses due to theft or dishonesty by employees."
        },
        {
            title: "Forgery or Alteration",
            content: " Covers losses from the forgery or alteration of checks, promissory notes, and other financial documents."
        },
        {
            title: "Inside the Premises",
            content: "Covers loss of money and securities inside the business premises due to theft, burglary, or robbery."
        },
        {
            title: "Outside the Premises",
            content: " Covers loss of money and securities outside the business premises while in the custody of a messenger or armored motor vehicle company."
        },
        {
            title: "Death Benefits",
            content: "Covers losses from hacking and unauthorized computer use."
        },
        {
            title: "Funds Transfer Fraud",
            content: "Covers losses from fraudulent instructions to transfer funds."
        },
        {
            title: "Money Orders and Counterfeit Money",
            content: "Covers losses from accepting counterfeit money or money orders in good faith."
        },
        {
            title: "Credit Card Fraud",
            content: "Covers losses from the fraudulent use of credit cards issued to the business."
        },
        
        
    ]
    const exclusion = [
        {
            title: "Prior Known Losses",
            content: "Claims for losses known before the policy inception."
        },
        {
            title: "War and Terrorism",
            content: "Claims arising from acts of war or terrorism."
        },
        {
            title: "Indirect Losses",
            content: "Consequential or indirect financial losses not directly caused by a criminal act."
        },
        {
            title: "Inventory Shortages",
            content: "Unexplained or mysterious inventory shortages."
        },
        {
            title: "Governmental Actions",
            content: "Losses resulting from governmental or regulatory actions."
        },
        {
            title: "Legal Expenses",
            content: " Legal expenses unless specifically covered by the policy."
        },
        {
            title: "Non-Compliant Employers",
            content: "Claims assumed under contract, unless they would have existed without the contract."
        },
        {
            title: "Employee Termination",
            content: "Losses arising from employee termination disputes."
        },
       
    ]
    return (
        <>
            <Header />
            <div className="">
                <InnerHero
                    heading="Crime Insurance"
                    title="Protect your business from the financial impact of criminal activities, including theft, fraud, and embezzlement, with comprehensive Crime Insurance.  
"
                    image="/assets/img/crime-insurance-hero.png"
                />
                <Experience />
                <section className="w3-section my-5 px-lg-5 px-lg-80">
                    <div className="d-flex flex-row align-items-center justify-content-between px-4">
                        <div onClick={() => setActiveW('what')} className={`pointer ${activeW === 'what' ? 'active-w3' : ''}`}>
                            <p>What</p>
                        </div>
                        <div onClick={() => setActiveW('who')} className={`pointer ${activeW === 'who' ? 'active-w3' : ''}`}>
                            <p>Who</p>
                        </div>
                        <div onClick={() => setActiveW('why')} className={`pointer ${activeW === 'why' ? 'active-w3' : ''}`}>
                            <p>Why</p>
                        </div>
                        <div onClick={() => setActiveW('coverage')} className={`pointer ${activeW === 'coverage' ? 'active-w3' : ''}`}>
                            <p>Coverages & Exclusions</p>
                        </div>
                    </div>
                </section>
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">What Is Crime Insurance?</h1>
                        <p className="mt-5 content-regular">Crime Insurance provides businesses with financial protection against a variety of criminal activities, including employee theft, fraud, forgery, and other dishonest acts. This type of insurance helps businesses recover from financial losses caused by criminal activities and provides peace of mind that their assets are safeguarded.

                        </p>
                    </div>
                </section>
                <section className="coverage-section">
                    <div className="px-lg-5 px-lg-80">
                        <h1 className="title-primary-color mb-4">Key Coverage Areas of Crime Insurance</h1>
                        <Slider {...settings}>
                            <Side
                                heading="Employee Theft"
                                content=""
                                image="/assets/img/sideA.png"
                                description="Covers losses resulting from theft committed by employees."
                            />
                            <Side
                                heading="Forgery or Alteration"
                                content=""
                                image="/assets/img/sideB.png"
                                description="Covers losses from forged or altered financial documents."
                            />
                            <Side
                                heading="Fraud"
                                content=""
                                image="/assets/img/sideC.png"
                                description="Covers losses resulting from fraudulent activities, including electronic fraud."
                            />
                             <Side
                                heading="Computer Fraud"
                                content=""
                                image="/assets/img/sideC.png"
                                description="Covers losses from hacking and other computer-related crimes."
                            />
                             <Side
                                heading="Funds Transfer Fraud"
                                content=""
                                image="/assets/img/sideC.png"
                                description="Covers losses from fraudulent transfers of funds."
                            />
                             <Side
                                heading="Third-Party Theft"
                                content=""
                                image="/assets/img/sideC.png"
                                description="Covers losses from theft committed by third parties, including contractors and vendors."
                            />
                        </Slider>

                    </div>
                    <ServiceBlock
                        title="Advantages of Crime Insurance:"
                        subtitle=""
                        points={["Protects against financial losses due to criminal activities:", "Covers a wide range of criminal acts, including employee dishonesty:", "Provides financial support for recovery efforts:","Enhances overall business security:","Builds trust with stakeholders and clients:"]}
                        ctaLabel=""
                        ctaLink=""
                        image="/assets/img/do-advantage.png"
                        type="primary"
                        imageLocation="right"
                    />
                </section>
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">Who Needs Crime Insurance?</h1>
                        <p className="mt-5 content-regular">Any business, regardless of size or industry, is vulnerable to criminal activities and can benefit from Crime Insurance. This includes:
                        </p>
                        <BSServiceBlock points={["Small and Medium Enterprises (SMEs):", "Large Corporations:", "Retailers:", "Financial Institutions:", "Healthcare Providers:","Educational Institutions:", "Manufacturing Firms:"]} />
                    </div>
                </section>
                <ServiceBlock
                    title="Factors Influencing Crime Insurance Coverage"
                    subtitle=""
                    points={["Number of employees:", "Nature of business operations:", "History of criminal incidents:", "Level of internal controls and security measures:", "Value of assets at risk:"]}
                    ctaLabel=""
                    ctaLink=""
                    image="/assets/img/do-coverage-factor.png"
                    type="primary"
                    imageLocation="right"
                />
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">Why Get Crime Insurance?</h1>
                        <p className="my-5 content-regular">Businesses face numerous threats from criminal activities that can result in significant financial losses. Crime Insurance provides a safety net, protecting your business from:</p>
                        <BSServiceBlock points={["Employee theft and embezzlement:","Fraudulent financial transactions:", "Forgery and alteration of financial documents:", "Computer fraud and cybercrimes:", "Theft by third-party contractors or vendors:","Loss of funds due to fraudulent transfers:"]} />
                    </div>
                </section>
                <ServiceBlock
                    title="Who Can Be Affected by Criminal Activities?"
                    subtitle=""
                    points={["Business owners:", "Employees:", "Customers and clients:","Shareholders:","Third-party vendors:","Regulatory authorities:" ]}
                    ctaLabel=""
                    ctaLink=""
                    image="/assets/img/litigation.png"
                    type="primary"
                    imageLocation="right"
                />
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">Coverages & Exclusions in Crime Insurance</h1>
                        <section className="w3-section my-5">
                            <div className="d-flex flex-row">
                                <div onClick={() => setCoverage(true)} className={`mr-4 pointer ${isCoverage ? 'active-w3' : ''}`}>
                                    <p>Coverages</p>
                                </div>
                                <div onClick={() => setCoverage(false)} className={`pointer ${!isCoverage ? 'active-w3' : ''}`}>
                                    <p>Exclusions</p>
                                </div>
                            </div>
                        </section>
                        <div>
                            {isCoverage && (
                                <p>Crime Insurance provides comprehensive protection against various criminal activities. Key coverages include:</p>
                            )}
                            {!isCoverage && (
                                <p>Certain situations are excluded from Crime Insurance coverage, including:</p>
                            )}

                        </div>
                        {isCoverage && (
                            <div className="row">
                                {coverages.map((c, i) => (
                                    <div className="col-12 col-lg-6">
                                        <h5 className="title-primary-color my-3">{`${i + 1}. ${c.title}`}</h5>
                                        <p>{`${c.content}`}</p>
                                    </div>
                                ))}
                            </div>
                        )}
                        {!isCoverage && (
                            <div className="row">
                                {exclusion.map((c, i) => (
                                    <div className="col-12 col-lg-6">
                                        <h5 className="title-primary-color my-3">{`${i + 1}. ${c.title}`}</h5>
                                        <p>{`${c.content}`}</p>
                                    </div>
                                ))}
                            </div>
                        )}

                    </div>
                </section>
                <ClaimsExample 
                    title="Crime Insurance Claims Examples"
                    subtitle="Case of Employee Embezzlement"
                    incident="A financial services firm discovered that an employee had been embezzling funds over several years, resulting in significant financial losses."
                    resolution="The Crime Insurance policy covered the financial losses incurred by the firm, allowing them to recover the embezzled funds and take legal action against the employee."
                    image="/assets/img/claims-example.png"
                />
                {/* ß */}
                <ServiceBlock
                    title="Why Choose Buckss for Crime Insurance?"
                    subtitle=""
                    points={
                        [
                            "Expertise:Over 50 years of combined experience in the insurance industry.",
                            "Tailored Solutions:Customized Crime Insurance plans to suit your business needs.",
                            "Dedicated Support:  Comprehensive support throughout the claims process.",
                            "Strong Partnerships: Collaborations with leading insurance providers to offer the best coverage options.",
                        ]
                    }
                    ctaLabel=""
                    ctaLink=""
                    image="/assets/img/insurance-agent-vector.png"
                    type="primary"
                    imageLocation="right"
                />
                <div className="mt-4">
                        <InsuranceServices />
                    </div>
               <div className="mt-4">
               <ProtectBusiness />
               </div>
            </div>
            <Footer />
        </>
    )
}

export default CrimeInsurance