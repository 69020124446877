import { useState } from "react"
import Footer from "../components/Footer"
import Header from "../components/Header"
import InnerHero from "../components/InnerHero"
import Experience from "../components/Experience"
import Side from "../components/Side"
import ServiceBlock from "../components/ServiceBlock"
import BSServiceBlock from "../components/BSServiceBlock"
import InsuranceServices from "../components/InsuranceServices"
import ProtectBusiness from "../components/ProtectBusiness"
import ClaimsExample from "../components/ClaimsExample"
import faq from "../data/faq"
import Accordian from "../components/Accordian"
import Slider from "react-slick"
import settings from "../lib/SliderSettings"

const MachineryLossInsurance = () => {
    const [activeW, setActiveW] = useState('what')
    const [isCoverage, setCoverage] = useState(true)
    const coverages = [
        {
            title: "Income Loss:",
            content: "Compensates for the loss of net profit during the machinery downtime period."
        },
        {
            title: "Fixed Costs",
            content: "Covers ongoing fixed expenses, such as salaries, rent, and utilities, even if the business operations are halted"
        },
        {
            title: "Extra Expenses",
            content: "Covers additional costs incurred to expedite the repair process and resume operations"
        },
        {
            title: "Extended Indemnity",
            content: "Provides extended coverage for income loss until the business fully recovers."
        },
        {
            title: "Replacement Costs",
            content: "Covers the cost of replacing machinery that cannot be repaired."
        },
        {
            title: "Professional Fees",
            content: "Covers the fees of engineers, auditors, and other professionals involved in preparing claims."
        },
        {
            title: "Temporary Relocation Costs",
            content: "Covers the expenses of temporarily relocating the business during machinery repairs."
        },
        {
            title: "Utilities and Services",
            content: "Covers costs related to utilities and services that continue despite machinery downtime."
        },
        {
            title: "Loss Minimization Expenses:",
            content: "Covers expenses incurred to minimize the loss during the downtime period."
        },
        {
            title: "Debris Removal",
            content: "Covers the cost of removing debris following a machinery breakdown incident."
        }
    ]
    const exclusion = [
        {
            title: "Normal Business Risks",
            content: " Losses not related to machinery breakdowns, such as general market downturns."
        },
        {
            title: "Wear and Tear:",
            content: "Damages due to regular wear and tear or gradual deterioration."
        },
        {
            title: "Intentional Damage",
            content: "Damages caused by deliberate actions or negligence."
        },
        {
            title: "Nuclear Risks",
            content: "Losses due to nuclear reactions or radiation."
        },
        {
            title: "War and Terrorism",
            content: "Damages resulting from war, invasion, or acts of terrorism."
        },
        {
            title: "Pre-existing Conditions",
            content: "Losses or damages that existed before the policy inception."
        },
        {
            title: "Utility Failure:",
            content: "Losses due to utility failure not caused by machinery breakdowns."
        },
        {
            title: "Government Action:",
            content: "Losses due to actions or orders from the government"
        }
    ]
    return (
        <>
            <Header />
            <div className="">
                <InnerHero
                    heading="Machinery Loss of Profit Insurance"
                    title="Protect your business from financial setbacks caused by machinery breakdowns. Our Machinery Loss of Profit Insurance ensures your operations continue smoothly, even when the unexpected happens."
                    image="/assets/img/machinery-loss-insurance-hero.png"
                />
                <Experience />
                <section className="w3-section my-5 px-lg-5 px-lg-80">
                    <div className="d-flex flex-row align-items-center justify-content-between px-4">
                        <div onClick={() => setActiveW('what')} className={`pointer ${activeW === 'what' ? 'active-w3' : ''}`}>
                            <p>What</p>
                        </div>
                        <div onClick={() => setActiveW('who')} className={`pointer ${activeW === 'who' ? 'active-w3' : ''}`}>
                            <p>Who</p>
                        </div>
                        <div onClick={() => setActiveW('why')} className={`pointer ${activeW === 'why' ? 'active-w3' : ''}`}>
                            <p>Why</p>
                        </div>
                        <div onClick={() => setActiveW('coverage')} className={`pointer ${activeW === 'coverage' ? 'active-w3' : ''}`}>
                            <p>Coverages & Exclusions</p>
                        </div>
                    </div>
                </section>
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">What is Machinery Loss of Profit Insurance?</h1>
                        <p className="mt-5 content-regular">Machinery Loss of Profit Insurance, also known as Machinery Business Interruption Insurance, protects businesses from financial losses due to unexpected machinery breakdowns. This insurance covers the loss of income and additional expenses incurred to maintain operations during the repair or replacement of critical machinery.</p>
                    </div>
                </section>
                <section className="coverage-section">
                    <div className="px-lg-5 px-lg-80">
                        <h1 className="title-primary-color mb-4">Key Benefits of Machinery Loss of Profit Insurance</h1>
                        <Slider {...settings}>
                            <Side
                                heading="Side A"
                                content="Income Loss Coverage"
                                image="/assets/img/sideA.png"
                                description="Compensates for the loss of net profit due to machinery breakdowns."
                            />
                            <Side
                                heading="Side B"
                                content="Fixed Costs Coverage"
                                image="/assets/img/sideB.png"
                                description="Covers ongoing fixed expenses, such as salaries, rent, and utilities."
                            />
                            <Side
                                heading="Side C"
                                content="Extra Expenses Coverage"
                                image="/assets/img/sideC.png"
                                description="Covers additional costs incurred to expedite repairs and resume operations."
                            />
                             <Side
                                heading="Side D"
                                content="Extended Coverage"
                                image="/assets/img/sideC.png"
                                description="Provides protection for income loss during the downtime period."
                            />
                             <Side
                                heading="Side E"
                                content="Replacement Costs"
                                image="/assets/img/sideC.png"
                                description="Covers the cost of replacing machinery that cannot be repaired."
                            />
                        </Slider>

                    </div>
                    <ServiceBlock
                        title="Advantages of Machinery Loss of Profit Insurance"
                        subtitle="A narrative about how Buckss was founded, the inspiration behind the company, and its journey so far."
                        points={["Ensures financial stability during machinery downtimes:","Covers both direct and indirect financial losses:", "Reduces the financial impact of operational interruptions:", "Enhances business resilience and recovery capabilities:", "Provides peace of mind during unforeseen events:"]}
                        ctaLabel=""
                        ctaLink=""
                        image="/assets/img/do-advantage.png"
                        type="primary"
                        imageLocation="right"
                    />
                </section>
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">Who Needs Machinery Loss of Profit Insurance?</h1>
                        <p className="mt-5 content-regular">Machinery Loss of Profit Insurance is essential for any business that relies heavily on machinery for its operations. It is particularly crucial for: Manufacturing companies, Processing plants, Construction firms, Transportation and logistics companies, Food and beverage production facilities, Printing and publishing companies, Utilities and power generation companies</p>
                    </div>
                </section>
                <ServiceBlock
                    title="Factors Influencing Machinery Loss of Profit Insurance Coverage"
                    subtitle=""
                    points={["Business type and industry:","Size and scale of operations", "Historical machinery breakdown data", "Frequency and severity of machinery breakdowns", "Maintenance and risk management practices"]}
                    ctaLabel=""
                    ctaLink=""
                    image="/assets/img/do-coverage-factor.png"
                    type="primary"
                    imageLocation="right"
                />
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">Why Get Machinery Loss of Profit Insurance?</h1>
                        <p className="my-5 content-regular">Machinery Loss of Profit Insurance is vital for protecting businesses from the financial consequences of machinery breakdowns. The policy provides:</p>
                        <BSServiceBlock points={["Coverage for income loss and fixed expenses", "Companies aiming to minimize financial loss from machinery breakdowns", "Enterprises looking to ensure quick recovery and continuity post-machinery breakdown", "Organizations needing to comply with legal and regulatory requirements"]} />
                    </div>
                </section>
                <ServiceBlock
                    title="Who Can Benefit from Machinery Loss of Profit Insurance?"
                    subtitle=""
                    points={["Business owners seeking to protect their income and fixed costs:", "Companies aiming to minimize financial loss from machinery breakdowns:","Enterprises looking to ensure quick recovery and continuity post-machinery breakdown:","Organizations needing to comply with legal and regulatory requirements:"]}
                    ctaLabel=""
                    ctaLink=""
                    image="/assets/img/litigation.png"
                    type="primary"
                    imageLocation="right"
                />
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">Coverages & Exclusions in Machinery Loss of Profit Insurance*</h1>
                        <section className="w3-section my-5">
                            <div className="d-flex flex-row">
                                <div onClick={() => setCoverage(true)} className={`mr-4 pointer ${isCoverage ? 'active-w3' : ''}`}>
                                    <p>Coverages</p>
                                </div>
                                <div onClick={() => setCoverage(false)} className={`pointer ${!isCoverage ? 'active-w3' : ''}`}>
                                    <p>Exclusions</p>
                                </div>
                            </div>
                        </section>
                        <div>
                            {isCoverage && (
                                <p>Machinery Loss of Profit Insurance provides extensive coverage to protect businesses from the financial impact of machinery breakdowns. Key coverages include:</p>
                            )}
                            {!isCoverage && (
                                <p>Machinery Loss of Profit Insurance typically excludes certain situations, including:</p>
                            )}

                        </div>
                        {isCoverage && (
                            <div className="row">
                                {coverages.map((c, i) => (
                                    <div className="col-12 col-lg-6">
                                        <h5 className="title-primary-color my-3">{`${i + 1}. ${c.title}`}</h5>
                                        <p>{`${c.content}`}</p>
                                    </div>
                                ))}
                            </div>
                        )}
                        {!isCoverage && (
                            <div className="row">
                                {exclusion.map((c, i) => (
                                    <div className="col-12 col-lg-6">
                                        <h5 className="title-primary-color my-3">{`${i + 1}. ${c.title}`}</h5>
                                        <p>{`${c.content}`}</p>
                                    </div>
                                ))}
                            </div>
                        )}

                    </div>
                </section>
                <ClaimsExample 
                    title="Machinery Loss of Profit Insurance Claims Examples"
                    subtitle="Case of a Manufacturing Plant Machinery Breakdown"
                    incident=" A critical piece of machinery in a manufacturing plant broke down, halting production for several weeks"
                    resolution="The Machinery Loss of Profit Insurance policy covered the loss of net profit, fixed expenses, and additional costs incurred to expedite repairs. The business received compensation for income loss during the downtime period, enabling them to resume operations and minimize financial losses."
                    image="/assets/img/claims-example.png"
                />
                {/* <section className="my-4">
                    <p className={`service-title title-primary-color text-center`}>{'Frequently Asked Questions'}</p>
                    <div className=" p-5">
                    <div id="accordion" className="px-lg-5 px-lg-80 p-5">
                        {faq.do.map(f => (<Accordian faq={f} />))}
                    </div>
                </div>
                </section> */}
                <ServiceBlock
                    title="Why Choose Buckss for D&O Insurance?"
                    subtitle=""
                    points={
                        [
                            "Expertise:Leveraging over 50 years of combined experience in the insurance industry.",
                            "Customized Solutions:Tailored insurance plans to meet your company’s specific needs.",
                            "Dedicated Support:Comprehensive support throughout the claims process.",
                            "Top Partnerships:Strong relationships with leading insurance providers for optimal coverage.",
                        ]
                    }
                    ctaLabel=""
                    ctaLink=""
                    image="/assets/img/insurance-agent-vector.png"
                    type="primary"
                    imageLocation="right"
                />
                <div className="mt-4">
                        <InsuranceServices />
                    </div>
               <div className="mt-4">
               <ProtectBusiness />
               </div>
            </div>
            <Footer />
        </>
    )
}

export default MachineryLossInsurance