import { useState } from "react"
import Footer from "../components/Footer"
import Header from "../components/Header"
import InnerHero from "../components/InnerHero"
import Experience from "../components/Experience"
import Side from "../components/Side"
import ServiceBlock from "../components/ServiceBlock"
import BSServiceBlock from "../components/BSServiceBlock"
import InsuranceServices from "../components/InsuranceServices"
import ProtectBusiness from "../components/ProtectBusiness"
import ClaimsExample from "../components/ClaimsExample"
import faq from "../data/faq"
import Accordian from "../components/Accordian"
import Slider from "react-slick"
import settings from "../lib/SliderSettings"

const FireInsurance = () => {
    const [activeW, setActiveW] = useState('what')
    const [isCoverage, setCoverage] = useState(true)
    const coverages = [
        {
            title: "Property Damage",
            content: "Covers the cost of repairing or replacing buildings, machinery, and equipment damaged by fire"
        },
        {
            title: "Inventory Loss",
            content: "Compensates for the loss of inventory and stock due to fire."
        },
        {
            title: "Business Interruption:",
            content: "Provides compensation for lost income and additional expenses incurred while restoring operations"
        },
        {
            title: "Debris Removal:",
            content: "Covers the cost of removing debris after a fire incident."
        },
        {
            title: "Firefighting Expenses:",
            content: "Reimburses expenses related to firefighting efforts to control and extinguish the fire."
        },
        {
            title: "Temporary Relocation Costs:",
            content: "Covers the expenses of temporarily relocating the business during repairs."
        },
        {
            title: "Loss of Rental Income:",
            content: "Compensates property owners for loss of rental income due to fire damage."
        },
        {
            title: "Damage to Surrounding Property:",
            content: "Covers damages caused to surrounding properties due to the fire."
        }
    ]
    const exclusion = [
        {
            title: "Intentional Fire:",
            content: "Damages caused by deliberate fire set by the policyholder."
        },
        {
            title: "War and Nuclear Risks:",
            content: "Losses due to war, invasion, or nuclear risks."
        },
        {
            title: "Electrical Short-Circuits:",
            content: "Damages resulting from electrical short-circuits unless explicitly covered."
        },
        {
            title: "Negligence:",
            content: "Losses arising from gross negligence or failure to take reasonable precautions."
        },
        {
            title: "Unoccupied Property:",
            content: "Damage to unoccupied or vacant properties unless specifically covered."
        },
        {
            title: "Natural Disasters:",
            content: "Losses due to natural disasters like earthquakes or floods unless added to the policy."
        },
        {
            title: "Wear and Tear",
            content: "Damages due to regular wear and tear or gradual deterioration."
        },
        {
            title: "Pre-existing Damages:",
            content: "Any damages or losses that occurred before the policy inception."
        }
    ]
    return (
        <>
            <Header />
            <div className="">
                <InnerHero
                    heading="Fire Insurance  "
                    title="Protect your business from the devastating impact of fire-related incidents with comprehensive Fire Insurance that covers property damage, inventory loss, and business interruption."
                    image="/assets/img/fire-insurance-hero.png"
                />
                <Experience />
                <section className="w3-section my-5 px-lg-5 px-lg-80">
                    <div className="d-flex flex-row align-items-center justify-content-between px-4">
                        <div onClick={() => setActiveW('what')} className={`pointer ${activeW === 'what' ? 'active-w3' : ''}`}>
                            <p>What</p>
                        </div>
                        <div onClick={() => setActiveW('who')} className={`pointer ${activeW === 'who' ? 'active-w3' : ''}`}>
                            <p>Who</p>
                        </div>
                        <div onClick={() => setActiveW('why')} className={`pointer ${activeW === 'why' ? 'active-w3' : ''}`}>
                            <p>Why</p>
                        </div>
                        <div onClick={() => setActiveW('coverage')} className={`pointer ${activeW === 'coverage' ? 'active-w3' : ''}`}>
                            <p>Coverages & Exclusions</p>
                        </div>
                    </div>
                </section>
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">What is Fire Insurance?</h1>
                        <p className="mt-5 content-regular">Fire Insurance provides financial protection against damages and losses caused by fire-related incidents. It covers the cost of repairing or replacing damaged property, inventory, and equipment. Additionally, it compensates for business interruption losses, helping businesses recover and resume operations quickly.</p>
                    </div>
                </section>
                <section className="coverage-section">
                    <div className="px-lg-5 px-lg-80">
                        <h1 className="title-primary-color mb-4">Key Benefits of Fire Insurance</h1>
                        <Slider {...settings}>
                            <Side
                                heading="Side A"
                                content="Property Damage Coverage"
                                image="/assets/img/sideA.png"
                                description="Compensates for the repair or replacement of buildings, machinery, and equipment damaged by fire"
                            />
                            <Side
                                heading="Side B"
                                content="Inventory Loss Coverage:"
                                image="/assets/img/sideB.png"
                                description=" Covers the loss of inventory and stock due to fire incidents."
                            />
                            <Side
                                heading="Side C"
                                content="Business Interruption Coverage:"
                                image="/assets/img/sideC.png"
                                description="Provides compensation for lost income and additional expenses incurred while restoring operations."
                            />
                            <Side
                                heading="Side D"
                                content="Debris Removal Costs:"
                                image="/assets/img/sideC.png"
                                description="Covers the cost of removing debris after a fire incident."
                            />
                            <Side
                                heading="Side E"
                                content="Firefighting Expenses:"
                                image="/assets/img/sideC.png"
                                description=" Reimburses expenses related to firefighting efforts to control and extinguish the fire."
                            />
                        </Slider>

                    </div>
                    <ServiceBlock
                        title="Advantages of Fire Insurance:"
                        subtitle="A narrative about how Buckss was founded, the inspiration behind the company, and its journey so far."
                        points={["Comprehensive protection for business assets:", "Ensures business continuity:", "Reduces financial burden post-incident:","Enhances business stability and resilience:","Compliance with regulatory requirements:"]}
                        ctaLabel=""
                        ctaLink=""
                        image="/assets/img/do-advantage.png"
                        type="primary"
                        imageLocation="right"
                    />
                </section>
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">Who Needs Fire Insurance?</h1>
                        <p className="mt-5 content-regular">Fire Insurance is essential for all businesses, regardless of size or industry, to protect their assets and ensure business continuity. It is particularly crucial for: Manufacturing companies, Warehouses and storage facilitie, Retail stores and supermarkets, Restaurants and hotels, Office buildings, Educational institutions,  Healthcare facilities</p>
                    </div>
                </section>
                <ServiceBlock
                    title="Factors Influencing Fire Insurance Coverage:"
                    subtitle=""
                    points={["Nature of business operations:", "Value of property and assets:", "Location and fire risk level:", "Safety measures in place:", "History of fire incidents:"]}
                    ctaLabel=""
                    ctaLink=""
                    image="/assets/img/do-coverage-factor.png"
                    type="primary"
                    imageLocation="right"
                />
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">Why Get Fire Insurance?</h1>
                        <p className="my-5 content-regular">Fire Insurance is crucial for protecting businesses from the financial impact of fire-related incidents. The policy provides:                        </p>
                        <BSServiceBlock points={["Injuries caused by defective or unsafe products:", "Property damage resulting from product defects:", "Legal fees and defense costs:", "Settlements and judgments awarded to claimants:", "Regulatory fines and penalties:"]} />
                    </div>
                </section>
                <ServiceBlock
                    title="Who Can Benefit from Fire Insurance?"
                    subtitle=""
                    points={["Business owners seeking to protect their assets:", "Companies aiming to minimize financial loss from fire incidents:", "Enterprises looking to ensure quick recovery and continuity post-incident:", "Organizations needing to comply with legal and regulatory requirements:"]}
                    ctaLabel=""
                    ctaLink=""
                    image="/assets/img/litigation.png"
                    type="primary"
                    imageLocation="right"
                />
                <section className="px-lg-5 px-lg-80 p-5">
                    <div>
                        <h1 className="title-primary-color">Coverages & Exclusions in Product Liability Insurance*</h1>
                        <section className="w3-section my-5">
                            <div className="d-flex flex-row">
                                <div onClick={() => setCoverage(true)} className={`mr-4 pointer ${isCoverage ? 'active-w3' : ''}`}>
                                    <p>Coverages</p>
                                </div>
                                <div onClick={() => setCoverage(false)} className={`pointer ${!isCoverage ? 'active-w3' : ''}`}>
                                    <p>Exclusions</p>
                                </div>
                            </div>
                        </section>
                        <div>
                            {isCoverage && (
                                <p>Fire Insurance provides extensive coverage to protect businesses from the financial impact of fire-related incidents. Key coverages include:</p>
                            )}
                            {!isCoverage && (
                                <p>Fire Insurance typically excludes certain situations, including:</p>
                            )}

                        </div>
                        {isCoverage && (
                            <div className="row">
                                {coverages.map((c, i) => (
                                    <div className="col-12 col-lg-6">
                                        <h5 className="title-primary-color my-3">{`${i + 1}. ${c.title}`}</h5>
                                        <p>{`${c.content}`}</p>
                                    </div>
                                ))}
                            </div>
                        )}
                        {!isCoverage && (
                            <div className="row">
                                {exclusion.map((c, i) => (
                                    <div className="col-12 col-lg-6">
                                        <h5 className="title-primary-color my-3">{`${i + 1}. ${c.title}`}</h5>
                                        <p>{`${c.content}`}</p>
                                    </div>
                                ))}
                            </div>
                        )}

                    </div>
                </section>
                <ClaimsExample 
                    title="Fire Insurance Claims Examples"
                    subtitle="Case of a Warehouse Fire"
                    incident="A major fire broke out in a warehouse, causing significant damage to the building, stored goods, and equipment."
                    resolution="The Fire Insurance policy covered the costs of repairing the warehouse, replacing damaged inventory, and compensating for the business interruption. The company was able to resume operations quickly, minimizing financial losses."
                    image="/assets/img/claims-example.png"
                />
                {/* <section className="my-4">
                    <p className={`service-title title-primary-color text-center`}>{'Frequently Asked Questions'}</p>
                    <div className=" p-5">
                    <div id="accordion" className="px-lg-5 px-lg-80 p-5">
                        {faq.fire.map(f => (<Accordian faq={f} />))}
                    </div>
                </div>
                </section> */}
                <ServiceBlock
                    title="Why Choose Buckss for Fire Insurance?"
                    subtitle=""
                    points={
                        [
                            "Expertise:Leveraging over 50 years of combined experience in the insurance industry.",
                            "Tailored Solutions:Customized Fire insurance plans to suit your business needs.",
                            "Dedicated Support:Comprehensive support throughout the claims process.",
                            "Strong Partnerships:Collaborations with leading insurance providers to offer the best coverage options.",
                        ]
                    }
                    ctaLabel=""
                    ctaLink=""
                    image="/assets/img/insurance-agent-vector.png"
                    type="primary"
                    imageLocation="right"
                />
                <div className="mt-4">
                        <InsuranceServices />
                    </div>
               <div className="mt-4">
               <ProtectBusiness />
               </div>
            </div>
            <Footer />
        </>
    )
}

export default FireInsurance