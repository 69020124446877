const InsuranceServices = () => {
    return (
        <>
            <div id="know-more" className="d-flex justify-content-center align-items-center">
                <div><img src="/assets/img/insurance-needs.png" className="icon" /></div>
                <div> <span className="text-center primary-bold">Find the best insurance that Tailored to Your Needs</span></div>
            </div>
            <div className="insurance-box my-5 mx-5">
                <div className="row px-5 pt-5">
                    <div>
                        <p className="primary-bold">Liability Insurance</p>
                    </div>
                </div>
                <div className="row p-4">
                    <div className="col-12 col-lg-3 col-md-4 col-sm-6 my-lg-0 my-2">
                        <div className="d-flex flex-row  align-items-center">
                            <img src="/assets/img/director-insurance.png" className="mx-2 icon" />
                            <div><a href="/director-officer-insurance" className="service-link"><span>{"Directors & Officers Insurance"}</span></a></div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-3 col-md-4 col-sm-6 my-lg-0 my-2">
                        <div className="d-flex flex-row  align-items-center">
                            <img src="/assets/img/doctor.png" className="mx-2 icon" />
                            <div><a href="/indemnity-insurance" className="service-link"><span>{"Professional Indemnity for Doctors"}</span></a></div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-3 col-md-4 col-sm-6 my-lg-0 my-2">
                        <div className="d-flex flex-row  align-items-center">
                            <img src="/assets/img/security-shield.png" className="mx-2 icon" />
                            <div><a href="/general-liability-insurance" className="service-link"><span>{"General Liability Insurance"}</span></a></div>

                        </div>
                    </div>
                    <div className="col-12 col-lg-3 col-md-4 col-sm-6 my-lg-0 my-2">
                        <div className="d-flex flex-row  align-items-center">
                            <img src="/assets/img/delivery-box.png" className="mx-2 icon" />
                            <div><a href="/product-liability-insurance" className="service-link"><span>Product Liability Insurance</span></a></div>
                        </div>
                    </div>
                </div>
                <div className="row p-4">
                    <div className="col-12 col-lg-3 col-md-4 col-sm-6 my-lg-0 my-2">
                        <div className="d-flex flex-row  align-items-center">
                            <img src="/assets/img/cyber-insurance.png" className="mx-2 icon" />
                            <div><a href="/cyber-insurance" className="service-link"><span>{"Cyber Insurance"}</span></a></div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-3 col-md-4 col-sm-6 my-lg-0 my-2">
                        <div className="d-flex flex-row  align-items-center">
                            <img src="/assets/img/theft-insurance.png" className="mx-2 icon" />
                            <div><a href="/crime-insurance" className="service-link"><span>{"Crime Insurance"}</span></a></div>
                          
                        </div>
                    </div>
                    <div className="col-12 col-lg-3 col-md-4 col-sm-6 my-lg-0 my-2">
                        <div className="d-flex flex-row  align-items-center">
                            <img src="/assets/img/workman_icon.png" className="mx-2 icon" />
                            <div><a href="/workman-compensation" className="service-link"><span>{"Workman Compensation"}</span></a></div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-3 col-md-4 col-sm-6 my-lg-0 my-2">
                        <div className="d-flex flex-row  align-items-center">
                            <img src="/assets/img/computer.png" className="mx-2 icon" />
                            <div><a href="/errors-omissions-insurance" className="service-link"><span>Errors & Omissions Insurance</span></a></div>
                        </div>
                    </div>
                </div>

                {/****  Asset Insurance */}
                <div className="row px-5 pt-5">
                    <div>
                        <p className="primary-bold">Asset Insurance</p>
                    </div>
                </div>
                <div className="row p-4">
                    <div className="col-12 col-lg-3 col-md-4 col-sm-6 my-lg-0 my-2">
                        <div className="d-flex flex-row  align-items-center">
                            <img src="/assets/img/fire-prevention.png" className="mx-2 icon" />
                            <div><a href="/fire-insurance" className="service-link"><span>{"Fire Insurance"}</span></a></div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-3 col-md-4 col-sm-6 my-lg-0 my-2">
                        <div className="d-flex flex-row  align-items-center">
                            <img src="/assets/img/fire-prevention-loss.png" className="mx-2 icon" />
                            <div><a href="/fire-loss-insurance" className="service-link"><span>Fire Loss of Profit Insurance Policy</span></a></div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-3 col-md-4 col-sm-6 my-lg-0 my-2">
                        <div className="d-flex flex-row  align-items-center">
                            <img src="/assets/img/machinery.png" className="mx-2 icon" />
                            <div><a href="/machinery-breakdown-insurance" className="service-link"><span>Machinery Breakdown Insurance</span></a></div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-3 col-md-4 col-sm-6 my-lg-0 my-2">
                        <div className="d-flex flex-row  align-items-center">
                            <img src="/assets/img/machinery-loss.png" className="mx-2 icon" />
                            <div><a href="/machinery-loss-insurance" className="service-link"><span>Machinery Loss of Profit Insurance</span></a></div>
                        </div>
                    </div>
                </div>
                <div className="row p-4">
                    <div className="col-12 col-lg-3 col-md-4 col-sm-6 my-lg-0 my-2">
                        <div className="d-flex flex-row  align-items-center">
                            <img src="/assets/img/bus.png" className="mx-2 icon" />
                            <div><a href="/commercial-vehicle-insurance" className="service-link"><span>{"Commercial Vehicle Insurance"}</span></a></div>
                        </div>
                    </div>
                    {/* <div className="col-12 col-lg-3 col-md-4 col-sm-6 my-lg-0 my-2">
                        <div className="d-flex flex-row  align-items-center">
                            <img src="/assets/img/car.png" className="mx-2 icon" />
                            <div><span>Car Insurance</span></div>
                        </div>
                    </div> */}
                    <div className="col-12 col-lg-3 col-md-4 col-sm-6 my-lg-0 my-2">
                        <div className="d-flex flex-row  align-items-center">
                            <img src="/assets/img/office.png" className="mx-2 icon" />
                            <div><a href="/office-package-insurance" className="service-link"><span>Office Package Policy</span></a></div>
                        </div>
                    </div>
                </div>

                {/****  Engineering Insurance */}
                <div className="row px-5">
                    <div>
                        <p className="primary-bold">Engineering Insurance</p>
                    </div>
                </div>
                <div className="row p-4">
                    <div className="col-12 col-lg-3 col-md-4 col-sm-6 my-lg-0 my-2">
                        <div className="d-flex flex-row  align-items-center">
                            <img src="/assets/img/construction-worker.png" className="mx-2 icon" />
                            <div><a href="/contractor-insurance" className="service-link"><span>{"Contractor's All Risk"}</span></a></div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-3 col-md-4 col-sm-6 my-lg-0 my-2">
                        <div className="d-flex flex-row  align-items-center">
                            <img src="/assets/img/transport.png" className="mx-2 icon" />
                            <div><a href="/erection-insurance" className="service-link"><span>{"Erection All Risk"}</span></a></div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-3 col-md-4 col-sm-6 my-lg-0 my-2">
                        <div className="d-flex flex-row  align-items-center">
                            <img src="/assets/img/engineering.png" className="mx-2 icon" />
                            <div><a href="/contractors-plant-machinery-insurance" className="service-link"><span>Contractor's Plant & Machinery</span></a></div>
                        </div>
                    </div>
                </div>


                {/****  Marine Insurance */}
                <div className="row px-5">
                    <div>
                        <p className="primary-bold">Marine Insurance</p>
                    </div>
                </div>
                <div className="row p-4">
                    <div className="col-12 col-lg-3 col-md-4 col-sm-6 my-lg-0 my-2">
                        <div className="d-flex flex-row  align-items-center">
                            <img src="/assets/img/boat.png" className="mx-2 icon" />
                            <div><a href="/single-transist-insurance" className="service-link"><span>{"Single Transit Policy"}</span></a></div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-3 col-md-4 col-sm-6 my-lg-0 my-2">
                        <div className="d-flex flex-row  align-items-center">
                            <img src="/assets/img/logistics-delivery.png" className="mx-2 icon" />
                            <div><a href="/marine-open-insurance" className="service-link"><span>Marine Open Policy</span></a></div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-3 col-md-4 col-sm-6 my-lg-0 my-2">
                        <div className="d-flex flex-row  align-items-center">
                            <img src="/assets/img/track.png" className="mx-2 icon" />
                            <div><a href="/sales-turnover-insurance" className="service-link"><span>Sales Turnover Policy</span></a></div>
                        </div>
                    </div>
                </div>

                {/****  Employee Benefit Insurance */}
                <div className="row px-5">
                    <div>
                        <p className="primary-bold">Employee Benefit Insurance</p>
                    </div>
                </div>
                <div className="row p-4">
                    <div className="col-12 col-lg-3 col-md-4 col-sm-6 my-lg-0 my-2">
                        <div className="d-flex flex-row  align-items-center">
                            <img src="/assets/img/group-health.png" className="mx-2 icon" />
                            <div><a href="/group-health-insurance" className="service-link"><span>{"Group Health Insurance"}</span></a></div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-3 col-md-4 col-sm-6 my-lg-0 my-2">
                        <div className="d-flex flex-row  align-items-center">
                            <img src="/assets/img/workplace.png" className="mx-2 icon" />
                            <div><a href="/group-personal-accident-insurance" className="service-link"><span>Group Personal Accident Insurance</span></a></div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-3 col-md-4 col-sm-6 my-lg-0 my-2">
                        <div className="d-flex flex-row  align-items-center">
                            <img src="/assets/img/family-insurance.png" className="mx-2 icon" />
                            <div><a href="/group-term-insurance" className="service-link"><span>Group Term Insurance</span></a></div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-3 col-md-4 col-sm-6 my-lg-0 my-2">
                        <div className="d-flex flex-row  align-items-center">
                            <img src="/assets/img/travel.png" className="mx-2 icon" />
                            <div><a href="/group-travel-insurance" className="service-link"><span>Group Travel Insurance</span></a></div>
                        </div>
                    </div>
                </div>

            </div>
            </>
    )
}

export default InsuranceServices;