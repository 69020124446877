export const NextArrow  = (props) => {
    const { className, style, onClick } = props;
    return (
      <img src="/assets/img/circle-chevron-right.png" className={`${className} icon`} onClick={onClick} />
    );
  }
  
  export const PrevArrow  = (props) => {
    const { className, style, onClick } = props;
    return (
      <img src="/assets/img/circle-chevron-left.png" className={`${className} icon`} onClick={onClick} />
    );
  }